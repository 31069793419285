import * as accountActions from "../types/accountTypes";

// get all account
export const getAllAccountRequest = () => ({
    type: accountActions.GET_ALL_ACCOUNT_REQUEST,
});

export const getAllAccountSuccess = (payload) => ({
    type: accountActions.GET_ALL_ACCOUNT_SUCCESS,
    payload,
});

export const getAllAccountError = (payload) => ({
    type: accountActions.GET_ALL_ACCOUNT_ERROR,
    payload,
});

// get account
export const getAccountRequest = () => ({
    type: accountActions.GET_ACCOUNT_REQUEST,
});

export const getAccountSuccess = (payload) => ({
    type: accountActions.GET_ACCOUNT_SUCCESS,
    payload,
});

export const getAccountError = (payload) => ({
    type: accountActions.GET_ACCOUNT_ERROR,
    payload,
});

// put account
export const putAccountRequest = () => ({
    type: accountActions.PUT_ACCOUNT_REQUEST,
});

export const putAccountSuccess = (payload) => ({
    type: accountActions.PUT_ACCOUNT_SUCCESS,
    payload,
});

export const putAccountError = (payload) => ({
    type: accountActions.PUT_ACCOUNT_ERROR,
    payload,
});

// block account
export const blockAccountRequest = () => ({
    type: accountActions.BLOCK_ACCOUNT_REQUEST,
});

export const blockAccountSuccess = (payload) => ({
    type: accountActions.BLOCK_ACCOUNT_SUCCESS,
    payload,
});

export const blockAccountError = (payload) => ({
    type: accountActions.BLOCK_ACCOUNT_ERROR,
    payload,
});

// delete account
export const deleteAccountRequest = () => ({
    type: accountActions.DELETE_ACCOUNT_REQUEST,
});

export const deleteAccountSuccess = (payload) => ({
    type: accountActions.DELETE_ACCOUNT_SUCCESS,
    payload,
});

export const deleteAccountError = (payload) => ({
    type: accountActions.DELETE_ACCOUNT_ERROR,
    payload,
});

// delete account hard
export const deleteAccountHardRequest = () => ({
    type: accountActions.DELETE_ACCOUNT_HARD_REQUEST,
});

export const deleteAccountHardSuccess = (payload) => ({
    type: accountActions.DELETE_ACCOUNT_HARD_SUCCESS,
    payload,
});

export const deleteAccountHardError = (payload) => ({
    type: accountActions.DELETE_ACCOUNT_HARD_ERROR,
    payload,
});

// delete account avatar
export const deleteAccountAvatarRequest = () => ({
    type: accountActions.DELETE_ACCOUNT_AVATAR_REQUEST,
});

export const deleteAccountAvatarSuccess = (payload) => ({
    type: accountActions.DELETE_ACCOUNT_AVATAR_SUCCESS,
    payload,
});

export const deleteAccountAvatarError = (payload) => ({
    type: accountActions.DELETE_ACCOUNT_AVATAR_ERROR,
    payload,
});

// post admin
export const postNewUserRequest = () => ({
    type: accountActions.POST_NEW_USER_REQUEST,
});

export const postNewUserSuccess = (payload) => ({
    type: accountActions.POST_NEW_USER_SUCCESS,
    payload,
});

export const postNewUserError = (payload) => ({
    type: accountActions.POST_NEW_USER_ERROR,
    payload,
});

//set
export const clearAllParams = () => ({
    type: accountActions.CLEAR_ALL_PARAMS,
});

export const setPage = (data) => ({
    type: accountActions.SET_PAGE,
    payload: data,
});

export const setText = (data) => ({
    type: accountActions.SET_TEXT,
    payload: data,
});
