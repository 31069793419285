import { useEffect, useState, useRef } from "react";
import i18next from "i18next";

import { getLanguage, languageList } from "../../../helpers/language";
import useOutsideClick from "../../../hooks/useOutsideClick";

import { ReactComponent as ArrowDown } from "../../../assets/images/language/arrow.svg";
import "./Language.scss";

const Language = () => {
    const [language, setLanguage] = useState(languageList["en"]);
    const [selectStatus, changeSelectStatus] = useState(false);
    const languageRef = useRef();

    useEffect(() => {
        const siteLanguage = getLanguage();
        setLanguage(siteLanguage);
    }, []);

    const handleChangeLanguage = (lang) => {
        setLanguage(languageList[lang]);
        i18next.changeLanguage(lang);
        changeSelectStatus(false);
        localStorage.setItem("language", lang);
    };

    const getLocationDropdownList = () => {
        const copyLanguageInfo = { ...languageList };
        return Object.values(copyLanguageInfo);
    };

    useOutsideClick(languageRef, () => changeSelectStatus(false));

    return (
        <div ref={languageRef} className="language-control">
            <div
                className="language-control__selected"
                onClick={() => changeSelectStatus(!selectStatus)}
            >
                <ArrowDown
                    className={`language-control__icon ${
                        selectStatus ? "active" : ""
                    }`}
                />
                <p className="language-control__text">{language.label}</p>
            </div>
            {selectStatus && (
                <ul className="language-control__select-list">
                    {getLocationDropdownList().map(({ label, value, img }) => (
                        <li
                            key={label}
                            onClick={() => handleChangeLanguage(value)}
                            className="language-control__select-item"
                        >
                            <img src={img} alt="language" />
                            <p className="language-control__text" key={label}>
                                {label}
                            </p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Language;
