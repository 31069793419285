import { lazyImageConfig } from "./LazyImage.config";
import useLazyImage from "./use-lazy-image";

const LazyImage = ({ options = {}, type = "", image = "" }) => {
    const src = useLazyImage(lazyImageConfig[type], image);

    return <img src={src} {...options} />;
};

export default LazyImage;
