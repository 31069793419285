import { useTranslation } from "react-i18next";
import { flagsHeader, flagsTitle } from "./flags.config";
import FlagsItem from "./FlagsItem/FlagsItem";
import "./FlagsTable.scss";

const FlagsTable = () => {
    const { t } = useTranslation();
    return (
        <>
            <table className="flags">
                <thead className="flags__header">
                    <tr className="flags__header_item">
                        {flagsTitle.map((title, index) => (
                            <th
                                className="flags__header_item_title"
                                key={index + title}
                            >
                                {t(title)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="flags__content">
                    {flagsHeader.map((item, index) => (
                        <FlagsItem key={item + index} title={item} />
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default FlagsTable;
