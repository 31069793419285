import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import SupportSort from "./SupportSort/SupportSort";
import Pagination from "../../Pagination/Pagination";
import SupportTicket from "./SupportTicket/SupportTicket";
import NoSupportTickets from "./NoSupportTickets/NoSupportTickets";

import { sizeItemOnPage, supportTitle } from "./Support.config";
import {
    deactivateSupportTicket,
    editPageSupportTickets,
    getSupportTickets,
} from "../../../redux/operations/supportOperations";

import { openModalActionCreator } from "../../../redux/actions/modalActions";
import {
    supportPageSelector,
    supportSelector,
} from "../../../redux/selectors/supportSelectors";

import "./Support.scss";

const Support = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const page = useSelector(supportPageSelector);
    const { supportTickets, loading } = useSelector(supportSelector);
    const { items } = supportTickets;

    const handleOnDeactivate = (id) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    title: "support ticket",
                    action: () => dispatch(deactivateSupportTicket(id)),
                },
            })
        );
    };
    useEffect(() => {
        dispatch(getSupportTickets());
    }, []);
    return (
        <>
            <table className="support">
                <thead className="support__header">
                    <tr className="support__header_item">
                        {supportTitle.map(({ field, key }) => (
                            <th
                                key={field + key}
                                className="support__header_item_title"
                            >
                                {key && t(`support.title.${field}`)}
                            </th>
                        ))}
                        <th className="support__header_item_title">
                            <SupportSort />
                        </th>
                    </tr>
                </thead>
                {items.length > 0 && !loading ? (
                    <tbody className="support__content">
                        {items.map((supportTicket) => (
                            <SupportTicket
                                {...supportTicket}
                                key={supportTicket.chatId._id}
                                onDeactivate={handleOnDeactivate}
                            />
                        ))}
                    </tbody>
                ) : (
                    ""
                )}
            </table>
            {!items.length > 0 || loading ? <NoSupportTickets /> : ""}
            {items?.length > 0 && (
                <Pagination
                    elementType="page"
                    isStartedPage={page}
                    items={supportTickets}
                    sizeItemOnPage={sizeItemOnPage}
                    getNextPageItems={(e) =>
                        dispatch(editPageSupportTickets(e))
                    }
                />
            )}
        </>
    );
};
export default Support;
