import { sizeItemOnPage } from "../../components/Tables/Support/Support.config";
import * as supportTypes from "../types/supportTypes";

const initialQueryParams = { limit: sizeItemOnPage, text: "", page: 0 };

const initialState = {
    supportTickets: {
        items: [],
        count: 0,
    },
    supportChat: {
        priceProposal: { approvedBy: null, price: 0, proposedBy: null },
        messages: { items: [], carousel: [], count: null },
        operationsHistory: [],
        subcategoryTitle: "",
        isDisabled: false,
        participants: [],
        participant: {},
        createdAt: "",
        createdBy: "",
        updatedAt: "",
        district: "",
        orderId: "",
        taskId: "",
        _id: "",
    },
    params: initialQueryParams,
    loading: false,
    error: "",
};

export const supportReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case supportTypes.GET_SUPPORT_CHAT_REQUEST:
        case supportTypes.GET_SUPPORT_TICKETS_REQUEST:
        case supportTypes.DISABLE_SUPPORT_CHAT_REQUEST:
        case supportTypes.POST_SUPPORT_CHAT_FILE_REQUEST:
        case supportTypes.JOIN_TO_SUPPORT_CHAT_QUESTION_REQUEST:
        case supportTypes.DEACTIVATE_SUPPORT_TICKET_REQUEST: {
            return { ...state, loading: true };
        }

        case supportTypes.GET_SUPPORT_TICKETS_ERROR:
        case supportTypes.DISABLE_SUPPORT_CHAT_ERROR:
        case supportTypes.JOIN_TO_SUPPORT_CHAT_QUESTION_ERROR:
        case supportTypes.DEACTIVATE_SUPPORT_TICKET_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        case supportTypes.JOIN_TO_SUPPORT_CHAT_QUESTION_SUCCESS:
            return { ...state, loading: false };

        // support chat
        case supportTypes.GET_SUPPORT_CHAT_SUCCESS:
        case supportTypes.DISABLE_SUPPORT_CHAT_SUCCESS:
        case supportTypes.POST_SUPPORT_CHAT_FILE_SUCCESS:
            return { ...state, supportChat: payload };

        case supportTypes.GET_SUPPORT_TICKETS_SUCCESS:
        case supportTypes.DEACTIVATE_SUPPORT_TICKET_SUCCESS: {
            return {
                ...state,
                supportTickets: payload,
                loading: false,
            };
        }
        case supportTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };

        case supportTypes.SET_TEXT:
            return { ...state, params: { ...state.params, text: payload } };

        case supportTypes.CLEAR_ALL_PARAMS_SUPPORT_CHAT:
            return {
                ...state,
                supportChat: initialState.supportChat,
            };
        case supportTypes.CLEAR_ALL_PARAMS:
            return {
                ...state,
                supportTickets: initialState.supportTickets,
                params: initialQueryParams,
            };

        default:
            return state;
    }
};

export default supportReducer;
