import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { putFeedback } from "../../../../redux/operations/feedbackOperations";
import { closeModalActionCreator } from "../../../../redux/actions/modalActions";

import { SpecialistFeedbackEditSchema } from "./SpecialistFeedbackEdit.schema";

import "./index.scss";

const SpecialistFeedbackEdit = ({ details }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        mode: "all",
        defaultValues: { text: details.text, assessment: details.assessment },
        resolver: yupResolver(SpecialistFeedbackEditSchema),
    });

    const onSubmit = (data) => {
        dispatch(putFeedback(details.id, details.reporter, data));
    };

    return (
        <form
            className="specialist-feedback-edit"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="specialist-feedback-edit__content">
                <h2 className="specialist-feedback-edit__title">
                    {t("feedbacks-edit-title")}
                </h2>

                <div className="specialist-feedback-edit__content__item">
                    <label className="specialist-feedback-edit__content__item_label">
                        {t("details")}
                    </label>
                    <input
                        className={`specialist-feedback-edit__content__item_input ${
                            errors?.text && "error"
                        }`}
                        {...register("text")}
                    />
                    {errors?.text && (
                        <p className="specialist-feedback-edit__content__item-error">
                            {t(errors?.text.message)}
                        </p>
                    )}
                </div>
                <div className="specialist-feedback-edit__content__item">
                    <label className="specialist-feedback-edit__content__item_label">
                        {t("assessment")}
                    </label>
                    <input
                        maxLength="1"
                        className={`specialist-feedback-edit__content__item_input ${
                            errors?.assessment && "error"
                        }`}
                        {...register("assessment")}
                    />
                    {errors?.assessment && (
                        <p className="specialist-feedback-edit__content__item-error">
                            {t(errors?.assessment.message)}
                        </p>
                    )}
                </div>

                <div className="specialist-feedback-edit__content__service">
                    <button
                        onClick={() => dispatch(closeModalActionCreator())}
                        className="specialist-feedback-edit__content__service--cancel"
                    >
                        {t("btn-cancel")}
                    </button>
                    <button
                        type="submit"
                        className="specialist-feedback-edit__content__service--save"
                    >
                        {t("btn-save")}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default SpecialistFeedbackEdit;
