import { useDispatch } from "react-redux";

import RenderedModalContent from "./RenderedModal/RenderedModalContent";

import { closeModalActionCreator } from "../../redux/actions/modalActions";

import "./Modal.scss";

const Modal = () => {
    const dispatch = useDispatch();
    return (
        <div className="modal">
            <div className="modal__content">
                <RenderedModalContent />
            </div>
            <button
                className="modal__close"
                onClick={() => dispatch(closeModalActionCreator())}
            >
                &times;
            </button>
        </div>
    );
};
export default Modal;
