// get blog list
export const GET_BLOG_LIST_REQUEST = "blog/GET_BLOG_LIST_REQUEST";
export const GET_BLOG_LIST_SUCCESS = "blog/GET_BLOG_LIST_SUCCESS";
export const GET_BLOG_LIST_ERROR = "blog/GET_BLOG_LIST_ERROR";

// get blog by id
export const GET_BLOG_BY_ID_REQUEST = "blog/GET_BLOG_BY_ID_REQUEST";
export const GET_BLOG_BY_ID_SUCCESS = "blog/GET_BLOG_BY_ID_SUCCESS";
export const GET_BLOG_BY_ID_ERROR = "blog/GET_BLOG_BY_ID_ERROR";

// delete blog
export const DELETE_BLOG_REQUEST = "blog/DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "blog/DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_ERROR = "blog/DELETE_BLOG_ERROR";

// update blog
export const UPDATE_BLOG_REQUEST = "blog/UPDATE_BLOG_REQUEST";
export const UPDATE_BLOG_SUCCESS = "blog/UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_ERROR = "blog/UPDATE_BLOG_ERROR";

// create blog
export const CREATE_BLOG_REQUEST = "blog/CREATE_BLOG_REQUEST";
export const CREATE_BLOG_SUCCESS = "blog/CREATE_BLOG_SUCCESS";
export const CREATE_BLOG_ERROR = "blog/CREATE_BLOG_ERROR";

// set
export const SET_BLOG_PAGE = "blog/SET_BLOG_PAGE";
export const CLEAR_ALL_PARAMS = "account/CLEAR_ALL_PARAMS";
