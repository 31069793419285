import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import { promotionSelector } from "../../../redux/selectors/promotionSelectors";
import {
    getPromotionById,
    postPromotion,
    putPromotion,
} from "../../../redux/operations/promotionOperations";

import { Icon } from "../../Image/Image";
import Input from "../../../shared/Input/Input";
import Button from "../../../shared/Button/Button";
import FieldSet from "../../../shared/FieldSet/FieldSet";
import PhotoUploader from "../../PhotoUploader/PhotoUploader";

import { getFormData } from "../../../services/utils/getFormData";
import { promotionTypes } from "../../Tables/Promotion/Promotion.config";
import { getPromotionData, parseMoneyAmount } from "./PromotionForm.utils";
import { PromotionFormSchema } from "./PromotionForm.schema";
import {
    promotionFormPricesList,
    promotionServiceFor,
} from "./PromotionForm.config";
import { handleConvertNumberValue } from "../../../helpers/handleConvertNumberValue";

import "./PromotionForm.scss";

const PromotionForm = ({ details: { id, imageUrl } }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { promotion, loading } = useSelector(promotionSelector);

    const [currentPrices, setPrices] = useState({});
    const [currentDate, setCurrentDate] = useState("month");
    const [, setServiceFor] = useState("");

    const {
        control,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(PromotionFormSchema),
    });
    const props = useFieldArray({ control, name: `prices.${currentDate}` });

    useEffect(() => {
        if (id) dispatch(getPromotionById(id));
    }, [id, dispatch]);

    useEffect(() => {
        if (id)
            Object.entries(promotion).forEach(([name, value]) =>
                setValue(name, value)
            );
    }, [id, setValue, promotion]);

    useEffect(() => {
        if (id) setPrices(promotion.prices);
    }, [promotion.prices, id]);

    const onSubmit = (data) => {
        const key = Object.keys(data.prices)[0];

        const pricesArray = data.prices[key].map((item) => ({
            ...item,
            price: parseMoneyAmount(item.price),
        }));

        const pricesObj = {
            [key]: pricesArray,
        };

        if (id)
            return dispatch(
                putPromotion(
                    getFormData(
                        getPromotionData({ ...data, prices: pricesObj })
                    ),
                    id
                )
            );
        dispatch(
            postPromotion(
                getFormData(getPromotionData({ ...data, prices: pricesObj }))
            )
        );
    };

    const handleCloseModal = () => dispatch(closeModalActionCreator());

    const addMonth = () => {
        const periodItem = { price: "", description: "", _id: uuidv4() };

        if (currentPrices?.[currentDate]?.length > 0) {
            setPrices({
                ...currentPrices,
                [currentDate]: [...currentPrices[currentDate], periodItem],
            });
        } else setPrices({ ...currentPrices, [currentDate]: [periodItem] });
    };

    const handleDeleteFieldset = (e, price, index) => {
        e.preventDefault();
        setPrices({
            ...currentPrices,
            [currentDate]: currentPrices[currentDate].filter(
                (item) => item.price !== price
            ),
        });
        props.remove(index);
    };

    return (
        <form className="promotion_form" onSubmit={handleSubmit(onSubmit)}>
            <div className="promotion_form__content">
                <h2 className="promotion_form__title">
                    {t(`promotion.${id ? "edit" : "add"}`)}
                </h2>
                <div className="promotion_form__items">
                    <div className="promotion_item">
                        <Controller
                            name="image"
                            control={control}
                            render={({ field }) => (
                                <PhotoUploader
                                    {...field}
                                    type="rectangle"
                                    error={errors?.image?.message}
                                    defaultValue={imageUrl}
                                    onChange={(image) => field.onChange(image)}
                                />
                            )}
                        />
                    </div>
                    <div className="promotion_item">
                        <label className="promotion_item_label">
                            {t("naming")}
                        </label>
                        <div className="promotion_item_translation">
                            <div className="promotion_item_translation_text">
                                EN
                            </div>
                            <Input
                                type="input"
                                inputChangeOptions={register(
                                    "nameTranslations.en"
                                )}
                                error={errors?.nameTranslations?.en?.message}
                            />
                        </div>
                    </div>
                    <div className="promotion_item">
                        <div className="promotion_item_translation">
                            <div className="promotion_item_translation_text">
                                RU
                            </div>
                            <Input
                                type="input"
                                inputChangeOptions={register(
                                    "nameTranslations.ru"
                                )}
                                error={errors?.nameTranslations?.ru?.message}
                            />
                        </div>
                    </div>
                    <div className="promotion_item">
                        <div className="promotion_item_translation">
                            <div className="promotion_item_translation_text">
                                TR
                            </div>
                            <Input
                                type="input"
                                inputChangeOptions={register(
                                    "nameTranslations.tr"
                                )}
                                error={errors?.nameTranslations?.tr?.message}
                            />
                        </div>
                    </div>
                    <div className="promotion_item">
                        <label className="promotion_item_label">
                            {t("description")}
                        </label>
                        <div className="promotion_item_translation">
                            <div className="promotion_item_translation_text">
                                EN
                            </div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "descriptionTranslations.en"
                                )}
                                error={
                                    errors?.descriptionTranslations?.en?.message
                                }
                            />
                        </div>
                    </div>
                    <div className="promotion_item">
                        <div className="promotion_item_translation">
                            <div className="promotion_item_translation_text">
                                RU
                            </div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "descriptionTranslations.ru"
                                )}
                                error={
                                    errors?.descriptionTranslations?.ru?.message
                                }
                            />
                        </div>
                    </div>
                    <div className="promotion_item">
                        <div className="promotion_item_translation">
                            <div className="promotion_item_translation_text">
                                TR
                            </div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "descriptionTranslations.tr"
                                )}
                                error={
                                    errors?.descriptionTranslations?.tr?.message
                                }
                            />
                        </div>
                    </div>
                    <div className="promotion_item">
                        <label className="promotion_item_label">
                            {t("extended-description")}
                        </label>
                        <div className="promotion_item_translation">
                            <div className="promotion_item_translation_text">
                                EN
                            </div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "extendedDescription.en"
                                )}
                                error={errors?.extendedDescription?.en?.message}
                            />
                        </div>
                    </div>
                    <div className="promotion_item">
                        <div className="promotion_item_translation">
                            <div className="promotion_item_translation_text">
                                RU
                            </div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "extendedDescription.ru"
                                )}
                                error={errors?.extendedDescription?.ru?.message}
                            />
                        </div>
                    </div>
                    <div className="promotion_item">
                        <div className="promotion_item_translation">
                            <div className="promotion_item_translation_text">
                                TR
                            </div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "extendedDescription.tr"
                                )}
                                error={errors?.extendedDescription?.tr?.message}
                            />
                        </div>
                    </div>
                    <div className="promotion_item">
                        <label className="promotion_item_label">
                            {t("activated-accounts")}
                        </label>
                        <Input
                            type="input"
                            inputChangeOptions={register("activatedAccounts")}
                            error={errors?.activatedAccounts?.message}
                        />
                    </div>
                    <div className="promotion_item">
                        <label className="promotion_item_label">
                            {t("rate")}
                        </label>
                        <Input
                            type="input"
                            inputChangeOptions={register("rate")}
                            error={errors?.rate?.message}
                        />
                    </div>
                    <div className="promotion_item">
                        <label className="promotion_item_label">
                            {t("bought-services")}
                        </label>
                        <Input
                            type="input"
                            inputChangeOptions={register("bought")}
                            error={errors?.bought?.message}
                        />
                    </div>

                    <div className="promotion_item">
                        <label className="promotion_item_label">
                            Service for
                        </label>
                        <select
                            className={`promotion_item_select_date`}
                            onChange={(e) => setServiceFor(e.target.value)}
                            {...register("serviceFor")}
                        >
                            <option value={promotionServiceFor.specialist}>
                                {promotionServiceFor.specialist}
                            </option>
                            <option value={promotionServiceFor.customer}>
                                {promotionServiceFor.customer}
                            </option>
                        </select>
                    </div>

                    <div className="promotion_item">
                        <label className="promotion_item_label">
                            {id
                                ? t("promotion.period")
                                : t("promotion.type-and-period")}
                        </label>
                        {!id && (
                            <select
                                className={`promotion_item_select_type`}
                                {...register("type")}
                            >
                                {Object.values(promotionTypes).map(
                                    ({ label, value }) => (
                                        <option key={value} value={value}>
                                            {t(label)}
                                        </option>
                                    )
                                )}
                            </select>
                        )}
                        <select
                            className={`promotion_item_select_date`}
                            onChange={(e) => setCurrentDate(e.target.value)}
                        >
                            {Object.values(promotionFormPricesList).map(
                                ({ label, value }) => (
                                    <option key={value} value={value}>
                                        {t(label)}
                                    </option>
                                )
                            )}
                        </select>
                        {errors?.type && (
                            <p className="promotion_item-error">
                                {t(errors?.type.message)}
                            </p>
                        )}
                    </div>

                    <div className="promotion_form-add-price">
                        <span className="promotion_form-add-price-info">
                            {t("add-price-for-period")}:{" "}
                            {t(`promotion.prices.${currentDate}`)}
                        </span>
                        <button
                            className="promotion_form-add-price-btn"
                            type="button"
                            onClick={addMonth}
                        >
                            <Icon type="addPhotoCircle" />
                        </button>
                    </div>
                    {currentPrices?.[currentDate]?.length > 0 &&
                        currentPrices[currentDate].map((priceItem, index) => {
                            const field = `prices.${currentDate}[${index}]`;
                            return (
                                <FieldSet
                                    name={field}
                                    key={
                                        Date.now().toString(36) +
                                        Math.random().toString(36).substr(2)
                                    }
                                    errorFirstOption={
                                        errors?.prices?.currentDate?.[index]
                                            ?.price?.message
                                    }
                                    errorSecondOption={
                                        errors?.prices?.currentDate?.[index]
                                            ?.count?.message
                                    }
                                    labelOptionsFirst={t("price")}
                                    handleDeleteFieldset={(e) =>
                                        handleDeleteFieldset(
                                            e,
                                            priceItem.price,
                                            index
                                        )
                                    }
                                    handleChangeOptionFisrt={(e) =>
                                        setValue(
                                            `${field}.price`,
                                            handleConvertNumberValue(e)
                                        )
                                    }
                                    handleChangeOptionSecond={(e) =>
                                        setValue(
                                            `${field}.count`,
                                            handleConvertNumberValue(e)
                                        )
                                    }
                                    labelOptionsSecond={t("period")}
                                    placeholderOptionsFirst={t("price")}
                                    placeholderOptionsSecond={t("period")}
                                    inputOptionsFirst={register(
                                        `${field}.price`
                                    )}
                                    inputOptionsSecond={register(
                                        `${field}.count`
                                    )}
                                    currencyInputName={`${field}.price`}
                                    control={control}
                                />
                            );
                        })}

                    <div className="promotion_service">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button
                            type="submit"
                            buttonStyle={"btn--green"}
                            loading={loading}
                        >
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default PromotionForm;
