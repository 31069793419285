import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { memo, useEffect } from "react";

import {
    deleteClarification,
    editPageClarification,
    getAllClarification,
} from "../../../redux/operations/categoryOperations";
import { openModalActionCreator } from "../../../redux/actions/modalActions";
import {
    allClarificationParamsSelector,
    allClarificationSelector,
    allSubcategorySelector,
} from "../../../redux/selectors/categorySelectors";

import {
    clarificationsTitle,
    sizeClarificationsOnPage,
} from "./clarifications.config";
import Clarification from "./Clarification/Clarification";
import Pagination from "../../Pagination/Pagination";

import "./Clarifications.scss";

const Clarifications = () => {
    const { items: subcategories } = useSelector(allSubcategorySelector);
    const { page } = useSelector(allClarificationParamsSelector);
    const clarification = useSelector(allClarificationSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => dispatch(getAllClarification(page)), []);

    const handleOnAdd = () => {
        dispatch(
            openModalActionCreator({
                typeModal: "ADD_CLARIFICATION",
                details: {},
            })
        );
    };

    const handleOnEdit = (clarification) => {
        dispatch(
            openModalActionCreator({
                typeModal: "EDIT_CLARIFICATION",
                details: clarification,
            })
        );
    };

    const handleOnDelete = (id) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    id,
                    title: "clarification",
                    action: () => dispatch(deleteClarification(id)),
                },
            })
        );
    };

    return (
        subcategories.length > 0 && (
            <div className="clarifications-conatiner">
                <h1 className="clarifications-conatiner__title">
                    {t("clarification")}
                </h1>
                <table className="clarifications">
                    <thead className="clarifications__header">
                        <tr className="clarifications__header_item">
                            {clarificationsTitle.map((title, index) => (
                                <th
                                    className="clarifications__title"
                                    key={index}
                                >
                                    {t(title)}
                                </th>
                            ))}
                            <th className="clarifications__title">
                                <button
                                    className="clarifications__btn"
                                    onClick={handleOnAdd}
                                >
                                    {t("services.clarification.add")}
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="clarifications__body">
                        {clarification.items.map((clarification) => (
                            <Clarification
                                onDelete={() =>
                                    handleOnDelete(clarification._id)
                                }
                                onEdit={() => handleOnEdit(clarification)}
                                key={clarification._id + clarification.title}
                                {...clarification}
                            />
                        ))}
                    </tbody>
                </table>
                <Pagination
                    getNextPageItems={(e) => dispatch(editPageClarification(e))}
                    sizeItemOnPage={sizeClarificationsOnPage}
                    items={clarification}
                    isStartedPage={page}
                    elementType="page"
                />
            </div>
        )
    );
};

export default memo(Clarifications);
