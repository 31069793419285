export const toastifySuccessMessages = {
    PUT_SPEICALISTS_SERVICE: "toastify-put-specialist-service",
    DELETE_USER_AVATAR: "toastify-delete-user-avatar",
    ADD_NEW_USER: "toastify-add-new-user",
    DELETE_USER: "toastify-delete-user",
    PUT_USER: "toastify-put-user",

    UNBLOCKED_USER: "toastify-unblocked-user",
    BLOCKED_USER: "toastify-blocked-user",

    DELETE_FEEDBACK: "toastify-delete-feedback",
    PUT_FEEDBACK: "toastify-put-feedback",

    DELETE_CLARIFICATION: "toastify-delete-clarifaction",
    POST_CLARIFICATION: "toastify-post-clarifaction",
    PUT_CLARIFICATION: "toastify-put-clarifaction",

    DELETE_SUBCATEGORY: "toastify-delete-subcategory",
    POST_SUBCATEGORY: "toastify-post-subcategory",
    PUT_SUBCATEGORY: "toastify-put-subcategory",

    DELETE_CATEGORY: "toastify-delete-category",
    POST_CATEGORY: "toastify-post-category",
    PUT_CATEGORY: "toastify-put-category",

    DELETE_TAG: "toastify-delete-tag",
    POST_TAG: "toastify-post-tag",
    PUT_TAG: "toastify-put-tag",

    DELETE_COMPLAINT_USER: "toastify-delete-complaint-user",
    DELETE_COMPLAINT_TYPE: "toastify-delete-complaint-type",
    POST_COMPLAINT_TYPE: "toastify-post-complaint-type",
    PUT_COMPLAINT_TYPE: "toastify-put-complaint-type",

    DELETE_PROMOTION: "toastify-delete-promotion",
    POST_PROMOTION: "toastify-post-promotion",
    PUT_PROMOTION: "toastify-put-promotion",
    DEACTIVATE_PROMOTION: "toastify-deactivate-promotion",

    DEACTIVATE_SUPPORT_TICKET: "toastify-deactivate-support-ticket",
    DEACTIVATE_SUPPORT_TICKET_ERROR: "toastify-deactivate-support-ticket-error",

    DELETE_ADMIN: "toastify-delete-admin",
    PATCH_ADMIN: "toastify-patch-admin",
    POST_ADMIN: "toastify-post-admin",

    DELETE_BLOG: "toastify-delete-blog",
    PUT_BLOG: "toastify-put-blog",
    POST_BLOG: "toastify-post-blog",

    DELETE_FAQ: "toastify-delete-faq",
    PUT_FAQ: "toastify-put-faq",
    POST_FAQ: "toastify-post-faq",

    WELCOME: "toastify-welcome",
};
