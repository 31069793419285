import { get } from "../api";

export const getUserById = async (token) => {
    const url = `/user/me`;

    try {
        const { data } = await get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
