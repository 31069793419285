import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { yupResolver } from "@hookform/resolvers/yup";
import { newUserFormConfig } from "./NewUserForm.config";
import { accountRoles } from "../../../configs/accountRoles";
import { NewUserFormSchema } from "./NewUserFormSchema.Schema";

import { closeModalActionCreator } from "../../../redux/actions/modalActions";

import Button from "../../../shared/Button/Button";
import LocationSelect from "../../../shared/LocationSelect/LocationSelect";

import { citiesLoaderSelector } from "../../../redux/selectors/locationSelectors";
import { useEffect, useState } from "react";
import { isObjectEqual } from "../../../helpers/isObjectEqual";
import { getAllDistricts } from "../../../redux/operations/locationOperations";
import { postNewUser } from "../../../redux/operations/accountOperations";

import "./NewUserForm.scss";
const NewUserForm = () => {
    const { cities, districts } = useSelector(citiesLoaderSelector);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [selectedCity, setSelectedCity] = useState({
        state_code: "",
        name: "",
    });

    const {
        control,
        register,
        resetField,
        handleSubmit,
        formState: { errors, isValid, touchedFields, dirtyFields },
    } = useForm({
        mode: "all",
        resolver: yupResolver(NewUserFormSchema),
    });
    const handleSelectCity = (currentCity) => {
        if (
            isObjectEqual(currentCity, selectedCity) |
            (currentCity.name === selectedCity.name)
        )
            return;
        resetField("district");
        setSelectedCity(currentCity);
    };

    const handleCloseModal = () => dispatch(closeModalActionCreator());
    const handleFormSubmit = (data) => dispatch(postNewUser(data));

    useEffect(() => {
        if (selectedCity?.state_code || selectedCity.city)
            dispatch(
                getAllDistricts(selectedCity.state_code, selectedCity.city)
            );
    }, [selectedCity, selectedCity.city]);

    return (
        <form
            className={"new_user_form"}
            onSubmit={handleSubmit(handleFormSubmit)}
        >
            <div className="new_user_form__content">
                <h2 className="new_user_form__title">{t("new_user")}</h2>
                <div className="new_user_form__items">
                    {newUserFormConfig.map(({ label, placeholder, value }) => (
                        <div key={value} className="new_user__item">
                            <label className="new_user__item_label">
                                {t(label)}
                            </label>
                            <input
                                placeholder={t(placeholder)}
                                className={`new_user__item_input ${
                                    (errors[value] && "error") ||
                                    (touchedFields[value] && "confirmed") ||
                                    (dirtyFields[value] && "confirmed")
                                }`}
                                {...register(value)}
                            />
                            {errors?.[value]?.message && (
                                <span className="new_user__item-error">
                                    {t(errors[value].message)}
                                </span>
                            )}
                        </div>
                    ))}
                    <div className="new_user__item">
                        <label className="new_user__item_label">
                            {t("services-specialist-city")}
                        </label>
                        <Controller
                            name="city"
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={cities}
                                    error={errors.city?.message}
                                    placeholder={t("city-placeholder")}
                                    onSelect={(city) => {
                                        handleSelectCity(city);
                                        onChange(city.name);
                                    }}
                                    onBlur={(city) => onBlur(city.name)}
                                />
                            )}
                        />
                    </div>
                    <div className="new_user__item">
                        <label className="new_user__item_label">
                            {t("services-specialist-district")}
                        </label>
                        <Controller
                            control={control}
                            name="district"
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={districts}
                                    placeholder={t("district-placeholder")}
                                    onSelect={(district) => onChange(district.name)}
                                    onBlur={(district) => onBlur(district.name)}
                                    error={errors.district?.message}
                                />
                            )}
                        />
                    </div>
                    <div className="new_user__item">
                        <label className="new_user__item_label">
                            {t("role")}
                        </label>
                        <select
                            className={`new_user__item_select ${
                                errors.category ? "error" : ""
                            }`}
                            {...register("role")}
                        >
                            {Object.values(accountRoles).map(
                                ({ key, text }) => (
                                    <option key={key} value={key}>
                                        {t(text)}
                                    </option>
                                )
                            )}
                        </select>
                        {errors.role && (
                            <span className="new_user__item-error">
                                {t(errors.role.message)}
                            </span>
                        )}
                    </div>
                </div>
                <div className="new_user__service">
                    <Button onClick={handleCloseModal} buttonStyle={"btn--red"}>
                        {t("btn-cancel")}
                    </Button>
                    <Button
                        type="submit"
                        buttonStyle={"btn--green"}
                        disabled={!isValid}
                    >
                        {t("btn-save")}
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default NewUserForm;
