import { string, object } from "yup";
import { t } from "i18next";

const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const adminFormSchema = object().shape({
    firstName: string()
        .trim(true)
        .required("required")
        .min(3, ({ min }) => t("validation-min", { min }))
        .max(20, ({ max }) => t("validation-max", { max })),
    lastName: string()
        .trim(true)
        .required("required")
        .min(3, ({ min }) => t("validation-min", { min }))
        .max(20, ({ max }) => t("validation-max", { max })),
    phone: object().shape({
        dialCode: string()
            .required("required")
            .min(0, ({ min }) => t("validation-min", { min }))
            .max(4, ({ max }) => t("validation-max", { max })),
        number: string()
            .required("required")
            .matches(phoneRegExp, "wrong-phone")
            .min(7, ({ min }) => t("validation-min", { min }))
            .max(13, ({ max }) => t("validation-max", { max })),
    }),

    city: string().required("required"),
    district: string().required("required"),
    password: string()
        .trim()
        .when("email", (val) => {
            if (val) {
                return string().notRequired();
            } else {
                return string()
                    .trim()
                    .required("required")
                    .nullable(true)
                    .transform((value) => (value === "" ? null : value))
                    .min(6, ({ min }) => t("validation-min", { min }))
                    .max(20, ({ max }) => t("validation-max", { max }));
            }
        }),
    email: string().trim(true).email("wrong-email").required("required"),
    role: string().required("required"),
});
