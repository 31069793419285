import { websocketsTypes } from "./websockets.types";

// connect
export const openingWebsocketConnection = () => ({
    type: websocketsTypes.OPENING_WEBSOCKET_CONNECTION,
});

export const websocketConnected = (payload) => ({
    type: websocketsTypes.WEBSOCKET_CONNECTED,
    payload,
});

// disconnect
export const disconnectWebsocket = () => ({
    type: websocketsTypes.DISCONNECT_WEBSOCKET,
});

// set user online status
export const setUserOnlineStatus = (payload) => ({
    type: websocketsTypes.SET_USER_ONLINE_STATUS,
    payload,
});
