import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import Button from "../../../shared/Button/Button";
import PhotoUploader from "../../PhotoUploader/PhotoUploader";

import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import {
    postCategory,
    putCategory,
} from "../../../redux/operations/categoryOperations";
import { CategoryFormSchema } from "./CategoryForm.Schema";
import { categorySelector } from "../../../redux/selectors/categorySelectors";

import "./index.scss";

const CategoryForm = ({ details }) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
    } = useForm({
        mode: "all",
        defaultValues: { ...details },
        resolver: yupResolver(CategoryFormSchema),
    });
    const { loading } = useSelector(categorySelector);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { _id } = details;

    const handleCloseModal = () => dispatch(closeModalActionCreator());

    const onSubmit = (data) => {
        if (_id) {
            dispatch(putCategory(data, _id));
        } else {
            dispatch(postCategory(data));
        }
    };
    return (
        <form className="category_form" onSubmit={handleSubmit(onSubmit)}>
            <div className="category_form__content">
                <h2 className="category_form__title">
                    {t(`services.category.${_id ? "edit" : "add"}`)}
                </h2>
                <div className="category_form__items">
                    <div className="category__item">
                        <Controller
                            name="icon"
                            control={control}
                            render={({ field }) => (
                                <PhotoUploader
                                    {...field}
                                    defaultValue={details?.icon}
                                    onChange={(avatar) => {
                                        field.onChange(avatar);
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="category__item">
                        <label className="category__item_label">
                            {t("naming")}
                        </label>
                        <input
                            className={`category__item_input ${
                                errors?.remark && "error"
                            }`}
                            {...register("remark")}
                        />
                        {errors?.remark && (
                            <p className="category__item-error">
                                {t(errors?.remark.message)}
                            </p>
                        )}
                    </div>
                    <div className="category__item">
                        <label className="category__item_label">
                            {t("translations")}
                        </label>
                        <div className="category__item_translation">
                            <div className="category__item_translation_text">
                                EN
                            </div>
                            <input
                                className={`category__item_input ${
                                    errors?.titleTranslations?.en && "error"
                                }`}
                                {...register("titleTranslations.en")}
                            />
                        </div>
                        {errors?.titleTranslations?.en && (
                            <p className="category__item_translation-error">
                                {t(errors?.titleTranslations?.en.message)}
                            </p>
                        )}
                    </div>
                    <div className="category__item">
                        <div className="category__item_translation">
                            <div className="category__item_translation_text">
                                RU
                            </div>
                            <input
                                className={`category__item_input ${
                                    errors?.titleTranslations?.ru && "error"
                                }`}
                                {...register("titleTranslations.ru")}
                            />
                        </div>
                        {errors?.titleTranslations?.ru && (
                            <p className="category__item_translation-error">
                                {t(errors?.titleTranslations?.ru.message)}
                            </p>
                        )}
                    </div>
                    <div className="category__item">
                        <div className="category__item_translation">
                            <div className="category__item_translation_text">
                                TR
                            </div>
                            <input
                                className={`category__item_input ${
                                    errors?.titleTranslations?.tr && "error"
                                }`}
                                {...register("titleTranslations.tr")}
                            />
                        </div>
                        {errors?.titleTranslations?.tr && (
                            <span className="category__item_translation-error">
                                {t(errors?.titleTranslations?.tr.message)}
                            </span>
                        )}
                    </div>
                    <div className="category__service">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button
                            type="submit"
                            buttonStyle={"btn--green"}
                            loading={loading}
                        >
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CategoryForm;
