export const usersComplaints = [
    { field: "type", key: "type" },
    { field: "problem", key: "problem" },
    { field: "creator", key: "creator" },
    { field: "creator`s email", key: "" },
    { field: "user", key: "user" },
    { field: "user`s email", key: "" },
    { field: "options", key: "" },
];

export const sizeItemOnPage = 7;
