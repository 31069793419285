import dayjs from "dayjs";

export const convertScheduleInputValuesToTimeString = (schedule) => {
    const updatedSchedule = [];
    schedule.forEach((item) => {
        if (!item.isWeekend) {
            if (item.workingHours[0].timeFrom.length <= 5) {
                item.workingHours[0].timeFrom = dayjs()
                    .hour(
                        item.workingHours[0].timeFrom.substring(
                            0,
                            item.workingHours[0].timeFrom.indexOf(":")
                        )
                    )
                    .minute(0)
                    .toISOString();
            }
            if (item.workingHours[0].timeTo.length <= 5) {
                item.workingHours[0].timeTo = dayjs()
                    .hour(
                        item.workingHours[0].timeTo.substring(
                            0,
                            item.workingHours[0].timeTo.indexOf(":")
                        )
                    )
                    .minute(0)
                    .toISOString();
            }

            updatedSchedule.push(item);
        }
    });
    return updatedSchedule;
};
