import * as promotionTypes from "../types/promotionTypes";

// get promotion
export const getPromotionRequest = () => ({
    type: promotionTypes.GET_PROMOTION_REQUEST,
});

export const getPromotionSuccess = (payload) => ({
    type: promotionTypes.GET_PROMOTION_SUCCESS,
    payload,
});

export const getPromotionError = (payload) => ({
    type: promotionTypes.GET_PROMOTION_ERROR,
    payload,
});

// get promotion by id
export const getPromotionByIdPending = () => ({
    type: promotionTypes.GET_PROMOTION_BY_ID_PENDING,
});

export const getPromotionByIdFulfilled = (data) => ({
    type: promotionTypes.GET_PROMOTION_BY_ID_FULFILLED,
    payload: data,
});

export const getPromotionByIdRejected = (error) => ({
    type: promotionTypes.GET_PROMOTION_BY_ID_REJECTED,
    payload: error,
});

// post promotion
export const postPromotionRequest = () => ({
    type: promotionTypes.POST_PROMOTION_REQUEST,
});

export const postPromotionSuccess = (payload) => ({
    type: promotionTypes.POST_PROMOTION_SUCCESS,
    payload,
});

export const postPromotionError = (payload) => ({
    type: promotionTypes.POST_PROMOTION_ERROR,
    payload,
});

// put promotion
export const putPromotionRequest = () => ({
    type: promotionTypes.PUT_PROMOTION_REQUEST,
});

export const putPromotionSuccess = (payload) => ({
    type: promotionTypes.PUT_PROMOTION_SUCCESS,
    payload,
});

export const putPromotionError = (payload) => ({
    type: promotionTypes.PUT_PROMOTION_ERROR,
    payload,
});

// delete promotion
export const deletePromotionRequest = () => ({
    type: promotionTypes.DELETE_PROMOTION_REQUEST,
});

export const deletePromotionSuccess = (payload) => ({
    type: promotionTypes.DELETE_PROMOTION_SUCCESS,
    payload,
});

export const deletePromotionError = (payload) => ({
    type: promotionTypes.DELETE_PROMOTION_ERROR,
    payload,
});

//set
export const clearAllParams = () => ({
    type: promotionTypes.CLEAR_ALL_PARAMS,
});

export const setPage = (data) => ({
    type: promotionTypes.SET_PAGE,
    payload: data,
});

// get list of bought promotions
export const getListOfBoughtPromotionsRequest = () => ({
    type: promotionTypes.GET_LIST_OF_BOUGHT_PROMOTIONS_REQUEST,
});

export const getListOfBoughtPromotionsSuccess = (payload) => ({
    type: promotionTypes.GET_LIST_OF_BOUGHT_PROMOTIONS_SUCCESS,
    payload,
});

export const getListOfBoughtPromotionsError = (payload) => ({
    type: promotionTypes.GET_LIST_OF_BOUGHT_PROMOTIONS_ERROR,
    payload,
});

// deactivate bought promotions item
export const putBoughtPromotionsItemRequest = () => ({
    type: promotionTypes.PUT_BOUGHT_PROMOTIONS_ITEM_REQUEST,
});

export const putBoughtPromotionsItemSuccess = (payload) => ({
    type: promotionTypes.PUT_BOUGHT_PROMOTIONS_ITEM_SUCCESS,
    payload,
});

export const putBoughtPromotionsItemError = (payload) => ({
    type: promotionTypes.PUT_BOUGHT_PROMOTIONS_ITEM_ERROR,
    payload,
});

// set history
export const setHistoryPage = (data) => ({
    type: promotionTypes.SET_HISTORY_PAGE,
    payload: data,
});

export const clearAllHistoryParams = () => ({
    type: promotionTypes.CLEAR_ALL_HISTORY_PARAMS,
});
