import { post } from "../api";

export const sendLoginRequest = async (reqData = {}) => {
    const url = `/auth/login/admin`;

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};
