export const promotionFormPricesList = {
    month: {
        label: "promotion.prices.month",
        value: "month",
    },
    year: {
        label: "promotion.prices.year",
        value: "year",
    },
    week: {
        label: "promotion.prices.week",
        value: "week",
    },
    day: {
        label: "promotion.prices.day",
        value: "day",
    },
};

export const promotionServiceFor = {
    specialist: "SPECIALIST",
    customer: "CUSTOMER",
};
