import * as supportTypes from "../types/supportTypes";

// deactivate support ticket
export const deactivateSupportTicketRequest = () => ({
    type: supportTypes.DEACTIVATE_SUPPORT_TICKET_REQUEST,
});

export const deactivateSupportTicketSuccess = (payload) => ({
    type: supportTypes.DEACTIVATE_SUPPORT_TICKET_SUCCESS,
    payload,
});

export const deactivateSupportTicketError = (payload) => ({
    type: supportTypes.DEACTIVATE_SUPPORT_TICKET_ERROR,
    payload,
});

//get support tickets//get
export const getSupportTicketsRequest = (data) => ({
    type: supportTypes.GET_SUPPORT_TICKETS_REQUEST,
    payload: data,
});

export const getSupportTicketsSuccess = (data) => ({
    type: supportTypes.GET_SUPPORT_TICKETS_SUCCESS,
    payload: data,
});

export const getSupportTicketsError = (data) => ({
    type: supportTypes.GET_SUPPORT_TICKETS_ERROR,
    payload: data,
});

// get support chat
export const getSupportChatRequest = () => ({
    type: supportTypes.GET_SUPPORT_CHAT_REQUEST,
});

export const getSupportChatSuccess = (data) => ({
    type: supportTypes.GET_SUPPORT_CHAT_SUCCESS,
    payload: data,
});

export const getSupportChatError = (error) => ({
    type: supportTypes.GET_SUPPORT_CHAT_ERROR,
    payload: error,
});

//post support chat file
export const postSupportChatFileRequest = () => ({
    type: supportTypes.POST_SUPPORT_CHAT_FILE_REQUEST,
});

export const postSupportChatFileSuccess = (data) => ({
    type: supportTypes.POST_SUPPORT_CHAT_FILE_SUCCESS,
    payload: data,
});

export const postSupportChatFileError = (error) => ({
    type: supportTypes.POST_SUPPORT_CHAT_FILE_ERROR,
    payload: error,
});

//join support chat
export const joinToSupportChatRequest = () => ({
    type: supportTypes.JOIN_TO_SUPPORT_CHAT_QUESTION_REQUEST,
});

export const joinToSupportChatSuccess = () => ({
    type: supportTypes.JOIN_TO_SUPPORT_CHAT_QUESTION_SUCCESS,
});

export const joinToSupportChatError = (error) => ({
    type: supportTypes.JOIN_TO_SUPPORT_CHAT_QUESTION_ERROR,
    payload: error,
});

//disable
export const disableSupportChatRequest = () => ({
    type: supportTypes.DISABLE_SUPPORT_CHAT_REQUEST,
});

export const disableSupportChatSuccess = (data) => ({
    type: supportTypes.DISABLE_SUPPORT_CHAT_SUCCESS,
    payload: data,
});

export const disableSupportChatError = (error) => ({
    type: supportTypes.DISABLE_SUPPORT_CHAT_ERROR,
    payload: error,
});

//set
export const setPage = (data) => ({
    type: supportTypes.SET_PAGE,
    payload: data,
});

export const setText = (data) => ({
    type: supportTypes.SET_TEXT,
    payload: data,
});

export const clearAllParams = () => ({
    type: supportTypes.CLEAR_ALL_PARAMS,
});
export const clearAllParamsSupportChat = () => ({
    type: supportTypes.CLEAR_ALL_PARAMS_SUPPORT_CHAT,
});
