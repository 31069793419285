import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { editSearchText } from "../../../../redux/operations/adminOperations";
import { adminParamsSelector } from "../../../../redux/selectors/adminSelectors";

import "./index.scss";

const AdminSearch = () => {
    const { text } = useSelector(adminParamsSelector);
    const [query, setQuery] = useState("");
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSearch = () => dispatch(editSearchText(query.trim()));

    return (
        <div className="accounts-search">
            <input
                onKeyPress={({ key }) => key === "Enter" && handleSearch()}
                onChange={({ target: { value } }) => setQuery(value)}
                className="accounts-search__input"
                placeholder={t("search")}
                value={query}
            ></input>
            <button
                disabled={text.split("=")[1] === query}
                className="accounts-search__btn"
                onClick={handleSearch}
            >
                {t("btn-search")}
            </button>
        </div>
    );
};

export default AdminSearch;
