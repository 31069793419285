import * as adminTypes from "../types/adminTypes";

// get admin list
export const getAdminListRequest = () => ({
    type: adminTypes.GET_ADMIN_LIST_REQUEST,
});

export const getAdminListSuccess = (payload) => ({
    type: adminTypes.GET_ADMIN_LIST_SUCCESS,
    payload,
});

export const getAdminListError = (payload) => ({
    type: adminTypes.GET_ADMIN_LIST_ERROR,
    payload,
});

// post admin
export const postAdminRequest = () => ({
    type: adminTypes.POST_ADMIN_REQUEST,
});

export const postAdminSuccess = (payload) => ({
    type: adminTypes.POST_ADMIN_SUCCESS,
    payload,
});

export const postAdminError = (payload) => ({
    type: adminTypes.POST_ADMIN_ERROR,
    payload,
});

// put account
export const putAdminRequest = () => ({
    type: adminTypes.PUT_ADMIN_REQUEST,
});

export const putAdminSuccess = (payload) => ({
    type: adminTypes.PUT_ADMIN_SUCCESS,
    payload,
});

export const putAdminError = (payload) => ({
    type: adminTypes.PUT_ADMIN_ERROR,
    payload,
});

// patch admin
export const patchAdminRequest = () => ({
    type: adminTypes.PATCH_ADMIN_REQUEST,
});

export const patchAdminSuccess = (payload) => ({
    type: adminTypes.PATCH_ADMIN_SUCCESS,
    payload,
});

export const patchAdminError = (payload) => ({
    type: adminTypes.PATCH_ADMIN_ERROR,
    payload,
});

// patch permissions
export const patchPermissionsRequest = () => ({
    type: adminTypes.PATCH_PERMISSIONS_REQUEST,
});

export const patchPermissionsSuccess = (payload) => ({
    type: adminTypes.PATCH_PERMISSIONS_SUCCESS,
    payload,
});

export const patchPermissionsError = (payload) => ({
    type: adminTypes.PATCH_PERMISSIONS_ERROR,
    payload,
});

// delete admin
export const deleteAdminRequest = () => ({
    type: adminTypes.DELETE_ADMIN_REQUEST,
});

export const deleteAdminSuccess = (payload) => ({
    type: adminTypes.DELETE_ADMIN_SUCCESS,
    payload,
});

export const deleteAdminError = (payload) => ({
    type: adminTypes.DELETE_ADMIN_ERROR,
    payload,
});

//set
export const clearAllParams = () => ({
    type: adminTypes.CLEAR_ALL_PARAMS,
});

export const setPage = (data) => ({
    type: adminTypes.SET_PAGE,
    payload: data,
});

export const setText = (data) => ({
    type: adminTypes.SET_TEXT,
    payload: data,
});

export const setRole = (data) => ({
    type: adminTypes.SET_ROLE,
    payload: data,
});
