import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { typesComplaints } from "./types-complaints.config";
import {
    deleteComplaintType,
    getAllComplaintType,
} from "../../../redux/operations/complaintOperations";
import { openModalActionCreator } from "../../../redux/actions/modalActions";

import {
    allComplaintParamsSelector,
    allComplaintsTypesSelector,
} from "../../../redux/selectors/complaintSelectors";

import TypeComplaint from "./TypeComplaint/TypeComplaint";
import Button from "../../../shared/Button/Button";

import "./index.scss";

const TypesComplaintsTable = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { items } = useSelector(allComplaintsTypesSelector);
    const { loading } = useSelector(allComplaintParamsSelector);

    useEffect(() => dispatch(getAllComplaintType()), []);

    const handleOnDelete = (_id, title) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    _id,
                    title: title,
                    action: () => dispatch(deleteComplaintType(_id)),
                },
            })
        );
    };

    const handleOnAdd = () => {
        dispatch(
            openModalActionCreator({
                typeModal: "ADD_COMPLAINT_TYPE",
                details: {},
            })
        );
    };

    const handleOnEdit = (text, _id) => {
        dispatch(
            openModalActionCreator({
                typeModal: "EDIT_COMPLAINT_TYPE",
                details: { text, _id },
            })
        );
    };

    return (
        <>
            <table className="types-complaints">
                <thead className="types-complaints__header">
                    <tr className="types-complaints__header_item">
                        {typesComplaints.map((title, index) => (
                            <th
                                className="types-complaints__header_item_title"
                                key={index + title}
                            >
                                {t(title)}
                            </th>
                        ))}
                        <th className="types-complaints__title">
                            <Button
                                onClick={handleOnAdd}
                                buttonStyle={"btn--outlined"}
                                loading={loading}
                            >
                                {t("btn-add")}
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody className="types-complaints__content">
                    {items &&
                        items.map(({ _id, text }) => (
                            <TypeComplaint
                                key={_id}
                                _id={_id}
                                text={text}
                                onEdit={handleOnEdit}
                                onDelete={handleOnDelete}
                            />
                        ))}
                </tbody>
            </table>
        </>
    );
};

export default TypesComplaintsTable;
