import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import LazyImage from "../../../components/LazyImage/LazyImage";
import { typeOfLazeImage } from "../../../components/LazyImage/LazyImage.constants";
import { getPhotoByTypeGender } from "../../../helpers/get-photo-by-type-gender";

import { ReactComponent as SuccessMessage } from "../../../assets/images/chat/success_message.svg";
import { openModalActionCreator } from "../../../redux/actions/modalActions";

import "./SupportChatMessage.scss";

const SupportChatMessage = (message) => {
    const {
        _id: messageId,
        participant,
        createdBy,
        carousel,
        createdAt,
        callback,
        userId,
        readBy,
        image,
        text,
        same,
        video,
    } = message;

    const [duration, setDuration] = useState(0);
    const messageByReceiverRef = useRef(null);
    const messageByCreatorRef = useRef(null);
    const dispatch = useDispatch();

    const handleLoadedMetadata = (ref) => {
        const video = ref.current;
        if (!video) return;
        video.onloadedmetadata = () => setDuration(video.duration);
        return duration;
    };

    const messageByCreator = () => {
        return (
            <div
                className={`chat_body_message_own${!same ? "-same" : ""} ${
                    image ? "--with-image" : ""
                }`}
                onAnimationStart={() => callback && callback((p) => (p += 1))}
            >
                {image ? (
                    <div
                        onLoad={() => callback && callback((p) => (p += 1))}
                        className="chat_body_message_own_image"
                        onClick={() => {
                            dispatch(
                                openModalActionCreator({
                                    isOpen: true,
                                    closeIcon: false,
                                    typeModal: "CHAT_CAROUSEL",
                                    details: { carousel, url: image.url },
                                })
                            );
                        }}
                    >
                        <LazyImage
                            type={typeOfLazeImage.content}
                            options={{ alt: "image" }}
                            image={image.url}
                        />
                    </div>
                ) : (
                    <div className="chat_body_message_own_text">{text}</div>
                )}
                <div className="chat_body_message_own_info">
                    <div className="chat_body_message_own_info_time">
                        {dayjs(createdAt).format("HH:mm")}
                    </div>
                    {readBy.includes(participant?._id) && (
                        <div className="chat_body_message_own_info_status">
                            <SuccessMessage />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const messageByReceiver = () => {
        return (
            <div
                className={`chat_body_message_companion${!same ? "-same" : ""}`}
                onTransitionEnd={() => callback && callback((p) => (p += 1))}
            >
                <div className="chat_body_message_companion_avatar">
                    <LazyImage
                        image={getPhotoByTypeGender(
                            participant?.avatar,
                            participant?.gender
                        )}
                        type={typeOfLazeImage.avatar}
                        options={{ alt: "avatar" }}
                    />
                </div>
                <div
                    className={`chat_body_message_companion_body ${
                        image ? "--with-image" : ""
                    }`}
                >
                    <div className="chat_body_message_companion_body__info">
                        {image ? (
                            <div
                                className="chat_body_message_companion_body_image"
                                onLoad={() =>
                                    callback && callback((p) => (p += 1))
                                }
                                onClick={() => {
                                    dispatch(
                                        openModalActionCreator({
                                            isOpen: true,
                                            closeIcon: false,
                                            typeModal: "CHAT_CAROUSEL",
                                            details: { carousel, messageId },
                                        })
                                    );
                                }}
                            >
                                {image.contentType.includes("image") && (
                                    <LazyImage
                                        type={typeOfLazeImage.content}
                                        options={{ alt: "image" }}
                                        image={image.url}
                                    />
                                )}
                            </div>
                        ) : (
                            <div className="chat_body_message_companion_body_description">
                                {text}
                            </div>
                        )}
                    </div>
                    <div className="chat_body_message_system_body__status">
                        <div className="chat_body_message_system_body__status">
                            <div className="chat_body_message_system_body__status_time">
                                {dayjs(createdAt).format("HH:mm")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderTextMessage = () => {
        return createdBy === userId ? messageByCreator() : messageByReceiver();
    };

    return renderTextMessage();
};

export default SupportChatMessage;
