import * as feedbackTypes from "../types/feedbackTypes";

// get all feedback
export const getAllFeedbackRequest = () => ({
    type: feedbackTypes.GET_ALL_FEEDBACK_REQUEST,
});

export const getAllFeedbackSuccess = (payload) => ({
    type: feedbackTypes.GET_ALL_FEEDBACK_SUCCESS,
    payload,
});

export const getAllFeedbackError = (payload) => ({
    type: feedbackTypes.GET_ALL_FEEDBACK_ERROR,
    payload,
});

// put feedback
export const putFeedbackRequest = () => ({
    type: feedbackTypes.PUT_FEEDBACK_REQUEST,
});

export const putFeedbackSuccess = (payload) => ({
    type: feedbackTypes.PUT_FEEDBACK_SUCCESS,
    payload,
});

export const putFeedbackError = (payload) => ({
    type: feedbackTypes.PUT_FEEDBACK_ERROR,
    payload,
});

// delete feedback
export const deleteFeedbackRequest = () => ({
    type: feedbackTypes.DELETE_FEEDBACK_REQUEST,
});

export const deleteFeedbackSuccess = (payload) => ({
    type: feedbackTypes.DELETE_FEEDBACK_SUCCESS,
    payload,
});

export const deleteFeedbackError = (payload) => ({
    type: feedbackTypes.DELETE_FEEDBACK_ERROR,
    payload,
});

//set
export const clearAllParams = () => ({
    type: feedbackTypes.CLEAR_ALL_PARAMS,
});

export const setPage = (data) => ({
    type: feedbackTypes.SET_PAGE,
    payload: data,
});
