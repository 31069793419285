import * as blogActions from "../types/blogTypes";

// get blog list
export const getBlogListRequest = () => ({
    type: blogActions.GET_BLOG_LIST_REQUEST,
});

export const getBlogListSuccess = (payload) => ({
    type: blogActions.GET_BLOG_LIST_SUCCESS,
    payload,
});

export const getBlogListError = (payload) => ({
    type: blogActions.GET_BLOG_LIST_ERROR,
    payload,
});

// get blog list
export const getBlogByIdRequest = () => ({
    type: blogActions.GET_BLOG_BY_ID_REQUEST,
});

export const getBlogByIdSuccess = (payload) => ({
    type: blogActions.GET_BLOG_BY_ID_SUCCESS,
    payload,
});

export const getBlogByIdError = (payload) => ({
    type: blogActions.GET_BLOG_BY_ID_ERROR,
    payload,
});

// delete blog
export const deleteBlogRequest = () => ({
    type: blogActions.DELETE_BLOG_REQUEST,
});

export const deleteBlogSuccess = (payload) => ({
    type: blogActions.DELETE_BLOG_SUCCESS,
    payload,
});

export const deleteBlogError = (payload) => ({
    type: blogActions.DELETE_BLOG_ERROR,
    payload,
});

// update blog
export const updateBlogRequest = () => ({
    type: blogActions.UPDATE_BLOG_REQUEST,
});

export const updateBlogSuccess = (payload) => ({
    type: blogActions.UPDATE_BLOG_SUCCESS,
    payload,
});

export const updateBlogError = (payload) => ({
    type: blogActions.UPDATE_BLOG_ERROR,
    payload,
});

// update blog
export const createBlogRequest = () => ({
    type: blogActions.CREATE_BLOG_REQUEST,
});

export const createBlogSuccess = (payload) => ({
    type: blogActions.CREATE_BLOG_SUCCESS,
    payload,
});

export const createBlogError = (payload) => ({
    type: blogActions.CREATE_BLOG_ERROR,
    payload,
});

// set
export const setBlogPage = (payload) => ({
    type: blogActions.SET_BLOG_PAGE,
    payload,
});

export const clearAllParams = () => ({
    type: blogActions.CLEAR_ALL_PARAMS,
});
