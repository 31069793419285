import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FAQTitles, sizeItemOnPage } from "./FAQ.config";
import {
    faqParamsSelector,
    faqSelector,
} from "../../../redux/selectors/faqSelectors";
import {
    deleteFAQ,
    editPageFAQ,
    getFAQ,
} from "../../../redux/operations/faqOperations";

import FAQItem from "./FAQItem/FAQItem";
import Button from "../../../shared/Button/Button";

import "./FAQ.scss";
import { openModalActionCreator } from "../../../redux/actions/modalActions";
import Pagination from "../../Pagination/Pagination";

const FAQTable = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { page } = useSelector(faqParamsSelector);
    const { faq, loading } = useSelector(faqSelector);
    const { items } = faq;

    useEffect(() => {
        dispatch(getFAQ());
    }, []);

    const handleOnAdd = () => {
        dispatch(
            openModalActionCreator({
                typeModal: "ADD_FAQ",
                details: {},
            })
        );
    };
    const handleOnEdit = (id, titleTranslations, descriptionTranslations) => {
        dispatch(
            openModalActionCreator({
                typeModal: "EDIT_FAQ",
                details: { id, titleTranslations, descriptionTranslations },
            })
        );
    };
    const handleOnDelete = (id) => {
        dispatch(deleteFAQ(id));
    };
    return (
        <>
            <table className="faq">
                <thead className="faq__header">
                    <tr className="faq__header_item">
                        {FAQTitles.map(({ field, key }) => (
                            <th
                                key={field + key}
                                className="faq__header_item_title"
                            >
                                {key && t(`${field}`)}
                            </th>
                        ))}
                        <th className="faq__header_item_title">
                            <Button
                                buttonStyle={"btn--outlined"}
                                onClick={handleOnAdd}
                            >
                                {t("btn-add")}
                            </Button>
                        </th>
                    </tr>
                </thead>
                {items.length > 0 && !loading ? (
                    <tbody className="faq__content">
                        {items.map((faq) => (
                            <FAQItem
                                {...faq}
                                key={faq._id}
                                onEdit={handleOnEdit}
                                onDelete={handleOnDelete}
                            />
                        ))}
                    </tbody>
                ) : (
                    ""
                )}
            </table>
            <Pagination
                getNextPageItems={(e) => dispatch(editPageFAQ(e))}
                sizeItemOnPage={sizeItemOnPage}
                items={faq}
                isStartedPage={page}
                scrollToTop={true}
                elementType="page"
            />
        </>
    );
};

export default FAQTable;
