import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { closeModalActionCreator } from "../../../redux/actions/modalActions";

import Document from "../../Document/Document";

import revertPhoto from "../../../assets/images/revert_photo_test.jpg";
import driverLicence from "../../../assets/images/driver_licence.jpg";
import taxPayerCard from "../../../assets/images/tax_payer_card.jpg";
import idCard from "../../../assets/images/document_test.jpg";
import "./DocumentForm.scss";

const documents = [
    { id: 62541, photo: idCard },
    { id: 62542, photo: revertPhoto },
    { id: 62543, photo: driverLicence },
    { id: 62544, photo: taxPayerCard },
];

const DocumentForm = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <div className="document_form">
            <div className="document_form__content">
                <p className="document_form__name">Ткаченко Иван Петрович</p>
                {documents.map((document, index) => (
                    <Document key={index} photo={document.photo} />
                ))}
                <div className="document_form__service">
                    <button
                        type="submit"
                        className="document_form__service--accept"
                    >
                        {t("btn-confirm")}
                    </button>
                    <button
                        className="document_form__service--cancel"
                        onClick={() => dispatch(closeModalActionCreator())}
                    >
                        {t("btn-reject")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocumentForm;
