import { loaderTypes } from "./loader.types";

const initialState = {
    globalLoader: false,
};

export const loader = (state = initialState, { type, payload }) => {
    switch (type) {
        case loaderTypes.CHANGE_GLOBAL_LOADER:
            return {
                ...state,
                globalLoader: payload,
            };

        default:
            return state;
    }
};
