import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

const Tag = ({ name, onEdit, onDelete }) => {
    const { t } = useTranslation();

    return (
        <tr className="title">
            <td>{name}</td>
            <td className="tag__options">
                <button className="tag__options--edit" onClick={onEdit}>
                    {t("btn-edit")}
                </button>
                <button className="tag__options--delete" onClick={onDelete}>
                    {t("btn-delete")}
                </button>
            </td>
        </tr>
    );
};

export default Tag;
