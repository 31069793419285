import PropTypes from "prop-types";

import "./index.scss";

const BackButton = ({ title, onClickCallback }) => {
    return (
        <button className="back-button" onClick={() => onClickCallback()}>
            <span className="back-button__arrow" />
            {title}
        </button>
    );
};

BackButton.propTypes = {
    title: PropTypes.string.isRequired,
    onClickCallback: PropTypes.func.isRequired,
};

export default BackButton;
