import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React from "react";

const ForbiddenPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div>
            <h1>{t("forbidden-page")}</h1>
            <button onClick={() => navigate(-1)}>{t("btn-come-back")}</button>
        </div>
    );
};

export default ForbiddenPage;
