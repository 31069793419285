import * as tagsActions from "../types/tagsTypes";

// get tags
export const getAllTagsRequest = () => ({
    type: tagsActions.GET_ALL_TAGS_REQUEST,
});

export const getAllTagsSuccess = (payload) => ({
    type: tagsActions.GET_ALL_TAGS_SUCCESS,
    payload,
});

export const getAllTagsError = (payload) => ({
    type: tagsActions.GET_ALL_TAGS_ERROR,
    payload,
});

// post tag
export const postTagRequest = () => ({
    type: tagsActions.POST_TAG_REQUEST,
});

export const postTagSuccess = (payload) => ({
    type: tagsActions.POST_TAG_SUCCESS,
    payload,
});

export const postTagError = (payload) => ({
    type: tagsActions.POST_TAG_ERROR,
    payload,
});

// put tag
export const putTagRequest = () => ({
    type: tagsActions.PUT_TAG_REQUEST,
});

export const putTagSuccess = (payload) => ({
    type: tagsActions.POST_TAG_SUCCESS,
    payload,
});

export const putTagError = (payload) => ({
    type: tagsActions.POST_TAG_ERROR,
    payload,
});

// delete tag
export const deleteTagRequest = () => ({
    type: tagsActions.DELETE_TAG_REQUEST,
});

export const deleteTagSuccess = (payload) => ({
    type: tagsActions.DELETE_TAG_SUCCESS,
    payload,
});

export const deleteTagError = (payload) => ({
    type: tagsActions.DELETE_TAG_ERROR,
    payload,
});
