import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { useEffect, useState } from "react";

import PhotoUploader from "../../PhotoUploader/PhotoUploader";
import Button from "../../../shared/Button/Button";
import { blogSchema } from "./BlogForm.Schema";
import { textEditorModules, textEditorFormats } from "./BlogForm.config";

import { postBlog, putBlog } from "../../../redux/operations/blogOperations";
import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import { allCategorySelector } from "../../../redux/selectors/categorySelectors";

import { getLanguage } from "../../../helpers/language";

import "react-quill/dist/quill.snow.css";
import "./BlogForm.scss";

const EN_TITLE = "EN_TITLE";
const RU_TITLE = "RU_TITLE";
const TR_TITLE = "TR_TITLE";

const EN_TEXT = "EN_TEXT";
const RU_TEXT = "RU_TEXT";
const TR_TEXT = "TR_TEXT";

const BlogForm = ({ details }) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        setValue,
        watch,
    } = useForm({
        mode: "all",
        defaultValues: {
            ...details,
            category: details?.category?._id,
            text: details?.text,
            title: details?.title,
        },
        resolver: yupResolver(blogSchema),
    });

    const [titleActiveTab, setTitleActiveTab] = useState(EN_TITLE);
    const [textActiveTab, setTextActiveTab] = useState(EN_TEXT);

    const { value: language } = getLanguage();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { _id } = details;

    const { items } = useSelector(allCategorySelector);

    useEffect(() => {
        register("title.en");
        register("text.en");
        register("title.ru");
        register("text.ru");
        register("title.tr");
        register("text.tr");
    }, [register]);

    const titleContentEn = watch("title.en");
    const textContentEn = watch("text.en");
    const titleContentRu = watch("title.ru");
    const textContentRu = watch("text.ru");
    const titleContentTr = watch("title.tr");
    const textContentTr = watch("text.tr");

    const handleCloseModal = () => dispatch(closeModalActionCreator());
    const onSubmit = (data) => {
        const newData = {
            ...data,
            title: {
                en: titleContentEn,
                ru: titleContentRu,
                tr: titleContentTr,
            },
            text: {
                en: textContentEn,
                ru: textContentRu,
                tr: textContentTr,
            },
        };

        if (_id) {
            dispatch(putBlog(newData, _id));
        } else {
            dispatch(postBlog(newData));
        }
    };

    return (
        <form className="blog_form" onSubmit={handleSubmit(onSubmit)}>
            <div className="blog_form__content">
                <h2 className="blog_form__title">
                    {t(`blog.${_id ? "edit" : "add"}`)}
                </h2>
                <div className="blog_form__items">
                    <div className="blog__item">
                        <Controller
                            name="image"
                            control={control}
                            render={({ field }) => (
                                <PhotoUploader
                                    {...field}
                                    defaultValue={details?.image}
                                    error={errors?.image?.message}
                                    onChange={(image) => {
                                        field.onChange(image);
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="blog__item">
                        <label className="blog__item_label">
                            {t("naming")}
                        </label>
                        <div className="blog__item_tab-wrapper">
                            <div
                                className={`blog__item_tab-wrapper-btn ${
                                    titleActiveTab === EN_TITLE &&
                                    "blog__item_tab-wrapper-btn-active"
                                }`}
                                onClick={() => setTitleActiveTab(EN_TITLE)}
                            >
                                EN
                            </div>
                            <div
                                className={`blog__item_tab-wrapper-btn ${
                                    titleActiveTab === RU_TITLE &&
                                    "blog__item_tab-wrapper-btn-active"
                                }`}
                                onClick={() => setTitleActiveTab(RU_TITLE)}
                            >
                                RU
                            </div>
                            <div
                                className={`blog__item_tab-wrapper-btn ${
                                    titleActiveTab === TR_TITLE &&
                                    "blog__item_tab-wrapper-btn-active"
                                }`}
                                onClick={() => setTitleActiveTab(TR_TITLE)}
                            >
                                TR
                            </div>
                        </div>
                        <div
                            className={`blog__item_text-editor ${
                                titleActiveTab === EN_TITLE &&
                                "blog__item_text-editor-visible"
                            }`}
                        >
                            <ReactQuill
                                theme="snow"
                                value={titleContentEn}
                                onChange={(editorState) =>
                                    setValue("title.en", editorState)
                                }
                                formats={textEditorFormats}
                                modules={textEditorModules}
                            />
                            {errors?.title?.en && (
                                <p className="blog__item_translation-error">
                                    {t(errors?.title?.en.message)}
                                </p>
                            )}
                        </div>
                        <div
                            className={`blog__item_text-editor ${
                                titleActiveTab === RU_TITLE &&
                                "blog__item_text-editor-visible"
                            }`}
                        >
                            <ReactQuill
                                theme="snow"
                                value={titleContentRu}
                                onChange={(editorState) =>
                                    setValue("title.ru", editorState)
                                }
                                formats={textEditorFormats}
                                modules={textEditorModules}
                            />
                            {errors?.title?.ru && (
                                <p className="blog__item_translation-error">
                                    {t(errors?.title?.ru.message)}
                                </p>
                            )}
                        </div>
                        <div
                            className={`blog__item_text-editor ${
                                titleActiveTab === TR_TITLE &&
                                "blog__item_text-editor-visible"
                            }`}
                        >
                            <ReactQuill
                                theme="snow"
                                value={titleContentTr}
                                onChange={(editorState) =>
                                    setValue("title.tr", editorState)
                                }
                                formats={textEditorFormats}
                                modules={textEditorModules}
                            />
                            {errors?.title?.tr && (
                                <p className="blog__item_translation-error">
                                    {t(errors?.title?.tr.message)}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="blog__item">
                        <label className="blog__item_label">
                            {t("description")}
                        </label>
                        <div className="blog__item_tab-wrapper">
                            <div
                                className={`blog__item_tab-wrapper-btn ${
                                    textActiveTab === EN_TEXT &&
                                    "blog__item_tab-wrapper-btn-active"
                                }`}
                                onClick={() => setTextActiveTab(EN_TEXT)}
                            >
                                EN
                            </div>
                            <div
                                className={`blog__item_tab-wrapper-btn ${
                                    textActiveTab === RU_TEXT &&
                                    "blog__item_tab-wrapper-btn-active"
                                }`}
                                onClick={() => setTextActiveTab(RU_TEXT)}
                            >
                                RU
                            </div>
                            <div
                                className={`blog__item_tab-wrapper-btn ${
                                    textActiveTab === TR_TEXT &&
                                    "blog__item_tab-wrapper-btn-active"
                                }`}
                                onClick={() => setTextActiveTab(TR_TEXT)}
                            >
                                TR
                            </div>
                        </div>
                        <div
                            className={`blog__item_text-editor ${
                                textActiveTab === EN_TEXT &&
                                "blog__item_text-editor-visible"
                            }`}
                        >
                            <ReactQuill
                                theme="snow"
                                value={textContentEn}
                                onChange={(editorState) =>
                                    setValue("text.en", editorState)
                                }
                                formats={textEditorFormats}
                                modules={textEditorModules}
                            />
                            {errors?.text?.en && (
                                <p className="blog__item_translation-error">
                                    {t(errors?.text?.en.message)}
                                </p>
                            )}
                        </div>
                        <div
                            className={`blog__item_text-editor ${
                                textActiveTab === RU_TEXT &&
                                "blog__item_text-editor-visible"
                            }`}
                        >
                            <ReactQuill
                                theme="snow"
                                value={textContentRu}
                                onChange={(editorState) =>
                                    setValue("text.ru", editorState)
                                }
                                formats={textEditorFormats}
                                modules={textEditorModules}
                            />
                            {errors?.text?.ru && (
                                <p className="blog__item_translation-error">
                                    {t(errors?.text?.ru.message)}
                                </p>
                            )}
                        </div>
                        <div
                            className={`blog__item_text-editor ${
                                textActiveTab === TR_TEXT &&
                                "blog__item_text-editor-visible"
                            }`}
                        >
                            <ReactQuill
                                theme="snow"
                                value={textContentTr}
                                onChange={(editorState) =>
                                    setValue("text.tr", editorState)
                                }
                                formats={textEditorFormats}
                                modules={textEditorModules}
                            />
                            {errors?.text?.tr && (
                                <p className="blog__item_translation-error">
                                    {t(errors?.text?.tr.message)}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="blog__item">
                        <label className="blog__item_label">
                            {t("category")}
                        </label>
                        <select
                            className={"blog__item_select"}
                            {...register("category")}
                        >
                            {items.map(({ _id, titleTranslations }) => (
                                <option key={_id} value={_id}>
                                    {titleTranslations[language]}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="blog__service">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button type="submit" buttonStyle={"btn--green"}>
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default BlogForm;
