import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import Select from "react-select";
import Button from "../../../shared/Button/Button";

import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import {
    getAllCategoryWithoutLimit,
    postSubcategory,
    putSubcategory,
} from "../../../redux/operations/categoryOperations";
import {
    allCategoryWithoutLimitSelector,
    categorySelector,
} from "../../../redux/selectors/categorySelectors";
import { allTagsSelector } from "../../../redux/selectors/tagsSelectors";

import { subcategorySchema } from "./SubcategoryForm.Schema";
import { useEffect } from "react";
import "./index.scss";

const SubcategoryForm = ({ details }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: { ...details },
        mode: "all",
        resolver: yupResolver(subcategorySchema),
    });

    const { loading } = useSelector(categorySelector);
    const { items: tagsList } = useSelector(allTagsSelector);
    const { items: categories } = useSelector(allCategoryWithoutLimitSelector);
    const { _id, tags } = details;

    const handleCloseModal = () => dispatch(closeModalActionCreator());

    useEffect(() => dispatch(getAllCategoryWithoutLimit()), []);

    const onSubmit = (data) => {
        if (_id) {
            dispatch(putSubcategory(data, _id));
        } else {
            dispatch(postSubcategory(data));
        }
    };
    const customStyles = {
        input: (styles) => ({
            ...styles,
            height: 35,
        }),
        singleValue: (styles) => ({
            textAlign: "left",
            ...styles,
        }),
        container: (styles) => ({
            ...styles,
            borderRadius: 6,
            width: 282,
            border: "0.1px solid black",
        }),
        placeholder: (styles) => ({
            ...styles,
            textAlign: "left",
        }),
    };
    return (
        <form className="subcategory__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="subcategory__form__content">
                <h2 className="subcategory__form__title">
                    {t(`services.subcategory.${_id ? "edit" : "add"}`)}
                </h2>
                <div className="subcategory__form__items">
                    <div className="subcategory__item">
                        <label className="subcategory__item_label">
                            {t("naming")}
                        </label>
                        <input
                            className={`subcategory__item_input ${
                                errors?.remark && "error"
                            }`}
                            {...register("remark")}
                        />
                        {errors.remark && (
                            <span className="subcategory__item-error">
                                {t(errors.remark?.message)}
                            </span>
                        )}
                    </div>
                    <div className="subcategory__item">
                        <label className="subcategory__item_label">
                            {t("translations")}
                        </label>
                        <div className="subcategory__item_translation">
                            <div className="subcategory__item_translation_text">
                                EN
                            </div>
                            <input
                                className={`subcategory__item_input ${
                                    errors?.titleTranslations?.en && "error"
                                }`}
                                {...register("titleTranslations.en")}
                            />
                        </div>
                        {errors?.titleTranslations?.en && (
                            <p className="subcategory__item_translation-error">
                                {t(errors?.titleTranslations?.en.message)}
                            </p>
                        )}
                    </div>
                    <div className="subcategory__item">
                        <div className="subcategory__item_translation">
                            <div className="subcategory__item_translation_text">
                                RU
                            </div>
                            <input
                                className={`subcategory__item_input ${
                                    errors?.titleTranslations?.ru && "error"
                                }`}
                                {...register("titleTranslations.ru")}
                            />
                        </div>
                        {errors?.titleTranslations?.ru && (
                            <p className="subcategory__item_translation-error">
                                {t(errors?.titleTranslations?.ru.message)}
                            </p>
                        )}
                    </div>
                    <div className="subcategory__item">
                        <div className="subcategory__item_translation">
                            <div className="subcategory__item_translation_text">
                                TR
                            </div>
                            <input
                                className={`subcategory__item_input ${
                                    errors?.titleTranslations?.tr && "error"
                                }`}
                                {...register("titleTranslations.tr")}
                            />
                        </div>
                        {errors?.titleTranslations?.tr && (
                            <p className="subcategory__item_translation-error">
                                {t(errors?.titleTranslations?.tr.message)}
                            </p>
                        )}
                    </div>
                    <div className="subcategory__item">
                        <label className="subcategory__item_label">
                            {t("category")}
                        </label>
                        <Controller
                            name={"category"}
                            control={control}
                            render={({ field: { onChange, ref, value } }) => (
                                <Select
                                    inputRef={ref}
                                    maxMenuHeight={150}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    styles={customStyles}
                                    options={categories}
                                    onChange={({ _id }) => onChange(_id)}
                                    getOptionValue={(option) => option._id}
                                    getOptionLabel={(option) => option.remark}
                                    value={categories?.find((c) => {
                                        return c._id === value;
                                    })}
                                />
                            )}
                        />

                        {errors.caregory && (
                            <span className="subcategory__item-error">
                                {t(errors.caregory.message)}
                            </span>
                        )}
                    </div>
                    <div className="subcategory__item">
                        <label className="subcategory__item_label">
                            {t("tags")}
                        </label>
                        <Controller
                            control={control}
                            render={({ field: { onChange, name, ref } }) => (
                                <Select
                                    isMulti
                                    inputRef={ref}
                                    maxMenuHeight={150}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    styles={customStyles}
                                    defaultValue={
                                        tags?.length > 0 ? [...tags] : []
                                    }
                                    options={tagsList}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option._id}
                                    onChange={(selectedOption) => {
                                        onChange(selectedOption);
                                    }}
                                    value={tagsList.find(
                                        (c) => c.name === name
                                    )}
                                />
                            )}
                            name={"tags"}
                        />
                        {errors.tags && (
                            <span className="subcategory__item-select-error">
                                {t(errors.tags.message)}
                            </span>
                        )}
                    </div>
                    <div className="subcategory__options">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button
                            type="submit"
                            buttonStyle={"btn--green"}
                            loading={loading}
                        >
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SubcategoryForm;
