const promotionsHistoryTitle = [
    "identifier",
    "promotion-type",
    "activation-date",
    "expiration-date",
    "status",
];
const sizeHistoryItemsOnPage = 10;

export { promotionsHistoryTitle, sizeHistoryItemsOnPage };
