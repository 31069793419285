import { useState } from "react";

const FlagsCheckbox = () => {
    const [checked, setChecked] = useState(true);

    return (
        <td className="flags__content_item_text">
            <input
                onClick={() => setChecked(!checked)}
                defaultChecked={checked}
                className="flags__content_item_checkbox"
                type={"checkbox"}
            />
        </td>
    );
};

export default FlagsCheckbox;
