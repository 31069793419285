import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { memo, useEffect } from "react";

import {
    allCategoryParamsSelector,
    allCategorySelector,
} from "../../../redux/selectors/categorySelectors";
import { getAllCategory } from "../../../redux/operations/categoryOperations";
import { openModalActionCreator } from "../../../redux/actions/modalActions";
import {
    deleteCategory,
    editPageCategory,
} from "../../../redux/operations/categoryOperations";

import { servicesTitle, sizeCategoriesOnPage } from "./category.config";
import CategoryItem from "./CategoryItem/CategoryItem";
import Pagination from "../../Pagination/Pagination";

import "./index.scss";

const Category = () => {
    const { page } = useSelector(allCategoryParamsSelector);
    const categories = useSelector(allCategorySelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => dispatch(getAllCategory(page)), []);

    const handleOnAdd = () => {
        dispatch(
            openModalActionCreator({ typeModal: "ADD_SERVICE", details: {} })
        );
    };

    const handleOnEdit = (service) => {
        dispatch(
            openModalActionCreator({
                typeModal: "EDIT_SERVICE",
                details: service,
            })
        );
    };

    const handleOnDelete = (id) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    id,
                    title: "service",
                    description: t("services.remove-category"),
                    action: () => dispatch(deleteCategory(id)),
                },
            })
        );
    };

    return (
        <div>
            <table className="category">
                <thead className="category__header">
                    <tr className="category__header_item">
                        {servicesTitle.map((title, index) => (
                            <th className="category__title" key={index + title}>
                                {t(title)}
                            </th>
                        ))}
                        <th className="category__title">
                            <button
                                className="category__btn"
                                onClick={handleOnAdd}
                            >
                                {t("services.category.add")}
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody className="category__body">
                    {categories.items.map((service) => (
                        <CategoryItem
                            onEdit={() => handleOnEdit(service)}
                            onDelete={() => handleOnDelete(service._id)}
                            key={service._id}
                            {...service}
                        />
                    ))}
                </tbody>
            </table>
            <Pagination
                getNextPageItems={(e) => dispatch(editPageCategory(e))}
                sizeItemOnPage={sizeCategoriesOnPage}
                isStartedPage={page}
                items={categories}
                elementType="page"
            />
        </div>
    );
};

export default memo(Category);
