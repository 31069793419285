export const scheduleWork = [
    {
        day: "MONDAY",
        isWeekend: true,
        workingHours: [
            {
                timeFrom: "8:00",
                timeTo: "17:00",
            },
        ],
    },
    {
        day: "TUESDAY",
        isWeekend: true,
        workingHours: [
            {
                timeFrom: "8:00",
                timeTo: "17:00",
            },
        ],
    },
    {
        day: "WEDNESDAY",
        isWeekend: true,
        workingHours: [
            {
                timeFrom: "8:00",
                timeTo: "17:00",
            },
        ],
    },
    {
        day: "THURSDAY",
        isWeekend: true,
        workingHours: [
            {
                timeFrom: "8:00",
                timeTo: "17:00",
            },
        ],
    },
    {
        day: "FRIDAY",
        isWeekend: true,
        workingHours: [
            {
                timeFrom: "8:00",
                timeTo: "17:00",
            },
        ],
    },
    {
        day: "SATURDAY",
        isWeekend: true,
        workingHours: [
            {
                timeFrom: "8:00",
                timeTo: "17:00",
            },
        ],
    },
    {
        day: "SUNDAY",
        isWeekend: true,
        workingHours: [
            {
                timeFrom: "8:00",
                timeTo: "17:00",
            },
        ],
    },
];

export const scheduleTime = [
    "1:00",
    "2:00",
    "3:00",
    "4:00",
    "5:00",
    "6:00",
    "7:00",
    "8:00",
    "9:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
];
