import { createSelector } from "reselect";

export const promotionSelector = (state) => state.promotion;

const paramsSelector = (state) => state.promotion.params;
export const promotionParamsSelector = createSelector(
    paramsSelector,
    (promotion) => promotion.promotionParams
);

const historyParamsSelector = (state) => state.promotion.historyParams;
export const promotionHistoryParamsSelector = createSelector(
    historyParamsSelector,
    (promotion) => promotion.historyPromotionParams
);
