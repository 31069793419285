import * as promotionTypes from "../types/promotionTypes";
import { sizeItemOnPage } from "../../components/Tables/Promotion/Promotion.config";
import { sizeHistoryItemsOnPage } from "../../components/Tables/PromotionsHistory/promotions-history.config";

const initialParams = {
    promotionParams: { limit: sizeItemOnPage, page: "&page=0" },
};

const historyInitialParams = {
    historyPromotionParams: { limit: sizeHistoryItemsOnPage, page: "&page=0" },
};

const initialState = {
    allPromotion: { items: [], totalCount: 0 },
    promotion: {},
    boughtPromotions: { items: [], totalCount: 0 },
    params: initialParams,
    historyParams: historyInitialParams,
    loading: false,
    error: "",
};

export const promotionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case promotionTypes.GET_PROMOTION_BY_ID_PENDING:
        case promotionTypes.DELETE_PROMOTION_REQUEST:
        case promotionTypes.POST_PROMOTION_REQUEST:
        case promotionTypes.GET_PROMOTION_REQUEST:
        case promotionTypes.PUT_PROMOTION_REQUEST:
        case promotionTypes.GET_LIST_OF_BOUGHT_PROMOTIONS_REQUEST: {
            return { ...state, loading: true };
        }

        // data
        case promotionTypes.DELETE_PROMOTION_SUCCESS:
        case promotionTypes.POST_PROMOTION_SUCCESS:
        case promotionTypes.GET_PROMOTION_SUCCESS:
        case promotionTypes.PUT_PROMOTION_SUCCESS: {
            return {
                ...state,
                allPromotion: payload,
                loading: false,
                error: "",
            };
        }
        //get promotion by id
        case promotionTypes.GET_PROMOTION_BY_ID_FULFILLED:
            return { ...state, promotion: payload, loading: false };

        // bought promotions
        case promotionTypes.GET_LIST_OF_BOUGHT_PROMOTIONS_SUCCESS:
            return { ...state, boughtPromotions: payload, loading: false };

        // error
        case promotionTypes.GET_PROMOTION_BY_ID_REJECTED:
        case promotionTypes.DELETE_PROMOTION_ERROR:
        case promotionTypes.POST_PROMOTION_ERROR:
        case promotionTypes.GET_PROMOTION_ERROR:
        case promotionTypes.PUT_PROMOTION_ERROR:
        case promotionTypes.GET_LIST_OF_BOUGHT_PROMOTIONS_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        // set
        case promotionTypes.SET_PAGE:
            return {
                ...state,
                params: {
                    ...state.params,
                    promotionParams: {
                        ...state.params.promotionParams,
                        page: payload,
                    },
                },
            };
        case promotionTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialParams };

        // set history
        case promotionTypes.SET_HISTORY_PAGE:
            return {
                ...state,
                historyParams: {
                    ...state.historyParams,
                    historyPromotionParams: {
                        ...state.historyParams.historyPromotionParams,
                        page: payload,
                    },
                },
            };

        case promotionTypes.PUT_BOUGHT_PROMOTIONS_ITEM_SUCCESS:
            const newItems = state.boughtPromotions.items.map((item) => {
                if (item._id === payload) {
                    return {
                        ...item,
                        active: false,
                    };
                }
                return item;
            });

            return {
                ...state,
                boughtPromotions: {
                    ...state.boughtPromotions,
                    items: newItems,
                },
            };

        case promotionTypes.CLEAR_ALL_HISTORY_PARAMS:
            return { ...state, historyParams: historyInitialParams };

        default:
            return state;
    }
};
