import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { memo, useEffect } from "react";

import { openModalActionCreator } from "../../../redux/actions/modalActions";
import {
    allCategorySelector,
    allSubcategoryParamsSelector,
    allSubcategorySelector,
} from "../../../redux/selectors/categorySelectors";
import {
    deleteSubcategory,
    editPageSubcategory,
    getAllSubcategory,
} from "../../../redux/operations/categoryOperations";

import {
    sizeSubcategoriesOnPage,
    subcategoriesTitle,
} from "./subcategories.config";
import Pagination from "../../Pagination/Pagination";
import Subcategory from "./Subcategory/Subcategory";

import "./Subcategories.scss";

const Subcategories = () => {
    const { items: categories } = useSelector(allCategorySelector);
    const { page } = useSelector(allSubcategoryParamsSelector);
    const subcategories = useSelector(allSubcategorySelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => dispatch(getAllSubcategory(page)), []);

    const handleOnAdd = () => {
        dispatch(
            openModalActionCreator({
                typeModal: "ADD_SUBCATEGORY",
                details: {},
            })
        );
    };

    const handleOnEdit = (subcategory) => {
        dispatch(
            openModalActionCreator({
                typeModal: "EDIT_SUBCATEGORY",
                details: subcategory,
            })
        );
    };

    const handleOnDelete = (id) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    id,
                    title: "subcategory",
                    description: t("services.remove-subcategory"),
                    action: () => dispatch(deleteSubcategory(id)),
                },
            })
        );
    };

    return (
        categories.length > 0 && (
            <div className="subcategories-conatiner">
                <h1 className="subcategories-conatiner__title">
                    {t("subcategory")}
                </h1>
                <table className="subcategories">
                    <thead className="subcategories__header">
                        <tr className="subcategories__header_item">
                            {subcategoriesTitle.map((title, index) => (
                                <th
                                    className="subcategories__title"
                                    key={`${title} + ${index}`}
                                >
                                    {t(title)}
                                </th>
                            ))}
                            <th className="subcategories__title">
                                <button
                                    className="subcategories__btn"
                                    onClick={handleOnAdd}
                                >
                                    {t("services.subcategory.add")}
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="subcategories__body">
                        {subcategories.items.map((subcategory) => (
                            <Subcategory
                                onDelete={() => handleOnDelete(subcategory._id)}
                                onEdit={() => handleOnEdit(subcategory)}
                                key={subcategory._id}
                                {...subcategory}
                            />
                        ))}
                    </tbody>
                </table>
                <Pagination
                    getNextPageItems={(e) => dispatch(editPageSubcategory(e))}
                    sizeItemOnPage={sizeSubcategoriesOnPage}
                    items={subcategories}
                    isStartedPage={page}
                    elementType="page"
                />
            </div>
        )
    );
};

export default memo(Subcategories);
