export const selectedAdditionals = [
    {
        icon: "chatService",
        selected: "service",
    },
    { icon: "attachFile", selected: "file" },
    {
        icon: "chooseVideo",
        selected: "video",
    },
];

export const servicesData = {
    icon: "chatClose",
    title: "support.modal.deactivate",
};
