import { post, get, patch, put } from "../api";

import { adminRoles } from "../../configs/accountRoles";

const baseUrl = "/admin";

// get
export const getAdminListRequest = async (params = "") => {
    const url = `${baseUrl}/list` + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};

// post
export const postAdminRequest = async (reqData = {}) => {
    const url = `${baseUrl}/new-user`;

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};

export const putAdminRequest = async (id, reqData = {}) => {
    const url = `/admin/profile/${id}`;

    try {
        const { data } = await put(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

// patch admin
export const patchAdminRequest = async (reqData = {}) => {
    const url = `${baseUrl}`;

    try {
        const { data } = await patch(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};

// patch permissions
export const patchPermissionsRequest = async (id, role, reqData = {}) => {
    const url = `${baseUrl}/${adminRoles[role].requstName}/${id}/permissions`;

    try {
        const { data } = await patch(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};
