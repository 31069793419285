import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import React from "react";

import { closeModalActionCreator } from "../../../redux/actions/modalActions";

import "./DeleteForm.scss";
import Button from "../../../shared/Button/Button";

const DeleteForm = ({
    details: { title, action, type = "delete", description = "" },
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleCloseModal = () => dispatch(closeModalActionCreator());

    return (
        <div className="delete-form">
            <div className="delete-form__title">
                {type === "deactivate"
                    ? t("want-to-deactivate")
                    : t("want-to-deactivate")}{" "}
                <span className="delete-form__title-name">{title}</span>?
            </div>
            {description && <p className="delete-form__title">{description}</p>}
            <div className="delete-form__btns">
                <Button onClick={handleCloseModal} buttonStyle={"btn--red"}>
                    {t("btn-cancel")}
                </Button>
                <Button onClick={action} buttonStyle={"btn--green"}>
                    {type === "deactivate"
                        ? t("btn-deactivate")
                        : t("btn-delete")}
                </Button>
            </div>
        </div>
    );
};

export default DeleteForm;
