import * as requests from "../../services/requests/blog";

import { closeModalActionCreator } from "../actions/modalActions";
import { setToastifyStatus } from "../actions/toastifyActions";
import { blogListSelector } from "../selectors/blogSelectors";
import * as blogActions from "../actions/blogActions";

import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";
import { getFormData } from "../../services/utils/getFormData";
import { sizeBlogItemsOnPage } from "../../components/Tables/Blog/blog.config";

// get blog
export const getBlogList =
    (params = "") =>
    (dispatch) => {
        dispatch(blogActions.getBlogListRequest());

        return requests
            .getBlogListRequest(`?limit=${sizeBlogItemsOnPage}` + params)
            .then(({ items, totalCount: count }) => {
                dispatch(blogActions.getBlogListSuccess({ items, count }));
            })
            .catch(({ error }) => {
                dispatch(blogActions.getBlogListError(error));
            });
    };

// post blog
export const postBlog = (data) => (dispatch, getState) => {
    dispatch(blogActions.createBlogRequest());
    const { items } = blogListSelector(getState());

    return requests
        .postBlogRequest(getFormData(data))
        .then((item) => {
            const newItems = [...items, item];
            dispatch(blogActions.createBlogSuccess({ items: newItems }));
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.POST_BLOG,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(blogActions.createBlogError(error));
        });
};

// put blog
export const putBlog = (data, id) => (dispatch, getState) => {
    dispatch(blogActions.updateBlogRequest());
    const { items } = blogListSelector(getState());

    const updateData = {
        category: data.category,
        image: data.image,
        text: data.text,
        title: data.title,
    };

    return requests
        .putBlogRequest(getFormData(updateData), id)
        .then(() => {
            const newItems = items.map((body) =>
                body._id === id ? data : body
            );
            dispatch(blogActions.updateBlogSuccess({ items: newItems }));

            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PUT_BLOG,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(blogActions.updateBlogError(error));
        });
};

// delete blog
export const deleteBlog = (id) => (dispatch, getState) => {
    dispatch(blogActions.deleteBlogRequest());
    const { items } = blogListSelector(getState());

    return requests
        .deleteBlogRequest(id)
        .then(() => {
            const newItems = items.filter(({ _id }) => _id !== id);
            dispatch(blogActions.deleteBlogSuccess({ items: newItems }));
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_BLOG,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(blogActions.deleteBlogError(error));
        });
};

// set
export const editPageBlog = (data) => (dispatch) => {
    dispatch(blogActions.setBlogPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getBlogList(url));
};
