import PropTypes from "prop-types";
import Lottie from "react-lottie";
import React from "react";

const LottieAnimation = ({ lotti, width, height }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: lotti,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <div>
            <Lottie options={defaultOptions} height={height} width={width} />
        </div>
    );
};

LottieAnimation.propTypes = {
    lotti: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default LottieAnimation;
