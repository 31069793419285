import * as yup from "yup";
import { t } from "i18next";

export const SpecialistFeedbackEditSchema = yup.object().shape({
    text: yup
        .string()
        .trim()
        .required("required")
        .min(10, ({ min }) => t("validation-min", { min })),
    assessment: yup.string().trim().required("required"),
});
