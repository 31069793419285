import * as faqTypes from "../types/faqTypes";


export const getFAQRequest = () => ({
    type: faqTypes.GET_FAQ_REQUEST,
});

export const getFAQSuccess = (payload) => ({
    type: faqTypes.GET_FAQ_SUCCESS,
    payload,
});

export const getFAQError = (payload) => ({
    type: faqTypes.GET_FAQ_ERROR,
    payload,
});



export const postFAQRequest = () => ({
    type: faqTypes.POST_FAQ_REQUEST,
});

export const postFAQSuccess = (payload) => ({
    type: faqTypes.POST_FAQ_SUCCESS,
    payload,
});

export const postFAQError = (payload) => ({
    type: faqTypes.POST_FAQ_ERROR,
    payload,
});


export const putFAQRequest = () => ({
    type: faqTypes.PUT_FAQ_REQUEST,
});

export const putFAQSuccess = (payload) => ({
    type: faqTypes.PUT_FAQ_SUCCESS,
    payload,
});

export const putFAQError = (payload) => ({
    type: faqTypes.PUT_FAQ_ERROR,
    payload,
});


export const deleteFAQRequest = () => ({
    type: faqTypes.DELETE_FAQ_REQUEST,
});

export const deleteFAQSuccess = (payload) => ({
    type: faqTypes.DELETE_FAQ_SUCCESS,
    payload,
});

export const deleteFAQError = (payload) => ({
    type: faqTypes.DELETE_FAQ_ERROR,
    payload,
});


export const setPage = (data) => ({
    type: faqTypes.SET_PAGE,
    payload: data,
});
