export const normalizePutUserData = (user) => ({
    city: user?.city,
    district: user?.district,
    firstName: user?.firstName,
    lastName: user?.lastName,
    password: user?.password,
    popular: user?.popular,
    phone: {
        number: user?.phone?.dialCode + user?.phone?.number,
        isoCode: user?.phone?.isoCode,
    },
});

export const normalizePostUserData = (user) => ({
    role: user?.role,
    email: user?.email,
    city: user?.city,
    district: user?.district,
    firstName: user?.firstName,
    lastName: user?.lastName,
    password: user?.password,
    phone: user?.phone?.dialCode + user?.phone?.number,
});
