import { get, put, destroy } from "../api";

const baseUrl = "/admin/feedback";

export const getFeedbacksRequest = async (params) => {
    const url = baseUrl + params;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const putFeedbackRequest = async (id, reqData = {}) => {
    const url = `${baseUrl}/${id}`;

    try {
        const { data } = await put(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const deleteFeedbackRequest = async (id, reporter) => {
    const url = `${baseUrl}/${id}/reporter/${reporter}`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};
