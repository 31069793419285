import { sizeItemOnPage } from "../../pages/AccountsDetails/SpecialistFeedbacks/SpecialistFeedbacks.config";
import * as feedbackTypes from "../types/feedbackTypes";

const initialState = {
    params: { limit: sizeItemOnPage, page: "&page=0" },
    allFeedback: { items: [], count: 0 },
    loading: false,
    error: "",
};

export const feedbackReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case feedbackTypes.GET_ALL_FEEDBACK_REQUEST:
        case feedbackTypes.DELETE_FEEDBACK_REQUEST:
        case feedbackTypes.PUT_FEEDBACK_REQUEST: {
            return { ...state, loading: true };
        }

        // data all feedback
        case feedbackTypes.GET_ALL_FEEDBACK_SUCCESS:
        case feedbackTypes.DELETE_FEEDBACK_SUCCESS:
        case feedbackTypes.PUT_FEEDBACK_SUCCESS: {
            return {
                ...state,
                allFeedback: payload,
                loading: false,
                error: "",
            };
        }

        // error
        case feedbackTypes.GET_ALL_FEEDBACK_ERROR:
        case feedbackTypes.DELETE_FEEDBACK_ERROR:
        case feedbackTypes.PUT_FEEDBACK_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        //set
        case feedbackTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };
        case feedbackTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialState.params };

        default:
            return state;
    }
};
