export const newUserFormConfig = [
    {
        label: "name",
        placeholder: "name-placeholder",
        value: "firstName",
    },
    {
        label: "surname",
        placeholder: "surname-placeholder",
        value: "lastName",
    },
    {
        label: "phone",
        placeholder: "phone-placeholder",
        value: "phone",
    },
    {
        label: "password",
        placeholder: "password-placeholder",
        value: "password",
    },
    {
        label: "address",
        placeholder: "address-placeholder",
        value: "address",
    },
    {
        label: "email",
        placeholder: "email-placeholder",
        value: "email",
    },
];
