import { loaderTypes } from "./loader.types";

export const changeGlobalLoader = (status) => ({
    type: loaderTypes.CHANGE_GLOBAL_LOADER,
    payload: status,
});

export const changeButtonLoader = (status) => ({
    type: loaderTypes.CHANGE_BUTTON_LOADER,
    payload: status,
});
