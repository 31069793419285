import { t } from "i18next";
import { object, string } from "yup";

const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const NewUserFormSchema = object().shape({
    firstName: string()
        .trim(true)
        .required("required")
        .min(3, ({ min }) => t("validation-min", { min }))
        .max(20, ({ max }) => t("validation-max", { max })),
    lastName: string()
        .trim(true)
        .required("required")
        .min(3, ({ min }) => t("validation-min", { min }))
        .max(20, ({ max }) => t("validation-max", { max })),

    city: string().required("required"),
    district: string().required("required"),
    address: string().required("required"),
    email: string().required("required").email("wrong-email"),
    role: string().required("required"),
    password: string()
        .trim()
        .nullable(true)
        .transform((value) => (value === "" ? null : value))
        .min(6, ({ min }) => t("validation-min", { min }))
        .max(20, ({ max }) => t("validation-max", { max })),
    phone: string()
        .trim(true)
        .required("required")
        .matches(phoneRegExp, "wrong-phone")
        .min(7, ({ min }) => t("validation-min", { min }))
        .max(13, ({ max }) => t("validation-max", { max })),
});
