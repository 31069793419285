import { useSelector } from "react-redux";

import { getModal } from "../../../redux/selectors/modalSelectors";

import SpecialistServiceForm from "../../Forms/SpecialistServiceForm/SpecialistServiceForm";
import ClarificationForm from "../../Forms/ClarificationForm/ClarificationForm";
import ContentWriterForm from "../../Forms/ContentWriterForm/ContentWriterForm";
import SubcategoryForm from "../../Forms/SubcategoryForm/SubcategoryForm";
import SpecialistForm from "../../Forms/SpecialistForm/SpecialistForm";
import SpecialistFeedbackEdit from "../../../pages/AccountsDetails/SpecialistFeedbacks/SpecialistFeedbackEdit/SpecialistFeedbackEdit";
import ModeratorForm from "../../Forms/ModeratorForm/ModeratorForm";
import PromotionForm from "../../Forms/PromotionForm/PromotionForm";
import ComplaintForm from "../../Forms/ComplaintForm/ComplaintForm";
import CustomerForm from "../../Forms/CustomerForm/CustomerForm";
import DocumentForm from "../../Forms/DocumentForm/DocumentForm";
import CategoryForm from "../../Forms/CategoryForm/CategoryForm";
import DeleteForm from "../../Forms/DeleteForm/DeleteForm";
import AdminForm from "../../Forms/AdminForm/AdminForm";
import TagForm from "../../Forms/TagForm/TagForm";
import BlogForm from "../../Forms/BlogForm/BlogForm";
import NewUserForm from "../../Forms/NewUserForm/NewUserForm";
import SupportChatCarousel from "../../../pages/SupportChat/SupportChatCarousel/SupportChatCarousel";
import FAQForm from "../../Forms/FAQForm/FAQForm";
import RestoreForm from "../../Forms/RestoreForm/RestoreForm";

const RenderModalContent = () => {
    const { typeModal, details } = useSelector(getModal);

    switch (typeModal) {
        case "ADD_TAG":
        case "EDIT_TAG":
            return <TagForm details={details} />;
        case "EDIT_CUSTOMER":
            return <CustomerForm details={details} />;

        case "EDIT_SPECIALIST":
            return <SpecialistForm details={details} />;

        case "EDIT_SERVICE":
        case "ADD_SERVICE":
            return <CategoryForm details={details} />;

        case "RESTORE_SERVICE":
            return <RestoreForm details={details} />;

        case "EDIT_SUBCATEGORY":
        case "ADD_SUBCATEGORY":
            return <SubcategoryForm details={details} />;

        case "EDIT_CLARIFICATION":
        case "ADD_CLARIFICATION":
            return <ClarificationForm details={details} />;

        case "EDIT_SPECIALIST_SERVICE":
            return <SpecialistServiceForm details={details} />;

        case "SHOW_PERSON":
            return <DocumentForm />;

        case "DELETE":
            return <DeleteForm details={details} />;

        case "EDIT_FEEDBACK":
            return <SpecialistFeedbackEdit details={details} />;

        case "EDIT_PROMOTION":
        case "ADD_PROMOTION":
            return <PromotionForm details={details} />;

        case "ADD_ADMIN":
        case "EDIT_ADMIN":
            return <AdminForm details={details} />;

        case "EDIT_MODERATOR":
            return <ModeratorForm details={details} />;

        case "EDIT_CONTENT_WRITER":
            return <ContentWriterForm details={details} />;

        case "EDIT_BLOG":
        case "ADD_BLOG":
            return <BlogForm details={details} />;

        case "EDIT_COMPLAINT_TYPE":
        case "ADD_COMPLAINT_TYPE":
            return <ComplaintForm details={details} />;

        case "ADD_NEW_USER":
            return <NewUserForm />;

        case "CHAT_CAROUSEL":
            return <SupportChatCarousel details={details} />;
        case "ADD_FAQ":
        case "EDIT_FAQ":
            return <FAQForm details={details} />;

        default:
            return <h1>DEFAULT CASE</h1>;
    }
};

export default RenderModalContent;
