import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";

import {
    allFeedbackSelector,
    allFeedbackParamsSelector,
} from "../../../redux/selectors/feedbackSelectors";
import {
    getAllFeedback,
    editPageAll,
} from "../../../redux/operations/feedbackOperations";

import {
    sizeItemOnPage,
    specialistFeedbacksItemTtitle,
} from "./SpecialistFeedbacks.config";
import Pagination from "../../../components/Pagination/Pagination";
import SpecialistFeedbacksItem from "./SpecialistFeedbacksItem/SpecialistFeedbacksItem";
import NoFeedbacks from "./NoFeedbacks/NoFeedbacks";

import "./index.scss";

const SpecialistFeedbacks = () => {
    const { allFeedback } = useSelector(allFeedbackSelector);
    const { page } = useSelector(allFeedbackParamsSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(
        () => dispatch(getAllFeedback(id, sizeItemOnPage)),
        [dispatch, id]
    );

    return (
        <div className="specialist-feedbacks">
            <div className="specialist-feedbacks__header">
                {t("feedbacks-unique")} ID:{" "}
                <span className="specialist-feedbacks__header_id">{id}</span>
            </div>
            <div className="specialist-feedbacks__inner">
                {allFeedback.count ? (
                    <table className="specialist-feedbacks-item">
                        <thead className="specialist-feedbacks-item__header">
                            <tr className="specialist-feedbacks-item__header_item">
                                {specialistFeedbacksItemTtitle.map((title) => (
                                    <th
                                        className="specialist-feedbacks-item__title"
                                        key={title}
                                    >
                                        {t(title)}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        {allFeedback.items.map((feedback) => (
                            <SpecialistFeedbacksItem
                                key={feedback._id}
                                {...feedback}
                            />
                        ))}
                    </table>
                ) : (
                    <NoFeedbacks text={t("feedbacks-unique-not-found")} />
                )}
                <Pagination
                    getNextPageItems={(e) =>
                        dispatch(editPageAll(id, sizeItemOnPage, e))
                    }
                    sizeItemOnPage={sizeItemOnPage}
                    isStartedPage={page}
                    items={allFeedback}
                    elementType="page"
                />
            </div>
        </div>
    );
};

export default SpecialistFeedbacks;
