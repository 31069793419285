import React from "react";

import LottieAnimation from "../Lottie-animation";
import loader from "../../../../assets/animation/loader.json";

import "./index.scss";

const GlobalLoader = () => {
    return (
        <div className="global-loader">
            <LottieAnimation lotti={loader} height={400} width={400} />
        </div>
    );
};

export default GlobalLoader;
