import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../../shared/Input/Input";
import Button from "../../../shared/Button/Button";
import LocationSelect from "../../../shared/LocationSelect/LocationSelect";

import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import {
    accountSelector,
    allAccountSelector,
} from "../../../redux/selectors/accountSelectors";
import { citiesLoaderSelector } from "../../../redux/selectors/locationSelectors";
import {
    deleteAccountAvatar,
    getAccount,
    putAccount,
} from "../../../redux/operations/accountOperations";
import { getAllDistricts } from "../../../redux/operations/locationOperations";

import SELECT_TYPES from "../../../shared/LocationSelect/location-select.constants";
import { normalizePutUserData } from "../AdminForm/AdminForm.helpers";
import { isObjectEqual } from "../../../helpers/isObjectEqual";
import { specialistFormSchema } from "./SpecialistForm.Schema";
import { specialistFormConfig } from "./SpecialistForm.config";

import "./SpecialistForm.scss";

const SpecialistForm = ({ details: { _id, role, city } }) => {
    const account = useSelector(accountSelector);
    const { loading } = useSelector(allAccountSelector);
    const { cities, districts } = useSelector(citiesLoaderSelector);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectedCity, setSelectedCity] = useState({
        state_code: "",
        name: city,
    });

    const {
        handleSubmit,
        register,
        reset,
        resetField,
        control,
        formState: { errors, isValid },
    } = useForm({
        mode: "all",
        defaultValues: useMemo(() => {
            return account;
        }, [account]),
        resolver: yupResolver(specialistFormSchema),
    });

    const { isDirty } = useFormState({ control });

    useEffect(() => {
        dispatch(getAccount(_id, role));
    }, [dispatch, _id, role]);

    useEffect(() => {
        reset(account);
    }, [account, reset]);

    const handleSelectCity = (currentCity) => {
        if (
            isObjectEqual(currentCity, selectedCity) |
            (currentCity.name === selectedCity.name)
        )
            return;
        resetField("district");
        setSelectedCity(currentCity);
    };

    const handleFormSubmit = (data) => {
        if (isDirty) {
            dispatch(putAccount(_id, normalizePutUserData(data)));
        }

        dispatch(closeModalActionCreator());
        reset(data);
    };

    const handleCloseModal = () => {
        dispatch(closeModalActionCreator());
    };

    const handleDeleteAvatar = () => {
        account.avatar && dispatch(deleteAccountAvatar(_id));
    };
    useEffect(() => {
        if (selectedCity?.state_code || city)
            dispatch(getAllDistricts(selectedCity.state_code, city));
    }, [selectedCity, city]);

    return (
        <form
            className="specialist_form"
            onSubmit={handleSubmit(handleFormSubmit)}
        >
            <div className="specialist_form__content">
                <h2 className="specialist_form__title">
                    {t("edit-user")} ({t("specialist")})
                </h2>
                <div className="specialist_form__items">
                    <div className="specialist__item">
                        <label className="specialist__item_label">
                            {t("photo")}
                        </label>
                        <div className="specialist__upload">
                            {account.avatar ? (
                                <img
                                    className="specialist__upload_preview"
                                    src={account.avatar}
                                    alt="avatar"
                                />
                            ) : (
                                <div className="specialist__upload_default" />
                            )}
                        </div>
                        <button
                            type="button"
                            onClick={handleDeleteAvatar}
                            className={`specialist__item_btn--delete ${
                                account.avatar ? "uploaded" : "empty"
                            }`}
                        >
                            {t("btn-delete-photo")}
                        </button>
                    </div>
                    {specialistFormConfig.map(
                        ({
                            type,
                            label,
                            value,
                            placeholder,
                            placeholderPhoneNumber,
                            placeholderDialCode,
                        }) => {
                            if (type === "phone") {
                                return (
                                    <Input
                                        type={type}
                                        key={value}
                                        label={label}
                                        inputOptionsFirst={register(
                                            "phone.dialCode"
                                        )}
                                        inputOptionsSecond={register(
                                            "phone.number"
                                        )}
                                        placeholderDialCode={
                                            placeholderDialCode
                                        }
                                        placeholderPhoneNumber={
                                            placeholderPhoneNumber
                                        }
                                        error={errors?.phone}
                                        errorDialCode={
                                            errors?.phone?.dialCode?.message
                                        }
                                        errorPhoneNumber={
                                            errors?.phone?.number?.message
                                        }
                                    />
                                );
                            }
                            return (
                                <Controller
                                    name={value}
                                    control={control}
                                    key={value + label}
                                    render={({ field }) => (
                                        <Input
                                            type={type}
                                            label={label}
                                            inputChangeOptions={field}
                                            placeholderText={placeholder}
                                            error={errors[value]?.message}
                                        />
                                    )}
                                />
                            );
                        }
                    )}
                    <div className="specialist__item">
                        <label className="specialist__item_label">
                            {t("services-specialist-city")}
                        </label>
                        <Controller
                            name="city"
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={cities}
                                    error={errors.city?.message}
                                    onSelect={(city) => {
                                        handleSelectCity(city);
                                        onChange(city.name);
                                    }}
                                    onBlur={(city) => onBlur(city.name)}
                                    type={SELECT_TYPES.WITHOUT_LABEL}
                                    styleHeight={"54px"}
                                />
                            )}
                        />
                    </div>
                    <div className="specialist__item">
                        <label className="specialist__item_label">
                            {t("services-specialist-district")}
                        </label>
                        <Controller
                            control={control}
                            name="district"
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={districts}
                                    onSelect={(district) =>
                                        onChange(district.name)
                                    }
                                    onBlur={(district) => onBlur(district.name)}
                                    type={SELECT_TYPES.WITHOUT_LABEL}
                                    error={errors.district?.message}
                                    styleHeight={"54px"}
                                />
                            )}
                        />
                    </div>
                    <div className="specialist__item">
                        <label className="specialist__item_label">
                            {t("popular")}
                        </label>
                        <select
                            className={`specialist__item_select ${
                                errors.category ? "error" : ""
                            }`}
                            {...register("popular")}
                        >
                            <option value={true}>{t("yes")}</option>
                            <option value={false}>{t("no")}</option>
                        </select>
                        {errors.popular && (
                            <span className="specialist__item-error">
                                {t(errors.popular.message)}
                            </span>
                        )}
                    </div>
                    <div className="specialist__service">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button
                            type="submit"
                            buttonStyle={"btn--green"}
                            disabled={!isValid}
                            loading={loading}
                        >
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SpecialistForm;
