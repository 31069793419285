import Blog from "../../components/Tables/Blog/Blog";

import "./BlogPage.scss";

const BlogPage = () => {
    return (
        <div className="blog-container">
            <Blog />
        </div>
    );
};

export default BlogPage;
