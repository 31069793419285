import * as blogTypes from "../types/blogTypes";

import { sizeBlogItemsOnPage } from "../../components/Tables/Blog/blog.config";

const initialParams = {
    blogParams: { limit: sizeBlogItemsOnPage, page: "&page=0" },
};

const initialState = {
    blogList: { items: [], count: 0 },
    params: initialParams,
    loading: false,
    error: "",
};

export const blogReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case blogTypes.GET_BLOG_LIST_REQUEST:
        case blogTypes.CREATE_BLOG_REQUEST:
        case blogTypes.DELETE_BLOG_REQUEST:
        case blogTypes.GET_BLOG_BY_ID_REQUEST:
        case blogTypes.UPDATE_BLOG_REQUEST: {
            return { ...state, loading: true };
        }

        // success
        case blogTypes.GET_BLOG_LIST_SUCCESS:
        case blogTypes.CREATE_BLOG_SUCCESS:
        case blogTypes.DELETE_BLOG_SUCCESS:
        case blogTypes.GET_BLOG_BY_ID_SUCCESS:
        case blogTypes.UPDATE_BLOG_SUCCESS: {
            return { ...state, blogList: payload, loading: false, error: "" };
        }

        // error
        case blogTypes.GET_BLOG_LIST_ERROR:
        case blogTypes.CREATE_BLOG_ERROR:
        case blogTypes.DELETE_BLOG_ERROR:
        case blogTypes.GET_BLOG_BY_ID_ERROR:
        case blogTypes.UPDATE_BLOG_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        // set
        case blogTypes.SET_BLOG_PAGE:
            return {
                ...state,
                params: {
                    ...state.params,
                    blogParams: { ...state.params.blogParams, page: payload },
                },
            };
        case blogTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialParams };

        default:
            return state;
    }
};
