import { useEffect, useState } from "react";

const useLazyImage = (lowQualitySrc, highQualitySrc) => {
    const [src, setSrc] = useState(lowQualitySrc);

    useEffect(() => {
        setSrc(lowQualitySrc);
        const img = new Image();
        img.src = highQualitySrc;
        img.onload = () => setSrc(highQualitySrc);
        return () => setSrc("");
    }, [lowQualitySrc, highQualitySrc]);

    return src;
};

export default useLazyImage;
