import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./index.scss";

const Input = ({
    label = "",
    error = "",
    errorDialCode = "",
    errorPhoneNumber = "",
    inputChangeOptions = {},
    inputOptionsFirst = {},
    inputOptionsSecond = {},
    placeholderText = "",
    placeholderDialCode = "",
    placeholderPhoneNumber = "",
    type = "",
}) => {
    const { t } = useTranslation();
    switch (type) {
        case "textarea":
            return (
                <div className="form-container">
                    {label && (
                        <label className="form-container__label">
                            {t(label)}
                        </label>
                    )}
                    <textarea
                        {...inputChangeOptions}
                        placeholder={t(placeholderText)}
                        className={`form-container__textarea${
                            error ? "--error" : ""
                        }`}
                    />
                    {error && (
                        <span className="form-container__error">
                            {t(error)}
                        </span>
                    )}
                </div>
            );
        case "input":
            return (
                <div className="form-container">
                    {label && (
                        <label className="form-container_label">
                            {t(label)}
                        </label>
                    )}
                    <input
                        {...inputChangeOptions}
                        placeholder={t(placeholderText)}
                        className={`form-container_input ${
                            error ? "error" : ""
                        }`}
                    />
                    {error && (
                        <span className="form-container-error">{t(error)}</span>
                    )}
                </div>
            );
        case "phone":
            return (
                <div className="form-container">
                    {label && (
                        <label className="form-container_label">
                            {t(label)}
                        </label>
                    )}
                    <div className="form-container__phone">
                        <input
                            {...inputOptionsFirst}
                            className={`form-container__phone-code ${
                                errorDialCode ? "error" : ""
                            }`}
                            placeholder={t(placeholderDialCode)}
                        />
                        <input
                            {...inputOptionsSecond}
                            className={`form-container__phone-number ${
                                errorPhoneNumber ? "error" : ""
                            }`}
                            placeholder={t(placeholderPhoneNumber)}
                        />
                    </div>
                    {error && (
                        <span className="form-container-error">
                            {t(errorDialCode || errorPhoneNumber)}
                        </span>
                    )}
                </div>
            );
        default:
            return "";
    }
};

PropTypes.Input = {
    type: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    placeholderText: PropTypes.string,
    inputChangeOptions: PropTypes.object,
};
export default Input;
