import { get, post, destroy, put } from "../api";

export const getAllTagsRequest = async (params = "") => {
    const url = "/tags/list" + params;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const postTagRequest = async (tagData) => {
    const url = `/tags`;

    try {
        const { data } = await post(url, tagData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const deleteTagRequest = async (id) => {
    const url = `/tags/${id}`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const putTagRequest = async (reqData = {}, id) => {
    const url = `/tags/${id}`;

    try {
        const { data } = await put(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};
