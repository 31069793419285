import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import React from "react";

import { sendLogin } from "../../../redux/operations/authOperations";

import { loginSchema } from "./LoginForm.Schema";

import "./LoginForm.scss";

const LoginForm = () => {
    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm({
        mode: "all",
        resolver: yupResolver(loginSchema),
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleFormSubmit = (data) => dispatch(sendLogin(data, navigate));

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)} className="login-form">
            <div className="login-form__field">
                <div className="login-form__field-label">
                    <label>{t("email")}</label>
                </div>
                <input
                    className={`login-form__field-input ${
                        errors.email && "error"
                    }`}
                    type="text"
                    {...register("email")}
                    placeholder={t("your-email")}
                />
                {errors.email && (
                    <span className="login-form__field-error">
                        {t(errors.email.message)}
                    </span>
                )}
            </div>
            <div className="login-form__field">
                <div className="login-form__field-label">
                    <label>{t("password")}</label>
                </div>
                <input
                    className={`login-form__field-input ${
                        errors.password && "error"
                    }`}
                    type="password"
                    {...register("password")}
                    placeholder={t("your-password")}
                />
                {errors.password && (
                    <span className="login-form__field-error">
                        {t(errors.password.message)}
                    </span>
                )}
            </div>
            <button
                disabled={!isValid}
                type="submit"
                className="login-form__button"
            >
                {t("login")}
            </button>
        </form>
    );
};

export default LoginForm;
