import { typeOfComplaints } from "../../../pages/Complaints/constants";
import routerBook from "../../../router/routerBook";

const navigationLinks = [
    { title: "title-account", to: routerBook.accounts, dualTo: false },
    { title: "title-admin", to: routerBook.listOfAdminUsers, dualTo: false },
    { title: "title-service", to: routerBook.services, dualTo: false },
    { title: "title-promotion", to: routerBook.promotion, dualTo: false },
    {
        title: "title-promotions-history",
        to: routerBook.promotionsHistory,
        dualTo: false,
    },

    { title: "title-blog", to: routerBook.blog, dualTo: false },
    { title: "title-support", to: routerBook.support, dualTo: false },
    { title: "title-flags", to: routerBook.flags, dualTo: false },
    { title: "title-faq", to: routerBook.faq, dualTo: false },
    {
        includedArray: [
            `${routerBook.complaints}/${typeOfComplaints.types}`,
            `${routerBook.complaints}/${typeOfComplaints.users}`,
        ],
        title: "title-complaint",
        dualTo: true,
        options: [
            {
                title: "title-types-complaint",
                to: `${routerBook.complaints}/${typeOfComplaints.types}`,
            },
            {
                title: "title-all-complaints",
                to: `${routerBook.complaints}/${typeOfComplaints.users}`,
            },
        ],
    },
];

export { navigationLinks };
