import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { openModalActionCreator } from "../../../../redux/actions/modalActions";
import { deleteAdmin } from "../../../../redux/operations/adminOperations";

import { adminRoles } from "../../../../configs/accountRoles";

import "./AdminsItem.scss";

const AdminsItem = ({
    _id,
    role,
    city,
    email,
    firstName,
    lastName,
    phone,
    district,
    isDeleted,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onEdit = () => {
        dispatch(
            openModalActionCreator({
                typeModal: "EDIT_ADMIN",
                details: {
                    type: "EDIT_ADMIN",
                    account: {
                        city,
                        district,
                        email,
                        firstName,
                        lastName,
                        phone,
                        role,
                        _id,
                    },
                },
            })
        );
    };

    const handleOnDelete = () => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    _id,
                    title:
                        adminRoles.hasOwnProperty(role) &&
                        t(adminRoles[role].text),
                    action: () => dispatch(deleteAdmin(_id)),
                },
            })
        );
    };
    const phoneNumber = phone?.dialCode
        ? phone?.dialCode + phone?.number
        : phone?.number || phone;

    return (
        <tr className="admins-item">
            <td>
                {adminRoles.hasOwnProperty(role) && t(adminRoles[role].text)}
                {isDeleted && (
                    <span className="admins-item--deleted">{t("deleted")}</span>
                )}
            </td>
            <td>
                {firstName} {lastName}
            </td>
            <td>{phoneNumber}</td>
            <td>{email}</td>
            <td>{city}</td>
            <td className="admins-item__services">
                <button
                    className="admins-item__services--edit"
                    onClick={onEdit}
                >
                    {t("btn-edit")}
                </button>

                <button
                    className="admins-item__services--delete"
                    onClick={handleOnDelete}
                >
                    {t("btn-delete")}
                </button>
            </td>
        </tr>
    );
};
export default AdminsItem;
