// get category
export const GET_ALL_CATEGORY_REQUEST = "category/GET_ALL_CATEGORY_REQUEST";
export const GET_ALL_CATEGORY_SUCCESS = "category/GET_ALL_CATEGORY_SUCCESS";
export const GET_ALL_CATEGORY_ERROR = "category/GET_ALL_CATEGORY_ERROR";

// get all  category without limit
export const GET_ALL_CATEGORY_WITHOUT_LIMIT_REQUEST =
    "category/GET_ALL_CATEGORY_WITHOUT_LIMIT_REQUEST";
export const GET_ALL_CATEGORY_WITHOUT_LIMIT_SUCCESS =
    "category/GET_ALL_CATEGORY_WITHOUT_LIMIT_SUCCESS";
export const GET_ALL_CATEGORY_WITHOUT_LIMIT_ERROR =
    "category/GET_ALL_CATEGORY_WITHOUT_LIMIT_ERROR";

// post category
export const POST_CATEGORY_REQUEST = "category/POST_CATEGORY_REQUEST";
export const POST_CATEGORY_SUCCESS = "category/POST_CATEGORY_SUCCESS";
export const POST_CATEGORY_ERROR = "category/POST_CATEGORY_ERROR";

// put category
export const PUT_CATEGORY_REQUEST = "category/PUT_CATEGORY_REQUEST";
export const PUT_CATEGORY_SUCCESS = "category/PUT_CATEGORY_SUCCESS";
export const PUT_CATEGORY_ERROR = "category/POST_CATEGORY_ERROR";

// delete category
export const DELETE_CATEGORY_REQUEST = "category/DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "category/DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_ERROR = "category/DELETE_CATEGORY_ERROR";

// get subcategory
export const GET_ALL_SUBCATEGORY_ERROR = "category/GET_ALL_SUBCATEGORY_ERROR";
export const GET_ALL_SUBCATEGORY_SUCCESS =
    "category/GET_ALL_SUBCATEGORY_SUCCESS";
export const GET_ALL_SUBCATEGORY_REQUEST =
    "category/GET_ALL_SUBCATEGORY_REQUEST";

// get all  subcategory without limit
export const GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_REQUEST =
    "category/GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_REQUEST";
export const GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_SUCCESS =
    "category/GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_SUCCESS";
export const GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_ERROR =
    "category/GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_ERROR";

// post subcategory
export const POST_SUBCATEGORY_REQUEST = "category/POST_SUBCATEGORY_REQUEST";
export const POST_SUBCATEGORY_SUCCESS = "category/POST_SUBCATEGORY_SUCCESS";
export const POST_SUBCATEGORY_ERROR = "category/POST_SUBCATEGORY_ERROR";

// put subcategory
export const PUT_SUBCATEGORY_REQUEST = "category/PUT_SUBCATEGORY_REQUEST";
export const PUT_SUBCATEGORY_SUCCESS = "category/PUT_SUBCATEGORY_SUCCESS";
export const PUT_SUBCATEGORY_ERROR = "category/POST_SUBCATEGORY_ERROR";

// delete subcategory
export const DELETE_SUBCATEGORY_REQUEST = "category/DELETE_SUBCATEGORY_REQUEST";
export const DELETE_SUBCATEGORY_SUCCESS = "category/DELETE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_ERROR = "category/DELETE_SUBCATEGORY_ERROR";

// get clarification
export const GET_ALL_CLARIFICATION_REQUEST =
    "category/GET_ALL_CLARIFICATION_REQUEST";
export const GET_ALL_CLARIFICATION_SUCCESS =
    "category/GET_ALL_CLARIFICATION_SUCCESS";
export const GET_ALL_CLARIFICATION_ERROR =
    "category/GET_ALL_CLARIFICATION_ERROR";

// post clarification
export const POST_CLARIFICATION_REQUEST = "category/POST_CLARIFICATION_REQUEST";
export const POST_CLARIFICATION_SUCCESS = "category/POST_CLARIFICATION_SUCCESS";
export const POST_CLARIFICATION_ERROR = "category/POST_CLARIFICATION_ERROR";

// put clarification
export const PUT_CLARIFICATION_REQUEST = "category/PUT_CLARIFICATION_REQUEST";
export const PUT_CLARIFICATION_SUCCESS = "category/PUT_CLARIFICATION_SUCCESS";
export const PUT_CLARIFICATION_ERROR = "category/POST_CLARIFICATION_ERROR";

// delete clarification
export const DELETE_CLARIFICATION_ERROR = "category/DELETE_CLARIFICATION_ERROR";
export const DELETE_CLARIFICATION_SUCCESS =
    "category/DELETE_CLARIFICATION_SUCCESS";
export const DELETE_CLARIFICATION_REQUEST =
    "category/DELETE_CLARIFICATION_REQUEST";

// set
export const SET_CLARIFICATION_PAGE = "category/SET_CLARIFICATION_PAGE";
export const SET_SUBCATEGORY_PAGE = "category/SET_SUBCATEGORY_PAGE";
export const SET_CATEGORY_PAGE = "category/SET_CATEGORY_PAGE";
export const CLEAR_ALL_PARAMS = "account/CLEAR_ALL_PARAMS";
