import { PersistGate } from "redux-persist/integration/react";
import React, { StrictMode, Suspense } from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import "./localization/i18n";

import { persistor, store } from "./redux/store/store";
import App from "./components/App/App";

ReactDOM.render(
    <StrictMode>
        <Suspense fallback={<div>Loading...</div>}>
            <PersistGate loading={null} persistor={persistor}>
                <Provider store={store}>
                    <App />
                </Provider>
            </PersistGate>
        </Suspense>
    </StrictMode>,
    document.getElementById("root")
);
