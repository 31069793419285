import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import { faqSelector } from "../../../redux/selectors/faqSelectors";
import { postFAQ, putFAQ } from "../../../redux/operations/faqOperations";
import { closeModalActionCreator } from "../../../redux/actions/modalActions";

import Input from "../../../shared/Input/Input";
import Button from "../../../shared/Button/Button";
import { FAQFormSchema } from "./FAQForm.schema";

import "./FAQForm.scss";

const FAQForm = ({
    details: { id, titleTranslations, descriptionTranslations },
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loading } = useSelector(faqSelector);
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
        defaultValues: { titleTranslations, descriptionTranslations },
        resolver: yupResolver(FAQFormSchema),
    });

    const onSubmit = (data) => {
        if (id) dispatch(putFAQ(id, data));
        else dispatch(postFAQ(data));
    };
    const handleCloseModal = () => dispatch(closeModalActionCreator());

    return (
        <form className="faq_form" onSubmit={handleSubmit(onSubmit)}>
            <div className="faq_form__content">
                <h2 className="faq_form__title">
                    {t(`faq.form.${id ? "edit" : "add"}`)}
                </h2>
                <div className="faq_form__items">
                    <div className="faq_item">
                        <label className="faq_item_label">{t("title")}</label>
                        <div className="faq_item_translation">
                            <div className="faq_item_translation_text">EN</div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "titleTranslations.en"
                                )}
                                error={errors?.titleTranslations?.en?.message}
                            />
                        </div>
                    </div>
                    <div className="faq_item">
                        <div className="faq_item_translation">
                            <div className="faq_item_translation_text">RU</div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "titleTranslations.ru"
                                )}
                                error={errors?.titleTranslations?.ru?.message}
                            />
                        </div>
                    </div>
                    <div className="faq_item">
                        <div className="faq_item_translation">
                            <div className="faq_item_translation_text">TR</div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "titleTranslations.tr"
                                )}
                                error={errors?.titleTranslations?.tr?.message}
                            />
                        </div>
                    </div>
                    <div className="faq_item">
                        <label className="faq_item_label">
                            {t("description")}
                        </label>
                        <div className="faq_item_translation">
                            <div className="faq_item_translation_text">EN</div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "descriptionTranslations.en"
                                )}
                                error={
                                    errors?.descriptionTranslations?.en?.message
                                }
                            />
                        </div>
                    </div>
                    <div className="faq_item">
                        <div className="faq_item_translation">
                            <div className="faq_item_translation_text">RU</div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "descriptionTranslations.ru"
                                )}
                                error={
                                    errors?.descriptionTranslations?.ru?.message
                                }
                            />
                        </div>
                    </div>
                    <div className="faq_item">
                        <div className="faq_item_translation">
                            <div className="faq_item_translation_text">TR</div>
                            <Input
                                type="textarea"
                                inputChangeOptions={register(
                                    "descriptionTranslations.tr"
                                )}
                                error={
                                    errors?.descriptionTranslations?.tr?.message
                                }
                            />
                        </div>
                    </div>

                    <div className="faq_service">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button
                            type="submit"
                            buttonStyle={"btn--green"}
                            loading={loading}
                        >
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default FAQForm;
