import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useState } from "react";
import dayjs from "dayjs";

import ScheduleWork from "../ScheduleWork/ScheduleWork";

import { ReactComponent as DateOfBithday } from "../../assets/images/date_of_bithday.svg";
import "./index.scss";

export const ScheduleInput = ({ placeholder, onChange, value, error }) => {
    const [isScheduleVisible, setIsScheduleVisible] = useState(false);
    const { t } = useTranslation();

    const generatePlaceholderContent = () => {
        if (!value) return placeholder;

        return `${t("services-specialist-schedule")}: ${value.map((item) => {
            if (item.isWeekend) return ` ${item.day} - ${t("weekend")}`;
            return ` ${item.day} ${
                item.workingHours[0].timeFrom.length > 5
                    ? dayjs(item.workingHours[0].timeFrom).format("H:mm")
                    : item.workingHours[0].timeFrom
            } - ${
                item.workingHours[0].timeTo.length > 5
                    ? dayjs(item.workingHours[0].timeTo).format("H:mm")
                    : item.workingHours[0].timeTo
            }`;
        })}`;
    };

    return (
        <div className={`schedule-input-container${error ? "--error" : ""}`}>
            <div
                className="schedule-input"
                onClick={() => setIsScheduleVisible(!isScheduleVisible)}
            >
                <p className="schedule-input__content">
                    {generatePlaceholderContent()}
                </p>
                <DateOfBithday />
            </div>
            {isScheduleVisible && (
                <ScheduleWork
                    defaultSchedule={value}
                    onCloseSchedule={() => setIsScheduleVisible(false)}
                    onSaveScheduleData={(data) => {
                        onChange(data);
                    }}
                />
            )}
            {error && <p className="schedule-input__error">{t(error)}</p>}
        </div>
    );
};

ScheduleInput.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.array,
    error: PropTypes.string,
};

export default ScheduleInput;
