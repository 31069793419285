export const flagsTitle = ["title-flags", "Web", "IOS", "Android"];
export const flagsHeader = [
    "about-us",
    "chat",
    "filters-in-search",
    "links-to-social-networks",
    "notifications",
    "orders",
    "payment-system",
    "popular-services",
    "promotion",
    "push-notifications",
    "regions",
    "services",
    "support-chat",
];
