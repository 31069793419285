// get all services
export const GET_ALL_SPECIALIST_SERVICES_REQUEST =
    "account/GET_ALL_SPECIALIST_SERVICES_REQUEST";
export const GET_ALL_SPECIALIST_SERVICES_SUCCESS =
    "account/GET_ALL_SPECIALIST_SERVICES_SUCCESS";
export const GET_ALL_SPECIALIST_SERVICES_ERROR =
    "account/GET_ALL_SPECIALIST_SERVICES_ERROR";

// put service
export const PUT_SPECIALIST_SERVICE_REQUEST =
    "service/PUT_SPECIALIST_SERVICE_REQUEST";
export const PUT_SPECIALIST_SERVICE_SUCCESS =
    "service/PUT_SPECIALIST_SERVICE_SUCCESS";
export const PUT_SPECIALIST_SERVICE_ERROR =
    "service/PUT_SPECIALIST_SERVICE_ERROR";
