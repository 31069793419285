// deactivate support ticket
export const DEACTIVATE_SUPPORT_TICKET_REQUEST =
    "support/DEACTIVATE_SUPPORT_TICKET_REQUEST";
export const DEACTIVATE_SUPPORT_TICKET_SUCCESS =
    "support/DEACTIVATE_SUPPORT_TICKET_SUCCESS";
export const DEACTIVATE_SUPPORT_TICKET_ERROR =
    "support/DEACTIVATE_SUPPORT_TICKET_ERROR";

// get support tickets
export const GET_SUPPORT_TICKETS_REQUEST =
    "support/GET_SUPPORT_TICKETS_REQUEST";
export const GET_SUPPORT_TICKETS_SUCCESS =
    "support/GET_SUPPORT_TICKETS_SUCCESS";
export const GET_SUPPORT_TICKETS_ERROR = "support/GET_SUPPORT_TICKETS_ERROR";

// get chat
export const GET_SUPPORT_CHAT_REQUEST = "support/GET_SUPPORT_CHAT_REQUEST";
export const GET_SUPPORT_CHAT_SUCCESS = "support/GET_SUPPORT_CHAT_SUCCESS";
export const GET_SUPPORT_CHAT_ERROR = "support/GET_SUPPORT_CHAT_ERROR";

// post chat file
export const POST_SUPPORT_CHAT_FILE_REQUEST =
    "support/POST_SUPPORT_CHAT_FILE_REQUEST";
export const POST_SUPPORT_CHAT_FILE_SUCCESS =
    "support/POST_SUPPORT_CHAT_FILE_SUCCESS";
export const POST_SUPPORT_CHAT_FILE_ERROR =
    "support/POST_SUPPORT_CHAT_FILE_ERROR";
// join to chat
export const JOIN_TO_SUPPORT_CHAT_QUESTION_REQUEST =
    "support/JOIN_TO_SUPPORT_CHAT_QUESTION_REQUEST";
export const JOIN_TO_SUPPORT_CHAT_QUESTION_SUCCESS =
    "support/JOIN_TO_SUPPORT_CHAT_QUESTION_SUCCESS";
export const JOIN_TO_SUPPORT_CHAT_QUESTION_ERROR =
    "support/JOIN_TO_SUPPORT_CHAT_QUESTION_ERROR";

// disable chat
export const DISABLE_SUPPORT_CHAT_REQUEST =
    "support/DISABLE_SUPPORT_CHAT_REQUEST";
export const DISABLE_SUPPORT_CHAT_SUCCESS =
    "support/DISABLE_SUPPORT_CHAT_SUCCESS";
export const DISABLE_SUPPORT_CHAT_ERROR = "support/DISABLE_SUPPORT_CHAT_ERROR";

//set
export const SET_PAGE = "support/SET_PAGE";
export const SET_TEXT = "support/SET_TEXT";
export const CLEAR_ALL_PARAMS = "support/CLEAR_ALL_PARAMS";
export const CLEAR_ALL_PARAMS_SUPPORT_CHAT =
    "support/CLEAR_ALL_PARAMS_SUPPORT_CHAT";
