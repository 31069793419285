export const genderTypes = {
    male: "MALE",
    female: "FEMALE",
    preferNotToTell: "PREFER_NOT_TO_TELL",
};

export const genders = [
    { label: "men", value: genderTypes.male },
    { label: "women", value: genderTypes.female },
    { label: "prefer_not_to_tell", value: genderTypes.preferNotToTell },
];
