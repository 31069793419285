import { ReactComponent as EmptyList } from "../../../../assets/images/empty-result.svg";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { supportSelector } from "../../../../redux/selectors/supportSelectors";

const NoSupportTickets = () => {
    const { loading } = useSelector(supportSelector);
    return (
        <div className="support_item_no-exist">
            <div className="support_item_no-exist-image">
                <EmptyList />
            </div>
            <div className="support_item_no-exist-text">
                {loading
                    ? t("support.ticket.loading")
                    : t("support.ticket.empty")}
            </div>
        </div>
    );
};

export default NoSupportTickets;
