export const websocketsTypes = {
    // connect
    OPENING_WEBSOCKET_CONNECTION: "websockets/OPENING_WEBSOCKET_CONNECTION",
    WEBSOCKET_CONNECTED: "websockets/WEBSOCKET_CONNECTED",

    // disconnect
    DISCONNECT_WEBSOCKET: "websockets/DISCONNECT_WEBSOCKET",

    // set user online status
    SET_USER_ONLINE_STATUS: "websockets/SET_USER_ONLINE_STATUS",
};
