import { deleteAccountRequest } from "../../services/requests/account";
import * as requests from "../../services/requests/admin";

import { changeGlobalLoader } from "../../components/Loader/loader.actions";
import { closeModalActionCreator } from "../actions/modalActions";
import { setToastifyStatus } from "../actions/toastifyActions";
import {
    adminParamsSelector,
    adminSelector,
} from "../selectors/adminSelectors";
import * as adminActions from "../actions/adminActions";

import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";
import { sizeItemOnPage } from "../../components/Tables/Admins/Admins.config";

// get admin list
export const getAdminList =
    (params = "") =>
    (dispatch, getState) => {
        dispatch(changeGlobalLoader({ status: true }));
        dispatch(adminActions.getAdminListRequest());
        const { text } = adminParamsSelector(getState());

        return requests
            .getAdminListRequest(`?limit=${sizeItemOnPage}` + text + params)
            .then(({ items, totalCount: count }) => {
                dispatch(adminActions.getAdminListSuccess({ items, count }));
            })
            .catch(({ error }) =>
                dispatch(adminActions.getAdminListError(error))
            )
            .finally(() => dispatch(changeGlobalLoader({ status: false })));
    };

// post admin
export const postAdmin = (data) => (dispatch, getState) => {
    const { adminList } = adminSelector(getState());
    dispatch(adminActions.postAdminRequest());

    return requests
        .postAdminRequest(data)
        .then((item) => {
            dispatch(
                adminActions.postAdminSuccess({
                    items: [...adminList.items, item],
                    count: adminList.count + 1,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.POST_ADMIN,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => dispatch(adminActions.postAdminError(error)));
};

//put admin
export const putAdmin = (id, data) => (dispatch, getState) => {
    dispatch(adminActions.putAdminRequest());
    const {
        adminList: { items, count },
    } = adminSelector(getState());

    return requests
        .putAdminRequest(id, data)
        .then((data) => {
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PATCH_ADMIN,
                    type: "success",
                })
            );

            const newItems = items.map((body) =>
                body._id === id ? { ...body, ...data } : body
            );

            dispatch(adminActions.putAdminSuccess({ items: newItems, count }));
            dispatch(closeModalActionCreator());
        })
        .catch(({ error }) => dispatch(adminActions.putAdminError(error)));
};

// patch admin
export const patchAdmin = (data) => (dispatch) => {
    dispatch(adminActions.patchAdminRequest());

    if ("_id" in data) delete data?._id;
    if ("password" in data) delete data?.password;
    if ("email" in data) delete data?.email;
    if ("role" in data) delete data?.role;
    if ("flag" in data?.phone) delete data?.phone?.flag;
    if ("dialCode" in data?.phone) delete data?.phone?.dialCode;

    return requests
        .patchAdminRequest(data)
        .then(() => dispatch(closeModalActionCreator()))
        .catch((error) => dispatch(adminActions.patchAdminError(error)));
};

// patch permissions
export const patchPermissions = (id, role, data) => (dispatch, getState) => {
    dispatch(adminActions.patchPermissionsRequest());
    const { adminList } = adminSelector(getState());

    return requests
        .patchPermissionsRequest(id, role, data)
        .then((response) => {
            const newItems = adminList.items.map((item) =>
                item._id === id
                    ? {
                          ...item,
                          permissions: { ...item.permissions, ...response },
                      }
                    : item
            );
            dispatch(
                adminActions.patchPermissionsSuccess({
                    items: newItems,
                    count: adminList.count,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PATCH_ADMIN,
                    type: "success",
                })
            );
        })
        .catch((error) => dispatch(adminActions.patchPermissionsError(error)));
};

// delete admin
export const deleteAdmin = (id) => (dispatch, getState) => {
    const { adminList } = adminSelector(getState());
    dispatch(adminActions.deleteAdminRequest());

    return deleteAccountRequest(id)
        .then(() => {
            const newItems = adminList?.items.map((item) => {
                if (item._id === id) {
                    item.isDeleted = true;
                }

                return item;
            });

            dispatch(
                adminActions.deleteAdminSuccess({
                    items: newItems,
                    count: adminList.count,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_ADMIN,
                    type: "success",
                })
            );
        })
        .catch((error) => dispatch(adminActions.deleteAdminError(error)));
};

// set
export const editPage = (data) => (dispatch) => {
    dispatch(adminActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getAdminList(url));
};

export const editRole = (data) => (dispatch) => {
    dispatch(adminActions.setRole(`&role=${data}`));
    const url = `&role=${data}`;
    dispatch(getAdminList(url));
};

export const editSearchText = (data) => (dispatch) => {
    dispatch(adminActions.setPage("&page=0"));
    dispatch(adminActions.setText(`&text=${data}`));

    dispatch(getAdminList("&page=0"));
};
