import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React from "react";

import { setToastifyStatus } from "../../redux/actions/toastifyActions";
import { toastifyStatusSelector } from "../../redux/selectors/toastifySelectors";

import "react-toastify/dist/ReactToastify.css";
import "./Toastify.scss";

const Toastify = () => {
    const { title, type } = useSelector(toastifyStatusSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const Notification = () => <h4>{t(title)}</h4>;

    const resetToastify = () => {
        dispatch(
            setToastifyStatus({
                isVisible: false,
                type: "",
                title: "",
                html: "",
            })
        );
    };

    if (type === "error") {
        toast.error(<Notification />);
        resetToastify();
    }

    if (type === "success") {
        toast.success(<Notification />);
        resetToastify();
    }

    return (
        <ToastContainer
            pauseOnFocusLoss={false}
            position="bottom-right"
            newestOnTop={false}
            autoClose={5000}
            closeOnClick
            pauseOnHover
            rtl={false}
            draggable
            limit={6}
        />
    );
};

export default Toastify;
