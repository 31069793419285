import { string, object, boolean } from "yup";
import { t } from "i18next";

const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const specialistFormSchema = object().shape({
    firstName: string()
        .trim(true)
        .required("required")
        .min(3, ({ min }) => t("validation-min", { min }))
        .max(20, ({ max }) => t("validation-max", { max })),
    lastName: string()
        .trim(true)
        .required("required")
        .min(3, ({ min }) => t("validation-min", { min }))
        .max(20, ({ max }) => t("validation-max", { max })),
    email: string().required("validation_required").email("wrong-email"),
    phone: object().shape({
        dialCode: string()
            .required("required")
            .min(0, ({ min }) => t("validation-min", { min }))
            .max(4, ({ max }) => t("validation-max", { max })),
        number: string()
            .required("required")
            .matches(phoneRegExp, "wrong-phone")
            .min(7, ({ min }) => t("validation-min", { min }))
            .max(13, ({ max }) => t("validation-max", { max })),
    }),
    city: string().required("required"),
    district: string().required("required"),
    popular: boolean().required("required"),
});
