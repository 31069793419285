import { convertScheduleInputValuesToTimeString } from "../../../shared/ScheduleInput/schedule-input.utils";

export const convertSpecialistServiceDataToRequestSchema = ({ ...rest }) => {
    const updatedSpecialistServiceData = {
        priceFrom: rest.priceFrom,
        priceTo: rest.priceTo,
        comment: rest.comment,
        city: rest.city,
        address: rest.address,
        district: rest.district,
        categoryId: rest.category._id,
        clarificationId: rest.clarification._id,
        subcategoryId: rest.subcategory._id,
        schedule: convertScheduleInputValuesToTimeString(rest.schedule),
    };

    return updatedSpecialistServiceData;
};
