export const supportTypes = [
    { key: "", field: "all" },
    { key: "&onlyActive=onlyActive", field: "active" },
];

export const supportTitle = [
    { field: "type", key: "type" },
    { field: "user", key: "user" },
    { field: "category", key: "category" },
    { field: "title", key: "title" },
    { field: "description", key: "description" },
    { field: "status", key: "status" },
];
export const sizeItemOnPage = 6;
