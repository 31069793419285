import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BackButton from "../../shared/BackButton/BackButton";

import SupportChatBody from "./SupportChatBody/SupportChatBody";
import "./SupportChat.scss";

const SupportChat = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="support_chat">
            <div className="support_chat__header">
                <div className="support_chat__header_btn">
                    <BackButton
                        onClickCallback={() => navigate(-1)}
                        title={t("btn-prev")}
                    />
                </div>
                <div className="support_chat__header_main">
                    <h1 className="support_chat__header_main_title">
                        {t("support.chat.support")}
                    </h1>
                </div>
            </div>
            <div className="support_chat__body">
                <SupportChatBody />
            </div>
        </div>
    );
};

export default SupportChat;
