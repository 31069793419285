import * as complaintType from "../types/complaintType";

// get complaint type

export const getAllComplaintTypeRequest = () => ({
    type: complaintType.GET_ALL_COMPLAINT_TYPE_REQUEST,
});

export const getAllComplaintTypeSuccess = (payload) => ({
    type: complaintType.GET_ALL_COMPLAINT_TYPE_SUCCESS,
    payload,
});

export const getAllComplaintTypeError = (payload) => ({
    type: complaintType.GET_ALL_COMPLAINT_TYPE_ERROR,
    payload,
});

// delete complaint
export const deleteComplaintTypeRequest = () => ({
    type: complaintType.DELETE_COMPLAINT_TYPE_REQUEST,
});

export const deleteComplaintTypeSuccess = (payload) => ({
    type: complaintType.DELETE_COMPLAINT_TYPE_SUCCESS,
    payload,
});

export const deleteComplaintTypeError = (payload) => ({
    type: complaintType.DELETE_COMPLAINT_TYPE_ERROR,
    payload,
});

// add complaint
export const postComplaintTypeRequest = () => ({
    type: complaintType.POST_COMPLAINT_TYPE_REQUEST,
});

export const postComplaintTypeSuccess = (payload) => ({
    type: complaintType.POST_COMPLAINT_TYPE_SUCCESS,
    payload,
});

export const postComplaintTypeError = (payload) => ({
    type: complaintType.POST_COMPLAINT_TYPE_ERROR,
    payload,
});

// add complaint
export const putComplaintTypeRequest = () => ({
    type: complaintType.PUT_COMPLAINT_TYPE_REQUEST,
});

export const putComplaintTypeSuccess = (payload) => ({
    type: complaintType.PUT_COMPLAINT_TYPE_SUCCESS,
    payload,
});

export const putComplaintTypeError = (payload) => ({
    type: complaintType.PUT_COMPLAINT_TYPE_ERROR,
    payload,
});

//get all complaint user
export const getAllComplaintUserRequest = () => ({
    type: complaintType.GET_ALL_COMPLAINT_USER_REQUEST,
});

export const getAllComplaintUserSuccess = (payload) => ({
    type: complaintType.GET_ALL_COMPLAINT_USER_SUCCESS,
    payload,
});

export const getAllComplaintUserError = (payload) => ({
    type: complaintType.GET_ALL_COMPLAINT_USER_ERROR,
    payload,
});

// delete complaint user
export const deleteComplaintUserRequest = () => ({
    type: complaintType.DELETE_COMPLAINT_USER_REQUEST,
});

export const deleteComplaintUserSuccess = (payload) => ({
    type: complaintType.DELETE_COMPLAINT_USER_SUCCESS,
    payload,
});

export const deleteComplaintUserError = (payload) => ({
    USER: complaintType.DELETE_COMPLAINT_USER_ERROR,
    payload,
});

//get all complaints user
export const getAllComplaintsUserRequest = () => ({
    type: complaintType.GET_ALL_COMPLAINTS_USER_REQUEST,
});

export const getAllComplaintsUserSuccess = (payload) => ({
    type: complaintType.GET_ALL_COMPLAINTS_USER_SUCCESS,
    payload,
});

export const getAllComplaintsUserError = (payload) => ({
    type: complaintType.GET_ALL_COMPLAINTS_USER_ERROR,
    payload,
});

// delete complaint user
export const deleteComplaintsUserRequest = () => ({
    type: complaintType.DELETE_COMPLAINTS_USER_REQUEST,
});

export const deleteComplaintsUserSuccess = (payload) => ({
    type: complaintType.DELETE_COMPLAINTS_USER_SUCCESS,
    payload,
});

export const deleteComplaintsUserError = (payload) => ({
    type: complaintType.DELETE_COMPLAINTS_USER_ERROR,
    payload,
});

//set
export const clearAllParams = () => ({
    type: complaintType.CLEAR_ALL_PARAMS,
});

export const setPage = (data) => ({
    type: complaintType.SET_PAGE,
    payload: data,
});

export const setText = (data) => ({
    type: complaintType.SET_TEXT,
    payload: data,
});
