import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import { openModalActionCreator } from "../../../redux/actions/modalActions";
import { clearAllParams } from "../../../redux/actions/adminActions";
import {
    editPage,
    getAdminList,
} from "../../../redux/operations/adminOperations";
import {
    adminParamsSelector,
    adminSelector,
} from "../../../redux/selectors/adminSelectors";

import { adminsTitle, sizeItemOnPage } from "./Admins.config";
import Pagination from "../../Pagination/Pagination";
import AdminsItem from "./AdminsItem/AdminsItem";
import AdminSort from "./AdminSort/AdminSort";

import "./Admins.scss";
import AdminSearch from "./AdminSearch/AdminSearch";
import Button from "../../../shared/Button/Button";

const Admins = () => {
    const { adminList } = useSelector(adminSelector);
    const { page } = useSelector(adminParamsSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getAdminList());
        return () => dispatch(clearAllParams());
    }, [dispatch]);

    const handleOnAdd = () => {
        dispatch(openModalActionCreator({ typeModal: "ADD_ADMIN" }));
    };
    return (
        <div className="admins">
            <AdminSort />
            <AdminSearch />
            <table className="admins__inner">
                <thead className="admins__header">
                    <tr className="admins__header_item">
                        {adminsTitle.map((title, index) => (
                            <th className="admins__title" key={index}>
                                {t(title)}
                            </th>
                        ))}
                        <th className="admins__title">
                            <Button
                                className="admins__button"
                                buttonStyle={"btn--outlined"}
                                onClick={handleOnAdd}
                            >
                                {t("add-admin")}
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody className="admins__body">
                    {adminList?.items?.length > 0 &&
                        adminList?.items?.map((admin) => (
                            <AdminsItem key={admin._id} {...admin} />
                        ))}
                </tbody>
            </table>
            <Pagination
                getNextPageItems={(e) => dispatch(editPage(e))}
                sizeItemOnPage={sizeItemOnPage}
                isStartedPage={page}
                items={adminList}
                elementType="page"
            />
        </div>
    );
};

export default Admins;
