import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import FlagsTable from "../../components/Tables/FlagsTable/FlagsTable";
import Button from "../../shared/Button/Button";

import { setToastifyStatus } from "../../redux/actions/toastifyActions";

import "./Flags.scss";

const Flags = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleTestModeRequest = () => {
        dispatch(
            setToastifyStatus({
                title: "Flags was successful updated",
                type: "success",
            })
        );
    };
    return (
        <div className="flags-container">
            <FlagsTable />

            <div className="flags-container__service">
                <Button
                    type="submit"
                    buttonStyle={"btn--green"}
                    onClick={handleTestModeRequest}
                >
                    {t("btn-save")}
                </Button>
            </div>
        </div>
    );
};

export default Flags;
