import { object, string, array, mixed } from "yup";
import { t } from "i18next";

export const PromotionFormSchema = object().shape({
    nameTranslations: object().shape({
        en: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        ru: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        tr: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
    }),
    descriptionTranslations: object().shape({
        en: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        ru: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        tr: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
    }),
    extendedDescription: object().shape({
        en: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        ru: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        tr: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
    }),
    activatedAccounts: string().required("required"),
    bought: string().required("required"),
    rate: string().required("required"),
    type: string().required("required"),
    image: mixed().required("required"),
    serviceFor: string().required("required"),
    prices: object().shape({
        day: array().of(
            object().shape({
                count: string().required("required"),
                price: string().required("required"),
            })
        ),
        week: array().of(
            object().shape({
                count: string().required("required"),
                price: string().required("required"),
            })
        ),
        month: array().of(
            object().shape({
                count: string().required("required"),
                price: string().required("required"),
            })
        ),
        year: array().of(
            object().shape({
                count: string().required("required"),
                price: string().required("required"),
            })
        ),
    }),
});
