import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllCities } from "../../redux/operations/locationOperations";

const LocationLoader = () => {
    const dispatch = useDispatch();

    useEffect(() => dispatch(getAllCities()), [dispatch]);

    return null;
};

export default LocationLoader;
