import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import Button from "../../../shared/Button/Button";

import "./index.scss";

const RestoreForm = ({ details }) => {
    const { action, title, description } = details;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleCloseModal = () => dispatch(closeModalActionCreator());

    return (
        <div className="restore-form">
            <div className="restore-form__title">
                <span>{title}</span>
            </div>
            <div className="restore-form__title">
                <span>{description}</span>
            </div>
            <div className="restore-form__btns">
                <Button onClick={handleCloseModal} buttonStyle={"btn--red"}>
                    {t("btn-cancel")}
                </Button>
                <Button onClick={action} buttonStyle={"btn--green"}>
                    {t("btn-restore")}
                </Button>
            </div>
        </div>
    );
};

export default RestoreForm;
