// get admin list
export const GET_ADMIN_LIST_REQUEST = "admin/GET_ADMIN_LIST_REQUEST";
export const GET_ADMIN_LIST_SUCCESS = "admin/GET_ADMIN_LIST_SUCCESS";
export const GET_ADMIN_LIST_ERROR = "admin/GET_ADMIN_LIST_ERROR";

// post admin
export const POST_ADMIN_REQUEST = "admin/POST_ADMIN_REQUEST";
export const POST_ADMIN_SUCCESS = "admin/POST_ADMIN_SUCCESS";
export const POST_ADMIN_ERROR = "admin/POST_ADMIN_ERROR";

// patch admin
export const PATCH_ADMIN_REQUEST = "admin/PATCH_ADMIN_REQUEST";
export const PATCH_ADMIN_SUCCESS = "admin/PATCH_ADMIN_SUCCESS";
export const PATCH_ADMIN_ERROR = "admin/PATCH_ADMIN_ERROR";

// put admin
export const PUT_ADMIN_REQUEST = "admin/PUT_ADMIN_REQUEST";
export const PUT_ADMIN_SUCCESS = "admin/PUT_ADMIN_SUCCESS";
export const PUT_ADMIN_ERROR = "admin/PUT_ADMIN_ERROR";

// patch permissions
export const PATCH_PERMISSIONS_REQUEST = "admin/PATCH_PERMISSIONS_REQUEST";
export const PATCH_PERMISSIONS_SUCCESS = "admin/PATCH_PERMISSIONS_SUCCESS";
export const PATCH_PERMISSIONS_ERROR = "admin/PATCH_PERMISSIONS_ERROR";

// delete admin
export const DELETE_ADMIN_REQUEST = "admin/DELETE_ADMIN_REQUEST";
export const DELETE_ADMIN_SUCCESS = "admin/DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_ERROR = "admin/DELETE_ADMIN_ERROR";

// set
export const CLEAR_ALL_PARAMS = "admin/CLEAR_ALL_PARAMS";
export const SET_PAGE = "admin/SET_PAGE";
export const SET_TEXT = "admin/SET_TEXT";
export const SET_ROLE = "admin/SET_ROLE";
