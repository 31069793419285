const deleteId = (prices) => {
    Object.values(prices).forEach((priceItem) =>
        priceItem.forEach((pricePeroid) => delete pricePeroid._id)
    );
    return prices;
};

export const getPromotionData = (data) => ({
    image: data.image,
    bought: data.bought,
    activatedAccounts: data.activatedAccounts,
    descriptionTranslations: data.descriptionTranslations,
    extendedDescription: data.extendedDescription,
    nameTranslations: data.nameTranslations,
    prices: deleteId(data.prices),
    rate: data.rate,
    type: data.type,
    serviceFor: data.serviceFor,
});

export const parseMoneyAmount = (value) => {
    const isValueWithComa = value.indexOf(",");

    if (isValueWithComa === -1) {
        return value + "00";
    } else {
        if (value.substring(isValueWithComa + 1).length === 1) {
            return value.replace(",", "") + "0";
        } else return value.replace(",", "");
    }
};
