import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../shared/Button/Button";
import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import { patchPermissions } from "../../../redux/operations/adminOperations";

import { contentWriterFormSchema } from "./ContentWriterForm.schema";
import { contentWriterFormConfig } from "./ContentWriterForm.config";
import { adminSelector } from "../../../redux/selectors/adminSelectors";

import "./ContentWriterForm.scss";

const ContentWriterForm = ({ details: { permissions, _id, role } }) => {
    const { loading } = useSelector(adminSelector);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        mode: "all",
        defaultValues: permissions,
        resolver: yupResolver(contentWriterFormSchema),
    });

    const handleCloseModal = () => dispatch(closeModalActionCreator());

    const handleFormSubmit = (data) => {
        dispatch(patchPermissions(_id, role, data));
    };

    return (
        <form
            className="content-writer_form"
            onSubmit={handleSubmit(handleFormSubmit)}
        >
            <div className="content-writer_form__content">
                <h2 className="content-writer_form__title">
                    {t("edit-content-writer")}
                </h2>
                <div className="content-writer_form__items">
                    {contentWriterFormConfig.map(({ label, value }) => (
                        <div key={value} className="content-writer__item">
                            <label className="content-writer__item_label">
                                {t(label)}
                            </label>
                            <select
                                className={`content-writer__item_select ${
                                    errors[value] ? "error" : ""
                                }`}
                                {...register(value)}
                            >
                                <option value={true}>{t("yes")}</option>
                                <option value={false}>{t("no")}</option>
                            </select>
                            {errors[value] && (
                                <span className="content-writer__item-error">
                                    {t(errors[value].message)}
                                </span>
                            )}
                        </div>
                    ))}
                    <div className="content-writer__service">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button
                            type="submit"
                            buttonStyle={"btn--green"}
                            loading={loading}
                        >
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContentWriterForm;
