import { object, string } from "yup";
import { t } from "i18next";

export const FAQFormSchema = object().shape({
    titleTranslations: object().shape({
        en: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        ru: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        tr: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
    }),
    descriptionTranslations: object().shape({
        en: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        ru: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        tr: string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
    }),
});
