import { useMemo, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Input from "../../../shared/Input/Input";
import Button from "../../../shared/Button/Button";
import LocationSelect from "../../../shared/LocationSelect/LocationSelect";

import { getAllDistricts } from "../../../redux/operations/locationOperations";
import { postAdmin, putAdmin } from "../../../redux/operations/adminOperations";
import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import { citiesLoaderSelector } from "../../../redux/selectors/locationSelectors";
import { adminSelector } from "../../../redux/selectors/adminSelectors";

import {
    normalizePostUserData,
    normalizePutUserData,
} from "./AdminForm.helpers";
import { adminFormSchema } from "./AdminForm.schema";
import { adminFormConfig } from "./AdminForm.config";
import { adminRoles } from "../../../configs/accountRoles";
import { isObjectEqual } from "../../../helpers/isObjectEqual";

import "./AdminForm.scss";

const AdminForm = ({ details }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loading } = useSelector(adminSelector);
    const { cities, districts } = useSelector(citiesLoaderSelector);

    const [selectedCity, setSelectedCity] = useState({
        state_code: "",
        name: details?.city,
    });

    const {
        handleSubmit,
        register,
        control,
        formState: { errors, isValid },
        resetField,
    } = useForm({
        mode: "all",
        defaultValues: useMemo(() => {
            return details?.account;
        }, [details?.account]),
        resolver: yupResolver(adminFormSchema),
    });

    useEffect(() => {
        if (selectedCity?.state_code || details?.account?.city)
            dispatch(
                getAllDistricts(selectedCity.state_code, details?.account?.city)
            );
    }, [selectedCity]);

    const handleCloseModal = () => dispatch(closeModalActionCreator());

    const handleSelectCity = (currentCity) => {
        if (
            isObjectEqual(currentCity, selectedCity) |
            (currentCity.name === selectedCity.name)
        )
            return;
        resetField("district");
        setSelectedCity(currentCity);
    };

    const handleFormSubmit = (data) => {
        const _id = details?.account?._id;

        if (_id) return dispatch(putAdmin(_id, normalizePutUserData(data)));
        dispatch(postAdmin(normalizePostUserData(data)));
    };

    return (
        <form className="admin_form" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="admin_form__content">
                <h2 className="admin_form__title">
                    {details?.type === "EDIT_ADMIN"
                        ? t("edit-admin")
                        : t("add-admin")}
                </h2>
                <div className="admin_form__items">
                    {adminFormConfig.map(
                        ({
                            type,
                            label,
                            value,
                            placeholder,
                            placeholderPhoneNumber,
                            placeholderDialCode,
                        }) => {
                            if (
                                details?.type === "EDIT_ADMIN" &&
                                value === "password"
                            ) {
                                return;
                            }
                            if (type === "phone") {
                                return (
                                    <Input
                                        type={type}
                                        key={value}
                                        label={label}
                                        inputOptionsFirst={register(
                                            "phone.dialCode"
                                        )}
                                        inputOptionsSecond={register(
                                            "phone.number"
                                        )}
                                        placeholderDialCode={
                                            placeholderDialCode
                                        }
                                        placeholderPhoneNumber={
                                            placeholderPhoneNumber
                                        }
                                        error={errors?.phone}
                                        errorDialCode={
                                            errors?.phone?.dialCode?.message
                                        }
                                        errorPhoneNumber={
                                            errors?.phone?.number?.message
                                        }
                                    />
                                );
                            }
                            return (
                                <Controller
                                    name={value}
                                    control={control}
                                    key={value + label}
                                    render={({ field }) => (
                                        <Input
                                            type={type}
                                            label={label}
                                            inputChangeOptions={field}
                                            placeholderText={placeholder}
                                            error={errors[value]?.message}
                                        />
                                    )}
                                />
                            );
                        }
                    )}
                    <div className="admin__item">
                        <label className="admin__item_label">
                            {t("services-specialist-city")}
                        </label>
                        <Controller
                            name="city"
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={cities}
                                    error={errors.city?.message}
                                    onSelect={(city) => {
                                        handleSelectCity(city);
                                        onChange(city.name);
                                    }}
                                    placeholder={t("city-placeholder")}
                                    onBlur={(city) => onBlur(city.name)}
                                />
                            )}
                        />
                    </div>
                    <div className="admin__item">
                        <label className="admin__item_label">
                            {t("services-specialist-district")}
                        </label>
                        <Controller
                            control={control}
                            name="district"
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={districts}
                                    onSelect={(district) =>
                                        onChange(district.name)
                                    }
                                    onBlur={(district) => onBlur(district.name)}
                                    placeholder={t("district-placeholder")}
                                    error={errors.district?.message}
                                />
                            )}
                        />
                    </div>
                    <div className="admin__item">
                        <label className="admin__item_label">{t("role")}</label>
                        <select
                            className={`admin__item_select ${
                                errors.category ? "error" : ""
                            }`}
                            {...register("role")}
                        >
                            {Object.values(adminRoles).map(({ key, text }) => (
                                <option key={key} value={key}>
                                    {t(text)}
                                </option>
                            ))}
                        </select>
                        {errors.popular && (
                            <span className="admin__item-error">
                                {t(errors.popular.message)}
                            </span>
                        )}
                    </div>
                    <div className="admin__service">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button
                            type="submit"
                            buttonStyle={"btn--green"}
                            disabled={!isValid}
                            loading={loading}
                        >
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AdminForm;
