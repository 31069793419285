import * as requests from "../../services/requests/feedbacks";

import { changeGlobalLoader } from "../../components/Loader/loader.actions";
import { allFeedbackSelector } from "../selectors/feedbackSelectors";
import { closeModalActionCreator } from "../actions/modalActions";
import { setToastifyStatus } from "../actions/toastifyActions";
import * as feedbackActions from "../actions/feedbackActions";

import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";

// get all feedback
export const getAllFeedback =
    (id, size, params = "") =>
    (dispatch) => {
        dispatch(feedbackActions.getAllFeedbackRequest());
        dispatch(changeGlobalLoader({ status: true }));

        return requests
            .getFeedbacksRequest(`?specialistId=${id}&limit=${size}` + params)
            .then(({ feedbacks: items, totalCount: count }) => {
                dispatch(
                    feedbackActions.getAllFeedbackSuccess({ items, count })
                );
                dispatch(changeGlobalLoader({ status: false }));
            })
            .catch(({ error }) =>
                dispatch(feedbackActions.getAllFeedbackError(error))
            )
            .finally(() => dispatch(changeGlobalLoader({ status: false })));
    };

// put feedback
export const putFeedback = (id, reporter, data) => (dispatch, getState) => {
    dispatch(feedbackActions.putFeedbackRequest());
    const {
        allFeedback: { items, count },
    } = allFeedbackSelector(getState());

    return requests
        .putFeedbackRequest(id, { ...data, reporter })
        .then(() => {
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PUT_FEEDBACK,
                    type: "success",
                })
            );
            const newItems = items.map((body) =>
                body._id === id ? { ...body, ...data } : body
            );
            dispatch(
                feedbackActions.putFeedbackSuccess({ items: newItems, count })
            );
            dispatch(closeModalActionCreator());
        })
        .catch(({ error }) =>
            dispatch(feedbackActions.putFeedbackError(error))
        );
};

// delete feedback
export const deleteFeedback = (id, reporter) => (dispatch, getState) => {
    dispatch(feedbackActions.deleteFeedbackRequest());
    const {
        allFeedback: { items, count },
    } = allFeedbackSelector(getState());

    return requests
        .deleteFeedbackRequest(id, reporter)
        .then(() => {
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_FEEDBACK,
                    type: "success",
                })
            );
            const newItems = items.filter(({ _id }) => _id !== id);
            dispatch(
                feedbackActions.deleteFeedbackSuccess({
                    items: newItems,
                    count,
                })
            );
            dispatch(closeModalActionCreator());
        })
        .catch(({ error }) =>
            dispatch(feedbackActions.deleteFeedbackError(error))
        );
};

// set
export const editPageAll = (id, size, data) => (dispatch) => {
    dispatch(feedbackActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getAllFeedback(id, size, url));
};
