import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import GlobalLoader from "../Loader/components/global-loader/global-loader";
import LocationLoader from "../LocationLoader/LccationLoader";
import Toastify from "../Toastify/Toastify";
import Router from "../../router/router";
import Modal from "../Modal/Modal";

import { authSelector } from "../../redux/selectors/authSelectors";
import { globalLoaderSelector } from "../Loader/loader.selectors";
import { websocketsSelector } from "../../websockets/websockets.selectors";

import { getIsOpen } from "../../redux/selectors/modalSelectors";
import { socketEvents } from "../../websockets/websockets.constants";
import { generateDeviceID } from "../../helpers/deviceId";

import { setToastifyStatus } from "../../redux/actions/toastifyActions";
import {
    editUserOfflineStatus,
    editUserOnlineStatus,
    connectToWebsocket,
    disconnectedWebsocket,
} from "../../websockets/websockets.operations";

import "../../assets/styles/_main.scss";

const App = () => {
    const { socket } = useSelector(websocketsSelector);
    const { token } = useSelector(authSelector);
    const isLoading = useSelector(globalLoaderSelector);
    const isOpen = useSelector(getIsOpen);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!localStorage.getItem("deviceID")) generateDeviceID();
    }, []);
    useEffect(() => {
        if (token) dispatch(connectToWebsocket());
        return () => dispatch(disconnectedWebsocket());
    }, [token]);

    useEffect(() => {
        if (socket) {
            socket.on(socketEvents.USER_ONLINE, ({ userId }) => {
                dispatch(editUserOnlineStatus(userId));
            });
            socket.on(socketEvents.USER_DISCONNECTED, ({ userId }) => {
                dispatch(editUserOfflineStatus(userId));
            });
            socket.on(socketEvents.EXCEPTION, ({ errorMessage }) => {
                dispatch(setToastifyStatus({ errorMessage, type: "error" }));
            });

            socket.on(
                socketEvents.DISCONNECT,
                () => token && dispatch(connectToWebsocket())
            );
        }
    }, [socket]);
    return (
        <BrowserRouter>
            <div className="App">
                <LocationLoader />
                <Toastify />
                {isOpen && <Modal />}
                {isLoading && <GlobalLoader />}
                <Router />
            </div>
        </BrowserRouter>
    );
};

export default App;
