export const GET_ALL_CITIES_PENDING = "location-loader/GET_ALL_CITIES_PENDING";
export const GET_ALL_CITIES_FULFILLED =
    "location-loader/GET_ALL_CITIES_FULFILLED";
export const GET_ALL_CITIES_REJECTED =
    "location-loader/GET_ALL_CITIES_REJECTED";

export const GET_ALL_DISTRICTS_PENDING =
    "districts-loader/GET_ALL_DISTRICTS_PENDING";
export const GET_ALL_DISTRICTS_FULFILLED =
    "districts-loader/GET_ALL_DISTRICTS_FULFILLED";
export const GET_ALL_DISTRICTS_REJECTED =
    "districts-loader/GET_ALL_DISTRICTS_REJECTED";
