export const GET_FAQ_REQUEST = "faq/GET_FAQ_REQUEST";
export const GET_FAQ_SUCCESS = "faq/GET_FAQ_SUCCESS";
export const GET_FAQ_ERROR = "faq/GET_FAQ_ERROR";

export const POST_FAQ_REQUEST = "faq/POST_FAQ_REQUEST";
export const POST_FAQ_SUCCESS = "faq/POST_FAQ_SUCCESS";
export const POST_FAQ_ERROR = "faq/POST_FAQ_ERROR";

export const PUT_FAQ_REQUEST = "faq/PUT_FAQ_REQUEST";
export const PUT_FAQ_SUCCESS = "faq/PUT_FAQ_SUCCESS";
export const PUT_FAQ_ERROR = "faq/POST_FAQ_ERROR";

export const DELETE_FAQ_REQUEST = "faq/DELETE_FAQ_REQUEST";
export const DELETE_FAQ_SUCCESS = "faq/DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_ERROR = "faq/DELETE_FAQ_ERROR";

export const SET_PAGE = "faq/SET_PAGE";
