import { formDataHeaders, get, patch, post } from "../api";

export const getSupportTickets = async (params = "") => {
    const url = `/question/list` + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

//Doesn`t realized back-end
export const getSupportTicketsForUser = async (params = "", id) => {
    const url = `/questions/${id}` + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deactivateSupportTicket = async (id) => {
    const url = `/question/deactivate/${id}`;

    try {
        const { data } = await patch(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getSupportChat = async (id) => {
    const url = `/chats/${id}`;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const postSupportChatFile = async (id, reqData = {}) => {
    const url = `/chats/${id}/upload`;

    try {
        const { data } = await post(url, reqData, formDataHeaders);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const jonToSupportChat = async (id) => {
    const url = `/question/join-to-question/${id}`;

    try {
        const { data } = await patch(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const disableSupportChat = async (id) => {
    const url = `/chats/${id}/disable`;

    try {
        const { data } = await patch(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
