import {
    OPEN_MODAL,
    CLOSE_MODAL,
    CLEAR_MODAL_STATE,
} from "../types/modalTypes";

export const openModalActionCreator = (payload) => ({
    type: OPEN_MODAL,
    payload,
});
export const closeModalActionCreator = (payload) => ({
    type: CLOSE_MODAL,
    payload,
});

export const clearModalState = () => ({
    type: CLEAR_MODAL_STATE,
});
