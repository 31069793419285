import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import React from "react";

import {
    editRole,
    getAdminList,
} from "../../../../redux/operations/adminOperations";

import { adminRoles } from "../../../../configs/accountRoles";

import "./AdminSort.scss";

const AdminSort = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <div className="admin-sort">
            <label className="admin-sort__label">{t("sort-by")}</label>
            <select
                defaultValue={""}
                onChange={({ target: { value } }) =>
                    dispatch(value ? editRole(value) : getAdminList())
                }
                className="admin-sort__select"
            >
                <option value={""}>{t("all")}</option>
                {Object.values(adminRoles).map(({ key, text }) => (
                    <option key={key} value={key}>
                        {t(text)}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default AdminSort;
