import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

import "./index.scss";

const Pagination = ({
    elementType = "placeholder",
    items = {},
    getNextPageItems = () => {},
    isStartedPage,
    sizeItemOnPage,
    scrollToTop = false,
}) => {
    const [page, setPage] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (isStartedPage === 0 && page !== 0) {
            setPage(0);
            getNextPageItems(0);
        } else if (typeof isStartedPage === "string") {
            const newPage = isStartedPage.split("=")[1];
            setPage(parseInt(newPage));
        }
    }, [isStartedPage, getNextPageItems, page]);

    const _handleNextList = ({ selected }) => {
        getNextPageItems(selected);
        setPage(selected);
        if (scrollToTop)
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    if (elementType === "placeholder") return null;
    if (!items.count) return null;

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {items.count > sizeItemOnPage && (
                <ReactPaginate
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    nextLabel={t("btn-next")}
                    previousLabel={t("btn-prev")}
                    pageCount={
                        items.count
                            ? Math.ceil(items.count / sizeItemOnPage)
                            : 1
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    forcePage={page}
                    onPageChange={_handleNextList}
                    disabledClassName={"disabled"}
                />
            )}
        </div>
    );
};

export default Pagination;
