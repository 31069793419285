export const socketEvents = {
    SUCCESS_CONNECTION: "successConnection",
    USER_ONLINE: "userOnline",
    USER_DISCONNECTED: "userDisconnected",
    EXCEPTION: "exception",
    CREATED_CHAT: "createdChat",
    NOTIFICATION: "NOTIFICATION",
    READ_MESSAGES: "readMessages",
    RECEIVE_MESSAGE: "receiveMessage",
    MESSAGES_WERE_READ: "messagesWereRead",
    OPERATION_HISTORY: "operationHistory",
    SEND_MESSAGE: "sendMessage",
    CHAT_UPDATED: "chatUpdated",
    DISCONNECT: "disconnect",
};
