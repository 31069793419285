import * as authTypes from "../types/authTypes";

const initialState = {
    info: { token: null },

    loading: false,
    error: "",
};

export const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case authTypes.SEND_LOGIN_REQUEST: {
            return { ...state, loading: true };
        }

        case authTypes.SEND_LOGIN_SUCCESS: {
            return { ...state, info: payload, loading: false, error: "" };
        }

        case authTypes.SEND_LOGIN_ERROR: {
            return { ...state, loading: false, error: payload };
        }
        // tokens
        case authTypes.UPDATE_TOKENS: {
            return { ...state, ...payload };
        }

        default:
            return state;
    }
};
