import FAQTable from "../../components/Tables/FAQ/FAQTable";
import "./FAQ.scss";

const FAQ = () => {
    return (
        <div className="faq-container">
            <FAQTable />
        </div>
    );
};

export default FAQ;
