// get all feedback
export const GET_ALL_FEEDBACK_REQUEST = "feedback/GET_ALL_FEEDBACK_REQUEST";
export const GET_ALL_FEEDBACK_SUCCESS = "feedback/GET_ALL_FEEDBACK_SUCCESS";
export const GET_ALL_FEEDBACK_ERROR = "feedback/GET_ALL_FEEDBACK_ERROR";

// put feedback
export const PUT_FEEDBACK_REQUEST = "feedback/PUT_FEEDBACK_REQUEST";
export const PUT_FEEDBACK_SUCCESS = "feedback/PUT_FEEDBACK_SUCCESS";
export const PUT_FEEDBACK_ERROR = "feedback/PUT_FEEDBACK_ERROR";

// delete feedback
export const DELETE_FEEDBACK_REQUEST = "feedback/DELETE_FEEDBACK_REQUEST";
export const DELETE_FEEDBACK_SUCCESS = "feedback/DELETE_FEEDBACK_SUCCESS";
export const DELETE_FEEDBACK_ERROR = "feedback/DELETE_FEEDBACK_ERROR";

//set
export const CLEAR_ALL_PARAMS = "feedback/CLEAR_ALL_PARAMS";
export const SET_PAGE = "feedback/SET_PAGE";
