import { combineReducers } from "redux";

import { loader } from "../../components/Loader/loader.reducer";
import { complaintReducer } from "./complaintReducer";
import { promotionReducer } from "./promotionReducer";
import { feedbackReducer } from "./feedbackReducer";
import { toastifyReducer } from "./toastifyReducer";
import { supportReducer } from "./supportReducer";
import { categoryReducer } from "./categoryReducer";
import { accountReducer } from "./accountReducer";
import { adminReducer } from "./adminReducer";
import { location } from "./locationReducer";
import { modalReducer } from "./modalReducer";
import { authReducer } from "./authReducer";
import { tagsReducer } from "./tagsReducer";
import { websockets } from "../../websockets/websockets.reducer";
import { services } from "./services";
import { blogReducer } from "./blogReducer";
import { faqReducer } from "./faqReducer";
import { myAccountReducer } from "./myAccountReducer";

import { LOGOUT_USER } from "../types/authTypes";

export const appReducer = combineReducers({
    complaint: complaintReducer,
    promotion: promotionReducer,
    feedback: feedbackReducer,
    category: categoryReducer,
    toastify: toastifyReducer,
    account: accountReducer,
    admin: adminReducer,
    modal: modalReducer,
    services: services,
    auth: authReducer,
    location: location,
    tags: tagsReducer,
    faq: faqReducer,
    loader: loader,
    blog: blogReducer,
    myAccount: myAccountReducer,
    websockets: websockets,
    support: supportReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};
