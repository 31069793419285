import { ReactComponent as AddPhotoCircle } from "../../assets/images/upload-photo/add_photo_circle.svg";
import { ReactComponent as AttachFile } from "../../assets/images/upload-photo/attach_file.svg";
import { ReactComponent as SendMessage } from "../../assets/images/upload-photo/send_message.svg";
import { ReactComponent as Undownload } from "../../assets/images/upload-photo/undownload.svg";
import { ReactComponent as WconnectLogo } from "../../assets/images/wconnect_logo.svg";
import { ReactComponent as ChatService } from "../../assets/images/chat/service/chat_service.svg";
import { ReactComponent as ChatClose } from "../../assets/images/chat/service/close.svg";
import { ReactComponent as ChooseVideo } from "../../assets/images/choose_video.svg";
import { ReactComponent as StartIcon } from "../../assets/images/video-player/start.svg";

import { ReactComponent as DownloadIcon } from "../../assets/images/video-player/download.svg";
import { ReactComponent as PrevIcon } from "../../assets/images/video-player/prev.svg";
import { ReactComponent as NextIcon } from "../../assets/images/video-player/next.svg";
import { ReactComponent as StopIcon } from "../../assets/images/video-player/stop.svg";
import { ReactComponent as VolumeOn } from "../../assets/images/video-player/volume-on.svg";
import { ReactComponent as VolumeOff } from "../../assets/images/video-player/volume-off.svg";

export const image = {
    wconnectLogo: <WconnectLogo />,
    addPhotoCircle: <AddPhotoCircle />,
    undownload: <Undownload />,
    attachFile: <AttachFile />,
    chooseVideo: <ChooseVideo />,
    chatService: <ChatService />,
    sendMessage: <SendMessage />,
    chatClose: <ChatClose />,

    //video
    startIcon: <StartIcon />,
    downloadIcon: <DownloadIcon />,
    prevIcon: <PrevIcon />,
    nextIcon: <NextIcon />,
    stopIcon: <StopIcon />,
    volumeOn: <VolumeOn />,
    volumeOff: <VolumeOff />,
};

export const Icon = ({ type }) => image[type];
