import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import { openModalActionCreator } from "../../../redux/actions/modalActions";
import {
    deletePromotion,
    editPagePromotion,
    getPromotion,
} from "../../../redux/operations/promotionOperations";
import {
    promotionParamsSelector,
    promotionSelector,
} from "../../../redux/selectors/promotionSelectors";
import { clearAllParams } from "../../../redux/actions/promotionActions";

import { promotionTitles, sizeItemOnPage } from "./Promotion.config";
import PromotionItem from "./PromotionItem/PromotionItem";
import Pagination from "../../Pagination/Pagination";

import "./Promotion.scss";

const Promotion = () => {
    const { allPromotion } = useSelector(promotionSelector);
    const { page } = useSelector(promotionParamsSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getPromotion());
        return () => dispatch(clearAllParams());
    }, [dispatch, allPromotion.count]);

    const handleOnAdd = () => {
        dispatch(
            openModalActionCreator({ typeModal: "ADD_PROMOTION", details: {} })
        );
    };

    const handleOnEdit = (promotion) => {
        dispatch(
            openModalActionCreator({
                typeModal: "EDIT_PROMOTION",
                details: { imageUrl: promotion.imageUrl, id: promotion._id },
            })
        );
    };

    const handleOnDelete = (id) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    id,
                    title: t("title-promotion"),
                    action: () => dispatch(deletePromotion(id)),
                },
            })
        );
    };

    return (
        <div className="promotion__container">
            <table className="promotion">
                <thead className="promotion__header">
                    <tr className="promotion__header_item">
                        {promotionTitles.map((title, index) => (
                            <th
                                className="promotion__title"
                                key={index + title}
                            >
                                {t(`promotion.titles.${title}`)}
                            </th>
                        ))}
                        <th className="promotion__title">
                            <button
                                className="promotion__btn"
                                onClick={() => handleOnAdd()}
                            >
                                {t("promotion.add")}
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody className="promotion__body">
                    {allPromotion.items.map((promotion) => (
                        <PromotionItem
                            onEdit={() => handleOnEdit(promotion)}
                            onDelete={() => handleOnDelete(promotion._id)}
                            key={promotion._id}
                            {...promotion}
                        />
                    ))}
                </tbody>
            </table>
            <Pagination
                getNextPageItems={(e) => dispatch(editPagePromotion(e))}
                sizeItemOnPage={sizeItemOnPage}
                items={allPromotion}
                isStartedPage={page}
                scrollToTop={true}
                elementType="page"
            />
        </div>
    );
};

export default Promotion;
