const sizeItemOnPage = 7;
const accountsTitle = [
    { field: "type-of-account", key: "role" },
    { field: "full-name", key: "firstName" },
    // { field: "phone", key: "" },
    { field: "email", key: "email" },
    { field: "city", key: "city" },
    { field: "actions", key: "" },
];
export { accountsTitle, sizeItemOnPage };
