import { sizeCategoriesOnPage } from "../../components/Tables/Category/category.config";
import { sizeSubcategoriesOnPage } from "../../components/Tables/Subcategories/subcategories.config";
import { sizeClarificationsOnPage } from "../../components/Tables/Clarifications/clarifications.config";
import * as categoryTypes from "../types/categoryTypes";

const initailParams = {
    clarificationParams: { limit: sizeClarificationsOnPage, page: "&page=0" },
    subcategoryParams: { limit: sizeSubcategoriesOnPage, page: "&page=0" },
    categoryParams: { limit: sizeCategoriesOnPage, page: "&page=0" },
};

const initialState = {
    allClarification: { items: [], count: 0 },
    allSubcategory: { items: [], count: 0 },
    allCategory: { items: [], count: 0 },
    allCategoryWithoutLimit: { items: [], count: 0 },
    allSubcategoryWithoutLimit: { items: [], count: 0 },
    params: initailParams,
    loading: false,
    error: "",
};

export const categoryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case categoryTypes.GET_ALL_CATEGORY_REQUEST:
        case categoryTypes.GET_ALL_CATEGORY_WITHOUT_LIMIT_REQUEST:
        case categoryTypes.GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_REQUEST:
        case categoryTypes.DELETE_CATEGORY_REQUEST:
        case categoryTypes.POST_CATEGORY_REQUEST:
        case categoryTypes.PUT_CATEGORY_REQUEST:
        case categoryTypes.GET_ALL_SUBCATEGORY_REQUEST:
        case categoryTypes.DELETE_SUBCATEGORY_REQUEST:
        case categoryTypes.POST_SUBCATEGORY_REQUEST:
        case categoryTypes.PUT_SUBCATEGORY_REQUEST:
        case categoryTypes.GET_ALL_CLARIFICATION_REQUEST:
        case categoryTypes.DELETE_CLARIFICATION_REQUEST:
        case categoryTypes.POST_CLARIFICATION_REQUEST:
        case categoryTypes.PUT_CLARIFICATION_REQUEST: {
            return { ...state, loading: true };
        }

        // data category
        case categoryTypes.GET_ALL_CATEGORY_SUCCESS:
        case categoryTypes.DELETE_CATEGORY_SUCCESS:
        case categoryTypes.POST_CATEGORY_SUCCESS:
        case categoryTypes.PUT_CATEGORY_SUCCESS: {
            return {
                ...state,
                allCategory: payload,
                loading: false,
                error: "",
            };
        }

        case categoryTypes.GET_ALL_CATEGORY_WITHOUT_LIMIT_SUCCESS: {
            return {
                ...state,
                allCategoryWithoutLimit: payload,
                loading: false,
                error: "",
            };
        }
        case categoryTypes.GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_SUCCESS: {
            return {
                ...state,
                allSubcategoryWithoutLimit: payload,
                loading: false,
                error: "",
            };
        }

        // data subcategory
        case categoryTypes.GET_ALL_SUBCATEGORY_SUCCESS:
        case categoryTypes.DELETE_SUBCATEGORY_SUCCESS:
        case categoryTypes.POST_SUBCATEGORY_SUCCESS:
        case categoryTypes.PUT_SUBCATEGORY_SUCCESS: {
            return {
                ...state,
                allSubcategory: payload,
                loading: false,
                error: "",
            };
        }

        // data clarification
        case categoryTypes.GET_ALL_CLARIFICATION_SUCCESS:
        case categoryTypes.DELETE_CLARIFICATION_SUCCESS:
        case categoryTypes.POST_CLARIFICATION_SUCCESS:
        case categoryTypes.PUT_CLARIFICATION_SUCCESS: {
            return {
                ...state,
                allClarification: payload,
                loading: false,
                error: "",
            };
        }

        // error
        case categoryTypes.GET_ALL_CATEGORY_ERROR:
        case categoryTypes.GET_ALL_CATEGORY_WITHOUT_LIMIT_ERROR:
        case categoryTypes.GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_ERROR:
        case categoryTypes.DELETE_CATEGORY_ERROR:
        case categoryTypes.POST_CATEGORY_ERROR:
        case categoryTypes.PUT_CATEGORY_ERROR:
        case categoryTypes.GET_ALL_SUBCATEGORY_ERROR:
        case categoryTypes.DELETE_SUBCATEGORY_ERROR:
        case categoryTypes.POST_SUBCATEGORY_ERROR:
        case categoryTypes.PUT_SUBCATEGORY_ERROR:
        case categoryTypes.GET_ALL_CLARIFICATION_ERROR:
        case categoryTypes.DELETE_CLARIFICATION_ERROR:
        case categoryTypes.POST_CLARIFICATION_ERROR:
        case categoryTypes.PUT_CLARIFICATION_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        // set
        case categoryTypes.SET_CATEGORY_PAGE:
            return {
                ...state,
                params: {
                    ...state.params,
                    categoryParams: {
                        ...state.params.categoryParams,
                        page: payload,
                    },
                },
            };
        case categoryTypes.SET_SUBCATEGORY_PAGE:
            return {
                ...state,
                params: {
                    ...state.params,
                    subcategoryParams: {
                        ...state.params.subcategoryParams,
                        page: payload,
                    },
                },
            };
        case categoryTypes.SET_CLARIFICATION_PAGE:
            return {
                ...state,
                params: {
                    ...state.params,
                    clarificationParams: {
                        ...state.params.clarificationParams,
                        page: payload,
                    },
                },
            };
        case categoryTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initailParams };

        default:
            return state;
    }
};
