import * as myAccountActions from "../actions/myAccountActions";
import * as myAccountRequests from "../../services/requests/my-account";
import { changeGlobalLoader } from "../../components/Loader/loader.actions";

export const getUser = () => (dispatch) => {
    dispatch(myAccountActions.getUserRequest());
    dispatch(changeGlobalLoader(true));

    return myAccountRequests
        .getUserById()
        .then((response) => {
            dispatch(myAccountActions.getUserSuccess(response));
        })
        .catch((error) => {
            dispatch(myAccountActions.getUserError(error));
        })
        .finally(() => dispatch(changeGlobalLoader(false)));
};
