import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useState, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Icon } from "../../Image/Image";
import { navigationLinks } from "./navigation.config";
import { logoutUser } from "../../../redux/actions/authActions";

import useOutsideClick from "../../../hooks/useOutsideClick";
import routerBook from "../../../router/routerBook";
import Language from "../Language/Language";

import "./Navigation.scss";

const Navigation = () => {
    const [openDual, setOpenDual] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dualLinkRef = useRef();

    const handleLogout = () => {
        dispatch(logoutUser());
        navigate(routerBook.login);
    };

    const handleClickDual = () => setOpenDual((prevState) => !prevState);
    useOutsideClick(dualLinkRef, () => setOpenDual(false));

    const handleHavigateLogo = () => navigate("/accounts");

    return (
        <aside className="sidebar">
            <nav className="nav">
                <div className="nav__inner">
                    <div className="nav__logo" onClick={handleHavigateLogo}>
                        <Icon type="wconnectLogo" />
                    </div>
                    <Language />
                    <div className="nav__content">
                        <ul className="nav__links">
                            {navigationLinks.map(
                                (
                                    {
                                        to,
                                        dualTo,
                                        title,
                                        options,
                                        includedArray,
                                    },
                                    index
                                ) => (
                                    <li key={index} className="nav__item">
                                        {!dualTo ? (
                                            <NavLink
                                                to={to}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "nav__link--active"
                                                        : "nav__link"
                                                }
                                            >
                                                {t(title)}
                                            </NavLink>
                                        ) : (
                                            <Fragment>
                                                <div
                                                    className="nav__dual"
                                                    ref={dualLinkRef}
                                                    onClick={handleClickDual}
                                                >
                                                    <span
                                                        className={`nav__link${
                                                            includedArray.includes(
                                                                pathname
                                                            )
                                                                ? "--active"
                                                                : ""
                                                        }`}
                                                    >
                                                        {t(title)}
                                                        {dualTo && (
                                                            <span
                                                                className={`nav__link-arrow${
                                                                    openDual
                                                                        ? "-active"
                                                                        : ""
                                                                }`}
                                                            >
                                                                &#129168;
                                                            </span>
                                                        )}
                                                    </span>

                                                    {openDual && (
                                                        <div className="nav__dual-links">
                                                            {options.map(
                                                                (
                                                                    {
                                                                        title,
                                                                        to,
                                                                    },
                                                                    index
                                                                ) => (
                                                                    <NavLink
                                                                        className={({
                                                                            isActive,
                                                                        }) =>
                                                                            isActive
                                                                                ? "nav__dual-links-item--active"
                                                                                : "nav__dual-links-item"
                                                                        }
                                                                        onClick={
                                                                            handleClickDual
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                        to={to}
                                                                    >
                                                                        {t(
                                                                            title
                                                                        )}
                                                                    </NavLink>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </Fragment>
                                        )}
                                    </li>
                                )
                            )}
                            <li className="nav__item">
                                <button
                                    className="logout__button"
                                    onClick={handleLogout}
                                >
                                    {t("title-exit")}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </aside>
    );
};

export default Navigation;
