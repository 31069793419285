import { useTranslation } from "react-i18next";

import emptyResultIcon from "../../../assets/images/empty-result.svg";
import "./SupportChatEmpty.scss";

const ChatsEmptyResult = () => {
    const { t } = useTranslation();

    return (
        <div className="support__chat-empty">
            <img
                className="support__chat-empty--img"
                src={emptyResultIcon}
                alt="Empty result"
            />
            <span className="support__chat-empty--title">
                {t("support.chat.no-messages-yet")}
            </span>
        </div>
    );
};

export default ChatsEmptyResult;
