import PropTypes from "prop-types";
import React from "react";

import loader from "../../assets/animation/loader.json";
import LottieAnimation from "../LottieAnimation/LottieAnimation";

import "./index.scss";

const ButtonLoader = ({ size }) => {
    return (
        <div className="spinner-container">
            <LottieAnimation lotti={loader} width={size} height={size} />
        </div>
    );
};

ButtonLoader.propTypes = { size: PropTypes.number };

export default ButtonLoader;
