import * as locationTypes from "../types/locationTypes";

const initialState = {
    districts: [],

    cities: [],

    error: "",
    loading: false,
};

export const location = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case locationTypes.GET_ALL_CITIES_PENDING: {
            return { ...state, loading: true };
        }

        // data
        case locationTypes.GET_ALL_CITIES_FULFILLED: {
            return { ...state, cities: payload, loading: false };
        }

        // error
        case locationTypes.GET_ALL_CITIES_REJECTED: {
            return { ...state, error: payload, loading: false };
        }

        //districts
        case locationTypes.GET_ALL_DISTRICTS_PENDING: {
            return { ...state, loading: true };
        }
        case locationTypes.GET_ALL_DISTRICTS_FULFILLED: {
            return { ...state, districts: payload, loading: false };
        }
        case locationTypes.GET_ALL_DISTRICTS_REJECTED: {
            return { ...state, error: payload, loading: false };
        }

        default:
            return state;
    }
};
