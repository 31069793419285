import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

import Select from "react-select";
import Button from "../../../shared/Button/Button";

import {
    getAllSubcategoryWithoutLimit,
    postClarification,
    putClarification,
} from "../../../redux/operations/categoryOperations";
import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import {
    allSubcategoryWithoutLimitSelector,
    categorySelector,
} from "../../../redux/selectors/categorySelectors";

import { clarificationSchema } from "./ClarificationForm.Schema";

import "./index.scss";

const ClarificationForm = ({ details }) => {
    const customStyles = {
        input: (styles) => ({
            ...styles,
            height: 35,
        }),
        singleValue: (styles) => ({
            textAlign: "left",
            ...styles,
        }),
        container: (styles) => ({
            ...styles,
            borderRadius: 6,
            width: 282,
            border: "0.1px solid black",
        }),
        placeholder: (styles) => ({
            ...styles,
            textAlign: "left",
        }),
    };

    const {
        handleSubmit,
        register,
        setValue,
        control,
        formState: { errors },
    } = useForm({
        mode: "all",
        defaultValues: { ...details },
        resolver: yupResolver(clarificationSchema),
    });

    const { loading } = useSelector(categorySelector);
    const { items } = useSelector(allSubcategoryWithoutLimitSelector);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { _id } = details;

    useEffect(() => {
        if (_id)
            Object.entries(details).forEach(([name, value]) =>
                setValue(name, value)
            );
    }, [_id, setValue, details]);

    useEffect(() => {
        dispatch(getAllSubcategoryWithoutLimit());
    }, []);

    const handleCloseModal = () => dispatch(closeModalActionCreator());

    const onSubmit = (data) => {
        if (_id) {
            dispatch(putClarification(data, _id));
        } else {
            dispatch(postClarification(data));
        }
    };

    return (
        <form className="clarification__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="clarification__form__content">
                <h2 className="clarification__form__title">
                    {t(`services.clarification.${_id ? "edit" : "add"}`)}
                </h2>
                <div className="clarification__form__items">
                    <div className="clarification__item">
                        <label className="clarification__item_label">
                            {t("naming")}
                        </label>
                        <input
                            className={`clarification__item_input ${
                                errors.name && "error"
                            }`}
                            {...register("remark")}
                        />
                        {errors?.remark && (
                            <span className="clarification__item-error">
                                {t(errors?.remark?.message)}
                            </span>
                        )}
                    </div>

                    <div className="clarification__item">
                        <label className="clarification__item_label">
                            {t("translations")}
                        </label>
                        <div className="clarification__item_translation">
                            <div className="clarification__item_translation_text">
                                EN
                            </div>
                            <input
                                className={`clarification__item_input ${
                                    errors?.titleTranslations?.en && "error"
                                }`}
                                {...register("titleTranslations.en")}
                            />
                        </div>
                        {errors?.titleTranslations?.en && (
                            <span className="clarification__item_translation-error">
                                {t(errors?.titleTranslations?.en.message)}
                            </span>
                        )}
                    </div>
                    <div className="clarification__item">
                        <div className="clarification__item_translation">
                            <div className="clarification__item_translation_text">
                                RU
                            </div>
                            <input
                                className={`clarification__item_input ${
                                    errors?.titleTranslations?.ru && "error"
                                }`}
                                {...register("titleTranslations.ru")}
                            />
                        </div>
                        {errors?.titleTranslations?.ru && (
                            <span className="clarification__item_translation-error">
                                {t(errors?.titleTranslations?.ru.message)}
                            </span>
                        )}
                    </div>
                    <div className="clarification__item">
                        <div className="clarification__item_translation">
                            <div className="clarification__item_translation_text">
                                TR
                            </div>
                            <input
                                className={`clarification__item_input ${
                                    errors?.titleTranslations?.tr && "error"
                                }`}
                                {...register("titleTranslations.tr")}
                            />
                        </div>
                        {errors?.titleTranslations?.tr && (
                            <span className="clarification__item_translation-error">
                                {t(errors?.titleTranslations?.tr.message)}
                            </span>
                        )}
                    </div>
                    <div className="clarification__item">
                        <label className="clarification__item_label">
                            {t("subcategory")}
                        </label>
                        <Controller
                            name={"subcategory"}
                            control={control}
                            render={({ field: { onChange, ref, value } }) => (
                                <Select
                                    inputRef={ref}
                                    maxMenuHeight={150}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    options={items}
                                    styles={customStyles}
                                    onChange={({ _id }) => onChange(_id)}
                                    getOptionValue={(option) => option._id}
                                    getOptionLabel={(option) => option.remark}
                                    value={items?.find((c) => {
                                        return c._id === value;
                                    })}
                                />
                            )}
                        />
                        {errors.subcategory && (
                            <span className="clarification__item-error">
                                {t(errors.subcategory.message)}
                            </span>
                        )}
                    </div>
                    <div className="clarification__clarification">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button
                            type="submit"
                            buttonStyle={"btn--green"}
                            loading={loading}
                        >
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ClarificationForm;
