import * as authTypes from "../types/authTypes";

export const sendLoginRequest = () => ({
    type: authTypes.SEND_LOGIN_REQUEST,
});

export const sendLoginSuccess = (payload) => ({
    type: authTypes.SEND_LOGIN_SUCCESS,
    payload,
});

export const sendLoginError = (payload) => ({
    type: authTypes.SEND_LOGIN_ERROR,
    payload,
});

export const logoutUser = () => ({
    type: authTypes.LOGOUT_USER,
});

//tokens
export const updateTokens = (data) => ({
    type: authTypes.UPDATE_TOKENS,
    payload: data,
});
