import { get, put } from "../api";

export const getServicesRequest = async (id) => {
    const url = `/admin/services/${id}`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const putServiceRequest = async (id, serviceData) => {
    const url = `/admin/service/${id}`;

    try {
        const { data } = await put(url, serviceData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};
