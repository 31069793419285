import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { accountRoles } from "../../../../configs/accountRoles";
import { compare } from "../../../../helpers/compare";
import book from "../../../../router/routerBook";

import "./Account.scss";

const Account = ({
    _id,
    role,
    firstName,
    lastName,
    email,
    city,
    onEdit,
    onDelete,
    onDeleteHard,
    onBlock,
    onRestore,
    isBanned = false,
    isDeleted = false,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <tr className="account">
            <td>
                {role && t(compare(accountRoles, role))}{" "}
                {isDeleted && (
                    <span className="account--deleted">{t("deleted")}</span>
                )}
                {isBanned && <span className="account--banned">Banned</span>}
            </td>
            <td>
                {firstName} {lastName}
            </td>
            <td>{email}</td>
            <td>{city}</td>
            <td className="account__services">
                <button className="account__services--edit" onClick={onEdit}>
                    {t("btn-edit")}
                </button>
                <button
                    className={`account__services${
                        isBanned ? "--unblock" : "--block"
                    }`}
                    onClick={onBlock}
                >
                    {t(isBanned ? "btn-unblock" : "btn-block")}
                </button>
                {isDeleted ? (
                    <button
                        className="account__services--restore"
                        onClick={onRestore}
                    >
                        {t("btn-restore")}
                    </button>
                ) : (
                    <button
                        className="account__services--delete"
                        onClick={onDelete}
                    >
                        {t("btn-delete")}
                    </button>
                )}
                <button
                    className="account__services--delete account__services--delete-hard"
                    onClick={onDeleteHard}
                >
                    {t("btn-delete-hard")}
                </button>
            </td>
            <td>
                <button
                    className="account__services--edit-service"
                    onClick={() => navigate(`${book.accounts}/${role}/${_id}`)}
                >
                    {t("btn-to-details")}
                </button>
            </td>
        </tr>
    );
};
export default Account;
