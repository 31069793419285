export const customerFormConfig = [
    {
        type: "input",
        label: "name",
        placeholder: "name-placeholder",
        value: "firstName",
    },
    {
        type: "input",
        label: "surname",
        placeholder: "surname-placeholder",
        value: "lastName",
    },
    {
        type: "input",
        label: "email",
        placeholder: "email-placeholder",
        value: "email",
    },
    {
        type: "phone",
        label: "phone",
        placeholderPhoneNumber: "phone-placeholder",
        placeholderDialCode: "+90",
        phoneValue: "phone.number",
        dialCodeValue: "phone.dialCode",
    },
];
