let BASE_URL;

switch (process.env.REACT_APP_NODE_ENV) {
    case "development":
        BASE_URL = process.env.REACT_APP_DEV_URL;
        break;
    case "preprod":
        BASE_URL = process.env.REACT_APP_PREPROD_URL;
        break;
    case "production":
        BASE_URL = process.env.REACT_APP_PROD_URL;
        break;
    default:
        BASE_URL = process.env.REACT_APP_PROD_URL;
        break;
}

export { BASE_URL };
