import * as myAccountTypes from "../types/myAccountTypes";

// get user
export const getUserRequest = () => ({
    type: myAccountTypes.GET_USER_REQUEST,
});

export const getUserSuccess = (data) => ({
    type: myAccountTypes.GET_USER_SUCCESS,
    payload: data,
});

export const getUserError = (error) => ({
    type: myAccountTypes.GET_USER_ERROR,
    payload: error,
});

export const clearAccountData = () => ({
    type: myAccountTypes.CLEAR_ACCOUNT_DATA,
});
