import * as yup from "yup";
import { t } from "i18next";

export const blogSchema = yup.object().shape({
    category: yup.string().required("required"),
    image: yup.mixed().required("required"),
    title: yup.object().shape({
        en: yup
            .string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        ru: yup
            .string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        tr: yup
            .string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
    }),
    text: yup.object().shape({
        en: yup
            .string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        ru: yup
            .string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        tr: yup
            .string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
    }),
});
