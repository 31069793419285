import * as requests from "../../services/requests/tags";
import * as tagsActions from "../actions/tagsActions";
import { allTagsSelector } from "../selectors/tagsSelectors";
import { setToastifyStatus } from "../actions/toastifyActions";
import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";
import { closeModalActionCreator } from "../actions/modalActions";

// get category
export const getAllTags = (sizeTagsOnPage) => (dispatch) => {
    dispatch(tagsActions.getAllTagsRequest());

    return requests
        .getAllTagsRequest(`?limit=${sizeTagsOnPage}`)
        .then(({ items, totalCount }) => {
            dispatch(tagsActions.getAllTagsSuccess({ items, totalCount }));
        })
        .catch(({ error }) => {
            dispatch(tagsActions.getAllTagsError(error));
        });
};

// post tag
export const postTag = (data) => (dispatch, getState) => {
    dispatch(tagsActions.postTagRequest());
    const { items } = allTagsSelector(getState());

    return requests
        .postTagRequest(data)
        .then((item) => {
            const newItems = [...items, item];
            dispatch(tagsActions.postTagSuccess({ items: newItems }));
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.POST_TAG,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(tagsActions.postTagError(error));
        });
};

//delete tag
export const deleteTag = (id) => (dispatch, getState) => {
    dispatch(tagsActions.deleteTagRequest());
    const { items } = allTagsSelector(getState());

    return requests
        .deleteTagRequest(id)
        .then(() => {
            const newItems = items.filter(({ _id }) => _id !== id);
            dispatch(tagsActions.deleteTagSuccess({ items: newItems }));
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_TAG,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(tagsActions.deleteTagError(error));
        });
};
//put tag

export const putTag = (data, id) => (dispatch, getState) => {
    dispatch(tagsActions.putTagRequest());
    const { items } = allTagsSelector(getState());

    return requests
        .putTagRequest({ name: data.name }, id)
        .then(() => {
            const newItems = items.map((body) =>
                body._id === id ? data : body
            );
            dispatch(tagsActions.putTagSuccess({ items: newItems }));

            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PUT_TAG,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(tagsActions.putTagError(error));
        });
};
