import { useParams } from "react-router-dom";
import { complaintsConfig } from "./config";

import "./index.scss";

const Complaints = () => {
    const { types } = useParams();
    return (
        <div className="complaints-container">{complaintsConfig[types]}</div>
    );
};

export default Complaints;
