import * as requests from "../../services/requests/account";

import {
    accountSelector,
    allAccountParamsSelector,
    allAccountSelector,
} from "../selectors/accountSelectors";
import { changeGlobalLoader } from "../../components/Loader/loader.actions";
import { closeModalActionCreator } from "../actions/modalActions";
import { setToastifyStatus } from "../actions/toastifyActions";
import * as accountActions from "../actions/accountActions";
import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";

// get all account
export const getAllAccount =
    (size, params = "") =>
    (dispatch, getState) => {
        const { text } = allAccountParamsSelector(getState());
        dispatch(accountActions.getAllAccountRequest());
        dispatch(changeGlobalLoader({ status: true }));

        return requests
            .getAllAccountRequest(`?limit=${size}` + text + params)
            .then(({ items, totalCount }) => {
                dispatch(
                    accountActions.getAllAccountSuccess({
                        items,
                        count: totalCount,
                    })
                );
                dispatch(changeGlobalLoader({ status: false }));
            })
            .catch(({ error }) =>
                dispatch(accountActions.getAllAccountError(error))
            )
            .finally(() => dispatch(changeGlobalLoader({ status: false })));
    };

// get account
export const getAccount = (id, role) => (dispatch) => {
    dispatch(accountActions.getAccountRequest());

    return requests
        .getAccountRequest(id, role)
        .then((item) => dispatch(accountActions.getAccountSuccess(item)))
        .catch(({ error }) => dispatch(accountActions.getAccountError(error)));
};

// put account
export const putAccount = (id, data) => (dispatch, getState) => {
    dispatch(accountActions.putAccountRequest());
    const {
        allAccount: { items, count },
    } = allAccountSelector(getState());

    return requests
        .putAccountRequest(id, data)
        .then(() => {
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PUT_USER,
                    type: "success",
                })
            );
            const newItems = items.map((body) =>
                body._id === id ? { ...body, ...data } : body
            );
            const newItem = items.find(({ _id }) => _id === id);

            const updateItem = {
                ...newItem,
                data,
            };

            dispatch(
                accountActions.getAllAccountSuccess({ items: newItems, count })
            );
            dispatch(accountActions.putAccountSuccess(updateItem));
            dispatch(closeModalActionCreator());
        })
        .catch(({ error }) => dispatch(accountActions.putAccountError(error)));
};

// delete account avatar
export const deleteAccountAvatar = (id) => (dispatch, getState) => {
    dispatch(accountActions.deleteAccountAvatarRequest());
    const account = accountSelector(getState());

    return requests
        .deleteAccountAvatarRequest(id)
        .then(() => {
            const newItem = {
                ...account,
                avatar: null,
            };
            dispatch(accountActions.deleteAccountAvatarSuccess(newItem));
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_USER_AVATAR,
                    type: "success",
                })
            );
        })
        .catch(({ error }) =>
            dispatch(accountActions.deleteAccountAvatarError(error))
        );
};

// block account
export const blockAccount = (id, isBanned) => (dispatch, getState) => {
    dispatch(accountActions.blockAccountRequest());
    const {
        allAccount: { items, count },
    } = allAccountSelector(getState());

    let newStatusIsBanned;
    if (isBanned) {
        newStatusIsBanned = false;
    } else {
        newStatusIsBanned = true;
    }

    return requests
        .putBlockAccountRequest(id, { isBanned: newStatusIsBanned })
        .then(() => {
            dispatch(
                setToastifyStatus({
                    title: newStatusIsBanned
                        ? toastifySuccessMessages.BLOCKED_USER
                        : toastifySuccessMessages.UNBLOCKED_USER,
                    type: "success",
                })
            );
            const item = items.find(({ _id }) => _id === id);
            const updateItem = {
                ...item,
                isBanned: newStatusIsBanned,
            };
            const newItems = items.map((body) =>
                body._id === id ? updateItem : body
            );
            dispatch(
                accountActions.blockAccountSuccess({ items: newItems, count })
            );
        })
        .catch(({ error }) =>
            dispatch(accountActions.blockAccountError(error))
        );
};

// delete account
export const deleteAccount = (id) => (dispatch, getState) => {
    dispatch(accountActions.deleteAccountRequest());
    const {
        allAccount: { items, count },
    } = allAccountSelector(getState());

    return requests
        .deleteAccountRequest(id)
        .then(() => {
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_USER,
                    type: "success",
                })
            );

            const newItems = items.map((item) => {
                if (item._id === id) {
                    item.isDeleted = true;
                }

                return item;
            });

            dispatch(
                accountActions.deleteAccountSuccess({ items: newItems, count })
            );
            dispatch(closeModalActionCreator());
        })
        .catch(({ error }) =>
            dispatch(accountActions.deleteAccountError(error))
        );
};

// delete account hard
export const deleteAccountHard = (id) => (dispatch, getState) => {
    dispatch(accountActions.deleteAccountHardRequest());

    const {
        allAccount: { items, count },
    } = allAccountSelector(getState());

    return requests
        .deleteAccountHardRequest(id)
        .then(() => {
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_USER,
                    type: "success",
                })
            );

            const newItems = items.filter((item) => item._id !== id);

            dispatch(
                accountActions.deleteAccountHardSuccess({
                    items: newItems,
                    count,
                })
            );
            dispatch(closeModalActionCreator());
        })
        .catch(({ error }) =>
            dispatch(accountActions.deleteAccountHardError(error))
        );
};

// post new user
export const postNewUser = (data) => (dispatch, getState) => {
    const { allAccount } = allAccountSelector(getState());
    dispatch(accountActions.postNewUserRequest);

    return requests
        .postNewUserRequest(data)
        .then((item) => {
            dispatch(
                accountActions.postNewUserSuccess({
                    items: [...allAccount.items, item],
                    count: allAccount.count + 1,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.ADD_NEW_USER,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => dispatch(accountActions.postNewUserError(error)));
};

// set
export const editPageAll = (size, data) => (dispatch) => {
    dispatch(accountActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getAllAccount(size, url));
};

export const editSearchText = (size, data) => (dispatch) => {
    dispatch(accountActions.setPage("&page=0"));
    dispatch(accountActions.setText(`&text=${data}`));

    dispatch(getAllAccount(size, "&page=0"));
};
