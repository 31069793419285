import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import React from "react";

import { openModalActionCreator } from "../../../../redux/actions/modalActions";
import {
    deleteFeedback,
    putFeedback,
} from "../../../../redux/operations/feedbackOperations";

import "./index.scss";

const SpecialistFeedbacksItem = ({
    _id: id,
    text,
    assessment,
    reporter,
    isVisible,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    id,
                    title: t("feedback"),
                    action: () => dispatch(deleteFeedback(id, reporter._id)),
                },
            })
        );
    };

    const handleEdit = () => {
        dispatch(
            openModalActionCreator({
                typeModal: "EDIT_FEEDBACK",
                details: { id, assessment, reporter: reporter._id, text },
            })
        );
    };

    const handleIsVisible = () => {
        dispatch(
            putFeedback(id, reporter._id, { isVisible: !isVisible || false })
        );
    };

    return (
        <tbody className="specialist-feedbacks-item__body">
            <tr className="title">
                <td>{id}</td>
                <td>{text}</td>
                <td>{assessment}</td>
                <td className="specialist-feedbacks-item__options">
                    <button
                        className="specialist-feedbacks-item__options--edit"
                        onClick={handleEdit}
                    >
                        {t("btn-edit")}
                    </button>
                    <button
                        className={`specialist-feedbacks-item__options${
                            isVisible ? "--visible" : "--unvisible"
                        }`}
                        onClick={handleIsVisible}
                    >
                        {t(isVisible ? "btn-hide" : "btn-show")}
                    </button>
                    <button
                        className="specialist-feedbacks-item__options--delete"
                        onClick={handleDelete}
                    >
                        {t("btn-delete")}
                    </button>
                </td>
            </tr>
        </tbody>
    );
};

export default SpecialistFeedbacksItem;
