// get all account
export const GET_ALL_ACCOUNT_REQUEST = "account/GET_ALL_ACCOUNT_REQUEST";
export const GET_ALL_ACCOUNT_SUCCESS = "account/GET_ALL_ACCOUNT_SUCCESS";
export const GET_ALL_ACCOUNT_ERROR = "account/GET_ALL_ACCOUNT_ERROR";

// get account
export const GET_ACCOUNT_REQUEST = "account/GET_ACCOUNT_REQUEST";
export const GET_ACCOUNT_SUCCESS = "account/GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_ERROR = "account/GET_ACCOUNT_ERROR";

// put account
export const PUT_ACCOUNT_REQUEST = "account/PUT_ACCOUNT_REQUEST";
export const PUT_ACCOUNT_SUCCESS = "account/PUT_ACCOUNT_SUCCESS";
export const PUT_ACCOUNT_ERROR = "account/PUT_ACCOUNT_ERROR";

// block account
export const BLOCK_ACCOUNT_REQUEST = "account/BLOCK_ACCOUNT_REQUEST";
export const BLOCK_ACCOUNT_SUCCESS = "account/BLOCK_ACCOUNT_SUCCESS";
export const BLOCK_ACCOUNT_ERROR = "account/BLOCK_ACCOUNT_ERROR";

// delete account
export const DELETE_ACCOUNT_REQUEST = "account/DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "account/DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_ERROR = "account/DELETE_ACCOUNT_ERROR";

// delete account hard
export const DELETE_ACCOUNT_HARD_REQUEST =
    "account/DELETE_ACCOUNT_HARD_REQUEST";
export const DELETE_ACCOUNT_HARD_SUCCESS =
    "account/DELETE_ACCOUNT_HARD_SUCCESS";
export const DELETE_ACCOUNT_HARD_ERROR = "account/DELETE_ACCOUNT_HARD_ERROR";

// delete account avatar
export const DELETE_ACCOUNT_AVATAR_REQUEST =
    "account/DELETE_ACCOUNT_AVATAR_REQUEST";
export const DELETE_ACCOUNT_AVATAR_SUCCESS =
    "account/DELETE_ACCOUNT_AVATAR_SUCCESS";
export const DELETE_ACCOUNT_AVATAR_ERROR =
    "account/DELETE_ACCOUNT_AVATAR_ERROR";

// post new user
export const POST_NEW_USER_REQUEST = "account/POST_NEW_USER_REQUEST";
export const POST_NEW_USER_SUCCESS = "account/POST_NEW_USER_SUCCESS";
export const POST_NEW_USER_ERROR = "account/POST_NEW_USER_ERROR";

//set
export const CLEAR_ALL_PARAMS = "account/CLEAR_ALL_PARAMS";
export const SET_TEXT = "account/SET_TEXT";
export const SET_PAGE = "account/SET_PAGE";
