// get services
import * as servicesTypes from "../types/servicesTypes";

export const getSpecialistServicesRequest = () => ({
    type: servicesTypes.GET_ALL_SPECIALIST_SERVICES_REQUEST,
});

export const getSpecialistServicesSuccess = (payload) => ({
    type: servicesTypes.GET_ALL_SPECIALIST_SERVICES_SUCCESS,
    payload,
});

export const getSpecialistServicesError = (payload) => ({
    type: servicesTypes.GET_ALL_SPECIALIST_SERVICES_ERROR,
    payload,
});

// put service
export const putSpecialistServiceRequest = () => ({
    type: servicesTypes.PUT_SPECIALIST_SERVICE_REQUEST,
});

export const putSpecialistServiceSuccess = (payload) => ({
    type: servicesTypes.PUT_SPECIALIST_SERVICE_SUCCESS,
    payload,
});

export const putSpecialistServiceError = (payload) => ({
    type: servicesTypes.PUT_SPECIALIST_SERVICE_ERROR,
    payload,
});
