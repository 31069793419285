import SupportTable from "../../components/Tables/Support/Support";
import "./Support.scss";

const Support = () => {
    return (
        <div className="support-container">
            <SupportTable />
        </div>
    );
};

export default Support;
