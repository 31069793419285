export const accountRoles = [
    { text: "customer", key: "CUSTOMER" },
    { text: "specialist", key: "SPECIALIST" },
];

export const adminRoles = {
    ADMIN: {
        text: "admin",
        key: "ADMIN",
        requstName: "admin",
    },
    MODERATOR: {
        text: "moderator",
        key: "MODERATOR",
        requstName: "moderator",
    },
    CONTENT_WRITER: {
        text: "content-writer",
        key: "CONTENT_WRITER",
        requstName: "content-writer",
    },
};
