import Subcategories from "../../components/Tables/Subcategories/Subcategories";
import Clarifications from "../../components/Tables/Clarifications/Clarifications";
import Category from "../../components/Tables/Category/Category";

import "./ServicesPage.scss";
import Tags from "../../components/Tables/Tags/Tags";

const ServicesPage = () => {
    return (
        <div className="services-container">
            <Category />
            <div className="services-container__content">
                <Subcategories />
                <Clarifications />
            </div>
            <Tags />
        </div>
    );
};

export default ServicesPage;
