import io from "socket.io-client";
import { store } from "../redux/store/store";
import { BASE_URL } from "../services/api/apiConfig";

export const socket = () => {
    const token = store.getState().auth.info.token;

    if (token)
        return io(`${BASE_URL}?token=${token}`, {
            transports: ["websocket", "polling"],
            reconnection: true,
            forceNew: true,
            secure: true,
        });
    return io(BASE_URL);
};
