import * as requests from "../../services/requests/auth";

import { setToastifyStatus } from "../actions/toastifyActions";
import * as authActions from "../actions/authActions";

import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";
import routerBook from "../../router/routerBook";
import { getUser } from "./myAccountOperations";

export const sendLogin = (data, navigate) => (dispatch) => {
    dispatch(authActions.sendLoginRequest());

    const deviceId = localStorage.getItem("deviceID");

    return requests
        .sendLoginRequest({ ...data, deviceId })
        .then((response) => {
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.WELCOME,
                    type: "success",
                })
            );
            dispatch(getUser());
            dispatch(authActions.sendLoginSuccess(response));
            navigate(routerBook.accounts);
        })
        .catch(({ error }) => dispatch(authActions.sendLoginError(error)));
};
