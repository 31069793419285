import * as yup from "yup";
import { t } from "i18next";

export const ComplaintFormSchema = yup.object().shape({
    text: yup.object().shape({
        en: yup
            .string()
            .trim()
            .required("required")
            .min(5, ({ min }) => t("validation-min", { min })),
        ru: yup
            .string()
            .trim()
            .required("required")
            .min(5, ({ min }) => t("validation-min", { min })),
        tr: yup
            .string()
            .trim()
            .required("required")
            .min(5, ({ min }) => t("validation-min", { min })),
    }),
});
