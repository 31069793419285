import { useParams } from "react-router-dom";
import React from "react";

import SpecialistFeedbacks from "./SpecialistFeedbacks/SpecialistFeedbacks";
import SpecialistServices from "./SpecialistServices/SpecialistServices";
import UserComplaintsItem from "./UserComplaints/UserComplaintsItem/UserComplaintsItem";
import { accountRoles } from "../../configs/accountRoles";

import "./index.scss";

const AccountsDetails = () => {
    const { role } = useParams();

    return (
        <div className="account-details">
            <UserComplaintsItem />
            {role === accountRoles[1].key && <SpecialistServices />}
            {role === accountRoles[1].key && <SpecialistFeedbacks />}
        </div>
    );
};

export default AccountsDetails;
