import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import { editSearchText } from "../../../../redux/operations/accountOperations";
import { allAccountParamsSelector } from "../../../../redux/selectors/accountSelectors";

import { sizeItemOnPage } from "../accounts.config";

import "./AccountsSearch.scss";

const AccountsSearch = () => {
    const { text } = useSelector(allAccountParamsSelector);
    const [query, setQuery] = useState("");
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSearch = () =>
        dispatch(editSearchText(sizeItemOnPage, query.trim()));

    return (
        <div className="accounts-search">
            <input
                onKeyPress={({ key }) => key === "Enter" && handleSearch()}
                onChange={({ target: { value } }) => setQuery(value)}
                className="accounts-search__input"
                placeholder={t("search")}
                value={query}
            ></input>
            <button
                disabled={text.split("=")[1] === query}
                className="accounts-search__btn"
                onClick={handleSearch}
            >
                {t("btn-search")}
            </button>
        </div>
    );
};

export default AccountsSearch;
