// get tags
export const GET_ALL_TAGS_REQUEST = "tags/GET_ALL_TAGS_REQUEST";
export const GET_ALL_TAGS_SUCCESS = "tags/GET_ALL_TAGS_SUCCESS";
export const GET_ALL_TAGS_ERROR = "tags/GET_ALL_TAGS_ERROR";

// post tag
export const POST_TAG_REQUEST = "tags/POST_TAG_REQUEST";
export const POST_TAG_SUCCESS = "tags/POST_TAG_SUCCESS";
export const POST_TAG_ERROR = "tags/POST_TAG_ERROR";

// put tag
export const PUT_TAG_REQUEST = "tags/PUT_TAG_REQUEST";
export const PUT_TAG_SUCCESS = "tags/PUT_TAG_SUCCESS";
export const PUT_TAG_ERROR = "tags/POST_TAG_ERROR";

// delete tag
export const DELETE_TAG_REQUEST = "tags/DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "tags/DELETE_TAG_SUCCESS";
export const DELETE_TAG_ERROR = "tags/DELETE_TAG_ERROR";
