import { useTranslation } from "react-i18next";
import React from "react";

import "./index.scss";

const Subcategory = ({ tags = [], remark, category, onEdit, onDelete }) => {
    const { t } = useTranslation();

    return (
        <tr className="title">
            <td>{remark}</td>
            <td>{category}</td>
            {tags.length ? (
                <td>
                    {tags.map(({ name, _id }) => (
                        <div className="subcategory_tag" key={_id}>
                            {name}
                        </div>
                    ))}
                </td>
            ) : (
                <td> - </td>
            )}
            <td className="subcategory__options">
                <button className="subcategory__options--edit" onClick={onEdit}>
                    {t("btn-edit")}
                </button>
                <button
                    className="subcategory__options--delete"
                    onClick={onDelete}
                >
                    {t("btn-delete")}
                </button>
            </td>
        </tr>
    );
};
export default Subcategory;
