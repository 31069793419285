import * as categoryActions from "../types/categoryTypes";

// get category
export const getAllCategoryRequest = () => ({
    type: categoryActions.GET_ALL_CATEGORY_REQUEST,
});

export const getAllCategorySuccess = (payload) => ({
    type: categoryActions.GET_ALL_CATEGORY_SUCCESS,
    payload,
});

export const getAllCategoryError = (payload) => ({
    type: categoryActions.GET_ALL_CATEGORY_ERROR,
    payload,
});
// get  all category without limit
export const getAllCategoryWithoutLimitRequest = () => ({
    type: categoryActions.GET_ALL_CATEGORY_WITHOUT_LIMIT_REQUEST,
});

export const getAllCategoryWithoutLimitSuccess = (payload) => ({
    type: categoryActions.GET_ALL_CATEGORY_WITHOUT_LIMIT_SUCCESS,
    payload,
});

export const getAllCategoryWithoutLimitError = (payload) => ({
    type: categoryActions.GET_ALL_CATEGORY_WITHOUT_LIMIT_ERROR,
    payload,
});

// post category
export const postCategoryRequest = () => ({
    type: categoryActions.POST_CATEGORY_REQUEST,
});

export const postCategorySuccess = (payload) => ({
    type: categoryActions.POST_CATEGORY_SUCCESS,
    payload,
});

export const postCategoryError = (payload) => ({
    type: categoryActions.POST_CATEGORY_ERROR,
    payload,
});

// put category
export const putCategoryRequest = () => ({
    type: categoryActions.PUT_CATEGORY_REQUEST,
});

export const putCategorySuccess = (payload) => ({
    type: categoryActions.PUT_CATEGORY_SUCCESS,
    payload,
});

export const putCategoryError = (payload) => ({
    type: categoryActions.PUT_CATEGORY_ERROR,
    payload,
});

// delete category
export const deleteCategoryRequest = () => ({
    type: categoryActions.DELETE_CATEGORY_REQUEST,
});

export const deleteCategorySuccess = (payload) => ({
    type: categoryActions.DELETE_CATEGORY_SUCCESS,
    payload,
});

export const deleteCategoryError = (payload) => ({
    type: categoryActions.DELETE_CATEGORY_ERROR,
    payload,
});

// get subcategory
export const getAllSubcategoryRequest = () => ({
    type: categoryActions.GET_ALL_SUBCATEGORY_REQUEST,
});

export const getAllSubcategorySuccess = (payload) => ({
    type: categoryActions.GET_ALL_SUBCATEGORY_SUCCESS,
    payload,
});

export const getAllSubcategoryError = (payload) => ({
    type: categoryActions.GET_ALL_SUBCATEGORY_ERROR,
    payload,
});
// get all  subcategory without limit
export const getAllSubcategoryWithoutLimitRequest = () => ({
    type: categoryActions.GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_REQUEST,
});

export const getAllSubcategoryWithoutLimitSuccess = (payload) => ({
    type: categoryActions.GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_SUCCESS,
    payload,
});

export const getAllSubcategoryWithoutLimitError = (payload) => ({
    type: categoryActions.GET_ALL_SUBCATEGORY_WITHOUT_LIMIT_ERROR,
    payload,
});

// post subcategory
export const postSubcategoryRequest = () => ({
    type: categoryActions.POST_SUBCATEGORY_REQUEST,
});

export const postSubcategorySuccess = (payload) => ({
    type: categoryActions.POST_SUBCATEGORY_SUCCESS,
    payload,
});

export const postSubcategoryError = (payload) => ({
    type: categoryActions.POST_SUBCATEGORY_ERROR,
    payload,
});

// put subcategory
export const putSubcategoryRequest = () => ({
    type: categoryActions.PUT_SUBCATEGORY_REQUEST,
});

export const putSubcategorySuccess = (payload) => ({
    type: categoryActions.PUT_SUBCATEGORY_SUCCESS,
    payload,
});

export const putSubcategoryError = (payload) => ({
    type: categoryActions.PUT_SUBCATEGORY_ERROR,
    payload,
});

// delete subcategory
export const deleteSubcategoryRequest = () => ({
    type: categoryActions.DELETE_SUBCATEGORY_REQUEST,
});

export const deleteSubcategorySuccess = (payload) => ({
    type: categoryActions.DELETE_SUBCATEGORY_SUCCESS,
    payload,
});

export const deleteSubcategoryError = (payload) => ({
    type: categoryActions.DELETE_SUBCATEGORY_ERROR,
    payload,
});

// get clarification
export const getAllClarificationRequest = () => ({
    type: categoryActions.GET_ALL_CLARIFICATION_REQUEST,
});

export const getAllClarificationSuccess = (payload) => ({
    type: categoryActions.GET_ALL_CLARIFICATION_SUCCESS,
    payload,
});

export const getAllClarificationError = (payload) => ({
    type: categoryActions.GET_ALL_CLARIFICATION_ERROR,
    payload,
});

// post clarification
export const postClarificationRequest = () => ({
    type: categoryActions.POST_CLARIFICATION_REQUEST,
});

export const postClarificationSuccess = (payload) => ({
    type: categoryActions.POST_CLARIFICATION_SUCCESS,
    payload,
});

export const postClarificationError = (payload) => ({
    type: categoryActions.POST_CLARIFICATION_ERROR,
    payload,
});

// put clarification
export const putClarificationRequest = () => ({
    type: categoryActions.PUT_CLARIFICATION_REQUEST,
});

export const putClarificationSuccess = (payload) => ({
    type: categoryActions.PUT_CLARIFICATION_SUCCESS,
    payload,
});

export const putClarificationError = (payload) => ({
    type: categoryActions.PUT_CLARIFICATION_ERROR,
    payload,
});

// delete clarification
export const deleteClarificationRequest = () => ({
    type: categoryActions.DELETE_CLARIFICATION_REQUEST,
});

export const deleteClarificationSuccess = (payload) => ({
    type: categoryActions.DELETE_CLARIFICATION_SUCCESS,
    payload,
});

export const deleteClarificationError = (payload) => ({
    type: categoryActions.DELETE_CLARIFICATION_ERROR,
    payload,
});

//set
export const setCategoryPage = (data) => ({
    type: categoryActions.SET_CATEGORY_PAGE,
    payload: data,
});

export const setSubcategoryPage = (data) => ({
    type: categoryActions.SET_SUBCATEGORY_PAGE,
    payload: data,
});

export const setClarificationPage = (data) => ({
    type: categoryActions.SET_CLARIFICATION_PAGE,
    payload: data,
});

export const clearAllParams = () => ({
    type: categoryActions.CLEAR_ALL_PARAMS,
});
