//  complaints Types
export const GET_ALL_COMPLAINT_TYPE_REQUEST =
    "complaint/GET_ALL_COMPLAINT_TYPE_REQUEST";
export const GET_ALL_COMPLAINT_TYPE_SUCCESS =
    "complaint/GET_ALL_COMPLAINT_TYPE_SUCCESS";
export const GET_ALL_COMPLAINT_TYPE_ERROR =
    "complaint/GET_ALL_COMPLAINT_TYPE_ERROR";

export const DELETE_COMPLAINT_TYPE_REQUEST =
    "complaint/DELETE_COMPLAINT_TYPE_REQUEST";
export const DELETE_COMPLAINT_TYPE_SUCCESS =
    "complaint/DELETE_COMPLAINT_TYPE_SUCCESS";
export const DELETE_COMPLAINT_TYPE_ERROR =
    "complaint/DELETE_COMPLAINT_TYPE_ERROR";

export const POST_COMPLAINT_TYPE_REQUEST =
    "complaint/POST_COMPLAINT_TYPE_REQUEST";
export const POST_COMPLAINT_TYPE_SUCCESS =
    "complaint/POST_COMPLAINT_TYPE_SUCCESS";
export const POST_COMPLAINT_TYPE_ERROR = "complaint/POST_COMPLAINT_TYPE_ERROR";

export const PUT_COMPLAINT_TYPE_REQUEST =
    "complaint/PUT_COMPLAINT_TYPE_REQUEST";
export const PUT_COMPLAINT_TYPE_SUCCESS =
    "complaint/PUT_COMPLAINT_TYPE_SUCCESS";
export const PUT_COMPLAINT_TYPE_ERROR = "complaint/PUT_COMPLAINT_TYPE_ERROR";

//  complaints Users
export const GET_ALL_COMPLAINT_USER_REQUEST =
    "complaint/GET_ALL_COMPLAINT_USER_REQUEST";
export const GET_ALL_COMPLAINT_USER_SUCCESS =
    "complaint/GET_ALL_COMPLAINT_USER_SUCCESS";
export const GET_ALL_COMPLAINT_USER_ERROR =
    "complaint/GET_ALL_COMPLAINT_USER_ERROR";

export const DELETE_COMPLAINT_USER_REQUEST =
    "complaint/DELETE_COMPLAINT_USER_REQUEST";
export const DELETE_COMPLAINT_USER_SUCCESS =
    "complaint/DELETE_COMPLAINT_USER_SUCCESS";
export const DELETE_COMPLAINT_USER_ERROR =
    "complaint/DELETE_COMPLAINT_USER_ERROR";

//  complaints user
export const GET_ALL_COMPLAINTS_USER_REQUEST =
    "complaint/GET_ALL_COMPLAINTS_USER_REQUEST";
export const GET_ALL_COMPLAINTS_USER_SUCCESS =
    "complaint/GET_ALL_COMPLAINTS_USER_SUCCESS";
export const GET_ALL_COMPLAINTS_USER_ERROR =
    "complaint/GET_ALL_COMPLAINTS_USER_ERROR";

export const DELETE_COMPLAINTS_USER_REQUEST =
    "complaint/DELETE_COMPLAINTS_USER_REQUEST";
export const DELETE_COMPLAINTS_USER_SUCCESS =
    "complaint/DELETE_COMPLAINTS_USER_SUCCESS";
export const DELETE_COMPLAINTS_USER_ERROR =
    "complaint/DELETE_COMPLAINTS_USER_ERROR";

//set
export const SET_PAGE = "complaint/SET_PAGE";
export const SET_TEXT = "complaint/SET_TEXT";
export const CLEAR_ALL_PARAMS = "complaint/CLEAR_ALL_PARAMS";
