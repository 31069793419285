import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Draggable from "react-draggable";

import "./Document.scss";

const Document = ({ photo }) => {
    const [image, setImage] = useState({ scale: 1, version: 0, rotate: 0 });
    const { t } = useTranslation();

    const zoomIn = () =>
        setImage(() => ({
            ...image,
            scale: image.scale + 0.5 <= 2 ? image.scale + 0.5 : 2,
        }));
    const zoomOut = () =>
        setImage(() => ({
            ...image,
            scale: image.scale - 0.5 >= 1 ? image.scale - 0.5 : 1,
        }));
    const rotate = () =>
        setImage(() => ({
            ...image,
            rotate: image.rotate === 270 ? 0 : image.rotate + 90,
        }));

    const reset = () =>
        setImage(() => ({
            scale: 1,
            rotate: 0,
            version: image.version + 1,
        }));

    const zoom = () => (image.scale === 2 ? reset() : zoomIn());

    return (
        <div className="document">
            <div className="document__service">
                <button className="document__service--zoomin" onClick={zoomIn}>
                    + {t("zoom")}: {image.scale === 3 ? "max" : image.scale}
                </button>
                <button
                    className="document__service--zoomout"
                    onClick={zoomOut}
                >
                    - {t("zoom")}
                </button>
                <button className="document__service--reset" onClick={reset}>
                    {t("reset")}
                </button>
                <button className="document__service--rotate" onClick={rotate}>
                    {t("rotate")}: {image.rotate}
                </button>
            </div>
            <div className="document__content">
                <Draggable disabled={false} key={image.version}>
                    <div style={{ cursor: "zoom-in" }}>
                        <img
                            src={photo}
                            alt="Document"
                            draggable="false"
                            onDoubleClick={zoom}
                            className="document__photo"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                transform: `rotate(${image.rotate}deg)`,
                                zoom: image.scale,
                            }}
                        />
                    </div>
                </Draggable>
            </div>
        </div>
    );
};

export default Document;
