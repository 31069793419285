import { post, get, put, destroy, formDataHeaders } from "../api";

export const getAllCategoryRequest = async (params = "") => {
    const url = "/category" + params;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const postCategoryRequest = async (reqData = {}) => {
    const url = "/category";

    try {
        const { data } = await post(url, reqData, formDataHeaders);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const putCategoryRequest = async (reqData = {}, id) => {
    const url = `/category/${id}`;

    try {
        const { data } = await put(url, reqData, formDataHeaders);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const deleteCategoryRequest = async (id) => {
    const url = `/category/${id}`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const getAllSubcategoryRequest = async (params = "") => {
    const url = "/category/subcategory" + params;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const postSubcategoryRequest = async (reqData = {}) => {
    const url = "/category/subcategory";

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const putSubcategoryRequest = async (reqData = {}, id) => {
    const url = `/category/subcategory/${id}`;

    try {
        const { data } = await put(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const deleteSubcategoryRequest = async (id) => {
    const url = `/category/subcategory/${id}`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const getAllClarificationRequest = async (params = "") => {
    const url = "/category/clarification" + params;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const postClarificationRequest = async (reqData = {}) => {
    const url = "/category/clarification";

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const putClarificationRequest = async (reqData = {}, id) => {
    const url = `/category/clarification/${id}`;

    try {
        const { data } = await put(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const deleteClarificationRequest = async (id) => {
    const url = `/category/clarification/${id}`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};
