import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import { getLanguage } from "../helpers/language";
import translationRu from "./ru/translation";
import translationTr from "./tr/translation";
import translationEn from "./en/translation";

const resources = {
    ru: { translation: translationRu },
    tr: { translation: translationTr },
    en: { translation: translationEn },
};

const initializelanguage = getLanguage();

i18n.use(initReactI18next).init({
    resources,
    lng: initializelanguage.value,
    interpolation: { escapeValue: false },
});

export default i18n;
