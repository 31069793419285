import { t } from "i18next";
import { string, object, number, ref, array } from "yup";

export const SpecialistServiceFormSchema = object().shape({
    schedule: array().required("required"),
    district: string().required("required"),
    city: string().required("required"),
    address: string()
        .required("required")
        .min(3, ({ min }) => t("validation-min", { min }))
        .max(50, ({ max }) => t("validation-max", { max })),
    priceFrom: number("validation-positive-number")
        .required("required")
        .typeError("validation-positive-number")
        .positive("validation-positive-number"),
    priceTo: number("validation-positive-number")
        .required("required")
        .typeError("validation-positive-number")
        .positive("validation-positive-number")
        .min(ref("priceFrom"), ({ min }) => t("validation-min", { min })),
    comment: string()
        .required("required")
        .min(10, ({ min }) => t("validation-min", { min }))
        .max(500, ({ max }) => t("validation-max", { max })),
});
