import { t } from "i18next";
import PropTypes from "prop-types";
import { forwardRef, useState } from "react";

import { Icon } from "../Image/Image";
import "./index.scss";

const PhotoUploader = forwardRef(
    ({ onChange, defaultValue, type = "circle", error = "" }, ref) => {
        const checkDefaultImage = (image) => {
            if (image) {
                if (typeof image === "object") {
                    return URL.createObjectURL(image);
                } else {
                    return image;
                }
            } else return null;
        };

        const [image, setImage] = useState(checkDefaultImage(defaultValue));

        const handleLoadLocalFile = (event) => {
            event.preventDefault();
            const reader = new FileReader();
            const file = event.target.files[0];

            if (file) {
                reader.onloadend = () => onChange(file);
                reader.readAsDataURL(file);
                setImage(URL.createObjectURL(file));
            } else {
                alert("File uploaded is not valid.");
            }
        };

        return (
            <section className="upload_photo" ref={ref}>
                <div className="upload_photo__inner">
                    <div className="upload_photo__initial">
                        <div className={`upload_photo__initial_circle ${type}`}>
                            {image ? (
                                <img
                                    className={`upload_photo__initial_image ${type}`}
                                    alt=""
                                    src={image}
                                />
                            ) : (
                                <div className="upload_photo__initial_undownload">
                                    <Icon type="undownload" />
                                </div>
                            )}
                            <div className="upload_photo__initial_plus">
                                <Icon type="addPhotoCircle" />
                            </div>
                            <input
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={handleLoadLocalFile}
                                className="upload_photo__initial_input"
                            ></input>
                        </div>
                    </div>
                </div>
                {error && (
                    <span className="upload_photo_error">{t(error)}</span>
                )}
            </section>
        );
    }
);
PhotoUploader.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
};

PhotoUploader.displayName = "PhotoUploader";
export default PhotoUploader;
