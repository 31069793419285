import {
    OPEN_MODAL,
    CLOSE_MODAL,
    CLEAR_MODAL_STATE,
} from "../types/modalTypes";

const initialState = { typeModal: "", isOpen: false, details: null };

export const modalReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case OPEN_MODAL: {
            return { ...state, ...payload, isOpen: true };
        }
        case CLEAR_MODAL_STATE:
        case CLOSE_MODAL: {
            return { ...initialState };
        }

        default:
            return state;
    }
};
