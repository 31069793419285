import * as adminTypes from "../types/adminTypes";

import { sizeItemOnPage } from "../../components/Tables/Admins/Admins.config";

const initialParams = {
    limit: sizeItemOnPage,
    page: "&page=0",
    role: "",
    text: "",
};

const initialState = {
    adminList: { items: [], count: 0 },
    params: initialParams,
    loading: false,
    error: "",
};

export const adminReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case adminTypes.PUT_ADMIN_REQUEST:
        case adminTypes.PATCH_PERMISSIONS_REQUEST:
        case adminTypes.GET_ADMIN_LIST_REQUEST:
        case adminTypes.DELETE_ADMIN_REQUEST:
        case adminTypes.POST_ADMIN_REQUEST: {
            return { ...state, loading: true };
        }

        // data
        case adminTypes.PUT_ADMIN_SUCCESS:
        case adminTypes.PATCH_PERMISSIONS_SUCCESS:
        case adminTypes.GET_ADMIN_LIST_SUCCESS:
        case adminTypes.DELETE_ADMIN_SUCCESS:
        case adminTypes.POST_ADMIN_SUCCESS: {
            return { ...state, adminList: payload, loading: false, error: "" };
        }

        // error
        case adminTypes.PUT_ADMIN_ERROR:
        case adminTypes.PATCH_PERMISSIONS_ERROR:
        case adminTypes.GET_ADMIN_LIST_ERROR:
        case adminTypes.DELETE_ADMIN_ERROR:
        case adminTypes.POST_ADMIN_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        // set
        case adminTypes.SET_ROLE:
            return {
                ...state,
                params: { ...state.params, role: payload, page: "&page=0" },
            };
        case adminTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };
        case adminTypes.SET_TEXT:
            return { ...state, params: { ...state.params, text: payload } };
        case adminTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialState.params };

        default:
            return state;
    }
};
