import { useTranslation } from "react-i18next";
import React from "react";

import "./Clarification.scss";

const Clarification = ({ subcategory, remark, onEdit, onDelete }) => {
    const { t } = useTranslation();

    return (
        <tr className="title">
            <td>{remark}</td>
            <td>{subcategory}</td>
            <td className="clarification__options">
                <button
                    className="clarification__options--edit"
                    onClick={onEdit}
                >
                    {t("btn-edit")}
                </button>
                <button
                    className="clarification__options--delete"
                    onClick={onDelete}
                >
                    {t("btn-delete")}
                </button>
            </td>
        </tr>
    );
};
export default Clarification;
