import React from "react";

import PromotionsHistoryTable from "../../components/Tables/PromotionsHistory/PromotionsHistory";

import "./PromotionsHistory.scss";

const PromotionsHistory = () => {
    return (
        <div className="promotions-history-container">
            <PromotionsHistoryTable />
        </div>
    );
};

export default PromotionsHistory;
