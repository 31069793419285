import isObject from "./isObject";
export const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
        if (!isObject(object[key]) || key === "icon" || key === "image")
            formData.append(key, object[key]);
        else formData.append(key, JSON.stringify(object[key]));
    });
    return formData;
};
