import { t } from "i18next";

import * as requests from "../../services/requests/category";

import {
    closeModalActionCreator,
    openModalActionCreator,
} from "../actions/modalActions";
import {
    allCategorySelector,
    allSubcategorySelector,
    allClarificationSelector,
} from "../selectors/categorySelectors";
import { setToastifyStatus } from "../actions/toastifyActions";
import * as categoryActions from "../actions/categoryActions";

import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";
import { sizeSubcategoriesOnPage } from "../../components/Tables/Subcategories/subcategories.config";
import { sizeCategoriesOnPage } from "../../components/Tables/Category/category.config";
import { sizeClarificationsOnPage } from "../../components/Tables/Clarifications/clarifications.config";
import { getFormData } from "../../services/utils/getFormData";

const extractIdFromErrorMessage = (error) => {
    const index = error.indexOf(":");
    const id = error.substring(index + 1, error.length).trim();
    return id;
};

// get category
export const getAllCategory =
    (params = "") =>
    (dispatch) => {
        dispatch(categoryActions.getAllCategoryRequest());

        return requests
            .getAllCategoryRequest(`?limit=${sizeCategoriesOnPage}` + params)
            .then(({ items, totalCount: count }) => {
                dispatch(
                    categoryActions.getAllCategorySuccess({ items, count })
                );
            })
            .catch(({ error }) => {
                dispatch(categoryActions.getAllCategoryError(error));
            });
    };
// get all  category without limit
export const getAllCategoryWithoutLimit = () => (dispatch) => {
    dispatch(categoryActions.getAllCategoryWithoutLimitRequest());

    return requests
        .getAllCategoryRequest("?limit=1000")
        .then(({ items, totalCount: count }) => {
            dispatch(
                categoryActions.getAllCategoryWithoutLimitSuccess({
                    items,
                    count,
                })
            );
        })
        .catch(({ error }) => {
            dispatch(categoryActions.getAllCategoryWithoutLimitError(error));
        });
};

// post category
export const postCategory = (data) => (dispatch, getState) => {
    dispatch(categoryActions.postCategoryRequest());
    const { items, count } = allCategorySelector(getState());

    return requests
        .postCategoryRequest(getFormData(data))
        .then((item) => {
            const newItems = [...items, item];
            dispatch(
                categoryActions.postCategorySuccess({
                    items: newItems,
                    count: count + 1,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.POST_CATEGORY,
                    type: "success",
                })
            );
        })
        .catch(({ errorData, status }) => {
            if (
                status === 409 &&
                errorData.error.startsWith("This category is deleted")
            ) {
                const id = extractIdFromErrorMessage(errorData.error);

                dispatch(
                    openModalActionCreator({
                        typeModal: "RESTORE_SERVICE",
                        details: {
                            action: () =>
                                dispatch(
                                    putCategory(
                                        { ...data, isDeleted: false },
                                        id
                                    )
                                ),
                            title: t("services.category-exist"),
                            description: t("services.restore-category"),
                        },
                    })
                );
            }

            if (
                status === 409 &&
                errorData.error === "This category already exist"
            ) {
                dispatch(
                    setToastifyStatus({
                        title: t("services.category-exist"),
                        type: "error",
                    })
                );
            }

            dispatch(categoryActions.postCategoryError(errorData.error));
        });
};

// put category
export const putCategory = (data, id) => (dispatch, getState) => {
    dispatch(categoryActions.putCategoryRequest());
    const { items, count } = allCategorySelector(getState());

    const updateData = {
        remark: data.remark,
        titleTranslations: data.titleTranslations,
        icon: data.icon,
        isDeleted: data.isDeleted,
    };

    if (updateData.titleTranslations.EN) delete updateData.titleTranslations.EN;
    if (updateData.titleTranslations.RU) delete updateData.titleTranslations.RU;
    if (updateData.titleTranslations.TR) delete updateData.titleTranslations.TR;

    return requests
        .putCategoryRequest(getFormData(updateData), id)
        .then(() => {
            const newItems = items.map((body) =>
                body._id === id ? data : body
            );
            dispatch(
                categoryActions.putCategorySuccess({
                    items: newItems,
                    count: count,
                })
            );

            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PUT_CATEGORY,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(categoryActions.putCategoryError(error));
        });
};

// delete category
export const deleteCategory = (id) => (dispatch, getState) => {
    dispatch(categoryActions.deleteCategoryRequest());
    const { items, count } = allCategorySelector(getState());

    return requests
        .deleteCategoryRequest(id)
        .then(() => {
            const newItems = items.filter(({ _id }) => _id !== id);
            dispatch(
                categoryActions.deleteCategorySuccess({
                    items: newItems,
                    count: count !== 0 ? count - 1 : count,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_CATEGORY,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(categoryActions.deleteCategoryError(error));
        });
};

// get subcategory
export const getAllSubcategory =
    (params = "") =>
    (dispatch) => {
        dispatch(categoryActions.getAllSubcategoryRequest());

        return requests
            .getAllSubcategoryRequest(
                `?limit=${sizeSubcategoriesOnPage}` + params
            )
            .then(({ items, totalCount: count }) => {
                dispatch(
                    categoryActions.getAllSubcategorySuccess({ items, count })
                );
            })
            .catch(({ error }) => {
                dispatch(categoryActions.getAllSubcategoryError(error));
            });
    };
// get subcategory without limit
export const getAllSubcategoryWithoutLimit = () => (dispatch) => {
    dispatch(categoryActions.getAllSubcategoryWithoutLimitRequest());

    return requests
        .getAllSubcategoryRequest("?limit=1000")
        .then(({ items, totalCount: count }) => {
            dispatch(
                categoryActions.getAllSubcategoryWithoutLimitSuccess({
                    items,
                    count,
                })
            );
        })
        .catch(({ error }) => {
            dispatch(categoryActions.getAllSubcategoryWithoutLimitError(error));
        });
};

// post subcategory
export const postSubcategory = (data) => (dispatch, getState) => {
    dispatch(categoryActions.postSubcategoryRequest());
    const { items, count } = allSubcategorySelector(getState());

    const updateData = {
        category: data.category,
        remark: data.remark,
        titleTranslations: data.titleTranslations,
        tags: data.tags?.map((tag) => tag["_id"]),
    };

    return requests
        .postSubcategoryRequest(updateData)
        .then((item) => {
            const newItems = [...items, item];
            dispatch(
                categoryActions.postSubcategorySuccess({
                    items: newItems,
                    count: count + 1,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.POST_SUBCATEGORY,
                    type: "success",
                })
            );
        })
        .catch(({ errorData, status }) => {
            if (
                status === 409 &&
                errorData.error.startsWith("This subcategory is deleted")
            ) {
                const id = extractIdFromErrorMessage(errorData.error);

                dispatch(
                    openModalActionCreator({
                        typeModal: "RESTORE_SERVICE",
                        details: {
                            action: () =>
                                dispatch(
                                    putSubcategory(
                                        { ...updateData, isDeleted: false },
                                        id
                                    )
                                ),
                            title: t("services.subcategory-exist"),
                            description: t("services.restore-subcategory"),
                        },
                    })
                );
            }

            if (
                status === 409 &&
                errorData.error === "This subcategory already exist"
            ) {
                dispatch(
                    setToastifyStatus({
                        title: t("services.subcategory-exist"),
                        type: "error",
                    })
                );
            }

            dispatch(categoryActions.postSubcategoryError(errorData.error));
        });
};

// put subcategory
export const putSubcategory = (data, id) => (dispatch, getState) => {
    dispatch(categoryActions.putSubcategoryRequest());
    const { items, count } = allSubcategorySelector(getState());

    const updateData = {
        category: data.category,
        remark: data.remark,
        titleTranslations: data.titleTranslations,
        tags: data.tags?.map((tag) => tag["_id"]),
        isDeleted: data.isDeleted,
    };
    if (updateData.titleTranslations.EN) delete updateData.titleTranslations.EN;
    if (updateData.titleTranslations.RU) delete updateData.titleTranslations.RU;
    if (updateData.titleTranslations.TR) delete updateData.titleTranslations.TR;

    return requests
        .putSubcategoryRequest(updateData, id)
        .then(() => {
            const newItems = items.map((body) =>
                body._id === id ? data : body
            );
            dispatch(
                categoryActions.putSubcategorySuccess({
                    items: newItems,
                    count: count,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PUT_SUBCATEGORY,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(categoryActions.putSubcategoryError(error));
        });
};

// delete subcategory
export const deleteSubcategory = (id) => (dispatch, getState) => {
    dispatch(categoryActions.deleteSubcategoryRequest());
    const { items, count } = allSubcategorySelector(getState());

    return requests
        .deleteSubcategoryRequest(id)
        .then(() => {
            const newItems = items.filter(({ _id }) => _id !== id);
            dispatch(
                categoryActions.deleteSubcategorySuccess({
                    items: newItems,
                    count: count !== 0 ? count - 1 : count,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_SUBCATEGORY,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(categoryActions.deleteSubcategoryError(error));
        });
};

// get clarification
export const getAllClarification =
    (params = "") =>
    (dispatch) => {
        dispatch(categoryActions.getAllClarificationRequest());

        return requests
            .getAllClarificationRequest(
                `?limit=${sizeClarificationsOnPage}` + params
            )
            .then(({ items, totalCount: count }) => {
                dispatch(
                    categoryActions.getAllClarificationSuccess({ items, count })
                );
            })
            .catch(({ error }) => {
                dispatch(categoryActions.getAllClarificationError(error));
            });
    };

// post clarification
export const postClarification = (data) => (dispatch, getState) => {
    dispatch(categoryActions.postClarificationRequest());
    const { items, count } = allClarificationSelector(getState());

    return requests
        .postClarificationRequest(data)
        .then((item) => {
            const newItems = [...items, item];
            dispatch(
                categoryActions.postClarificationSuccess({
                    items: newItems,
                    count: count + 1,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.POST_CLARIFICATION,
                    type: "success",
                })
            );
        })
        .catch(({ errorData, status }) => {
            if (
                status === 409 &&
                errorData.error.startsWith("This clarification is deleted")
            ) {
                const id = extractIdFromErrorMessage(errorData.error);

                dispatch(
                    openModalActionCreator({
                        typeModal: "RESTORE_SERVICE",
                        details: {
                            action: () =>
                                dispatch(
                                    putClarification(
                                        { ...data, isDeleted: false },
                                        id
                                    )
                                ),
                            title: t("services.clarification-exist"),
                            description: t("services.restore-clarification"),
                        },
                    })
                );
            }

            if (
                status === 409 &&
                errorData.error === "This clarification already exist"
            ) {
                dispatch(
                    setToastifyStatus({
                        title: t("services.clarification-exist"),
                        type: "error",
                    })
                );
            }

            dispatch(categoryActions.postClarificationError(errorData.error));
        });
};

// put clarification
export const putClarification = (data, id) => (dispatch, getState) => {
    dispatch(categoryActions.putClarificationRequest());
    const { items, count } = allClarificationSelector(getState());

    const updateData = {
        remark: data.remark,
        subcategory: data.subcategory,
        titleTranslations: data.titleTranslations,
        icon: data.icon,
        isDeleted: data.isDeleted,
    };

    if (updateData.titleTranslations.EN) delete updateData.titleTranslations.EN;
    if (updateData.titleTranslations.RU) delete updateData.titleTranslations.RU;
    if (updateData.titleTranslations.TR) delete updateData.titleTranslations.TR;

    return requests
        .putClarificationRequest(updateData, id)
        .then(() => {
            const newItems = items.map((body) =>
                body._id === id ? data : body
            );
            dispatch(
                categoryActions.putClarificationSuccess({
                    items: newItems,
                    count: count,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PUT_CLARIFICATION,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(categoryActions.putClarificationError(error));
        });
};

// delete clarification
export const deleteClarification = (id) => (dispatch, getState) => {
    dispatch(categoryActions.deleteClarificationRequest());
    const { items, count } = allClarificationSelector(getState());

    return requests
        .deleteClarificationRequest(id)
        .then(() => {
            const newItems = items.filter(({ _id }) => _id !== id);
            dispatch(
                categoryActions.deleteClarificationSuccess({
                    items: newItems,
                    count: count !== 0 ? count - 1 : count,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_CLARIFICATION,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(categoryActions.deleteClarificationError(error));
        });
};

// set
export const editPageCategory = (data) => (dispatch) => {
    dispatch(categoryActions.setCategoryPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getAllCategory(url));
};

export const editPageSubcategory = (data) => (dispatch) => {
    dispatch(categoryActions.setSubcategoryPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getAllSubcategory(url));
};

export const editPageClarification = (data) => (dispatch) => {
    dispatch(categoryActions.setClarificationPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getAllClarification(url));
};
