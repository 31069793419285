import { get, post, destroy, put, formDataHeaders } from "../api";

const baseUrl = "/promotion-types";

export const getPromotionRequest = async (params = "") => {
    const url = baseUrl + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};
// get promotion by id
export const getPromotionByIdRequest = async (id) => {
    const url = `${baseUrl}/${id}`;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};

export const postPromotionRequest = async (reqData = {}) => {
    const url = baseUrl;

    try {
        const { data } = await post(url, reqData, formDataHeaders);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};

export const deletePromotionRequest = async (id = "") => {
    const url = `${baseUrl}/${id}`;

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};

export const putPromotionRequest = async (reqData = {}, id = "") => {
    const url = `${baseUrl}/${id}`;

    try {
        const { data } = await put(url, reqData, formDataHeaders);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};

export const getListOfBoughtPromotions = async (params = "") => {
    const url = "/promotion-buy-history/list" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};

export const deactivateBoughtPromotionsItem = async (id) => {
    const url = `/promotion-buy-history/disable-promotion/${id}`;

    try {
        const { data } = await put(url);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};
