import { useTranslation } from "react-i18next";
import { getLanguage } from "../../../../helpers/language";
import Button from "../../../../shared/Button/Button";

const TypeComplaint = ({ text = "", _id = "", onEdit, onDelete }) => {
    const { t } = useTranslation();
    const { value: language = "en" } = getLanguage();

    return (
        <tr className="types-complaints__content_item">
            <td className="types-complaints__text">{text[language]}</td>
            <td className="types-complaints__options">
                <Button onClick={() => onEdit(text, _id)}>
                    {t("btn-edit")}
                </Button>
                <Button
                    buttonStyle={"btn--red"}
                    onClick={() => onDelete(_id, text[language])}
                >
                    {t("btn-delete")}
                </Button>
            </td>
        </tr>
    );
};

export default TypeComplaint;
