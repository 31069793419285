// get promotion
export const GET_PROMOTION_REQUEST = "promotion/GET_PROMOTION_REQUEST";
export const GET_PROMOTION_SUCCESS = "promotion/GET_PROMOTION_SUCCESS";
export const GET_PROMOTION_ERROR = "promotion/GET_PROMOTION_ERROR";

// get promotion by id
export const GET_PROMOTION_BY_ID_PENDING =
    "promotion/GET_PROMOTION_BY_ID_PENDING";
export const GET_PROMOTION_BY_ID_FULFILLED =
    "promotion/GET_PROMOTION_BY_ID_FULFILLED";
export const GET_PROMOTION_BY_ID_REJECTED =
    "promotion/GET_PROMOTION_BY_ID_REJECTED";

// post promotion
export const POST_PROMOTION_REQUEST = "promotion/POST_PROMOTION_REQUEST";
export const POST_PROMOTION_SUCCESS = "promotion/POST_PROMOTION_SUCCESS";
export const POST_PROMOTION_ERROR = "promotion/POST_PROMOTION_ERROR";

// put promotion
export const PUT_PROMOTION_REQUEST = "promotion/PUT_PROMOTION_REQUEST";
export const PUT_PROMOTION_SUCCESS = "promotion/PUT_PROMOTION_SUCCESS";
export const PUT_PROMOTION_ERROR = "promotion/PUT_PROMOTION_ERROR";

// delete promotion
export const DELETE_PROMOTION_REQUEST = "promotion/DELETE_PROMOTION_REQUEST";
export const DELETE_PROMOTION_SUCCESS = "promotion/DELETE_PROMOTION_SUCCESS";
export const DELETE_PROMOTION_ERROR = "promotion/DELETE_PROMOTION_ERROR";

// set
export const CLEAR_ALL_PARAMS = "promotion/CLEAR_ALL_PARAMS";
export const SET_PAGE = "promotion/SET_PAGE";

// get list of bought promotions
export const GET_LIST_OF_BOUGHT_PROMOTIONS_REQUEST =
    "promotion/GET_LIST_OF_BOUGHT_PROMOTIONS_REQUEST";
export const GET_LIST_OF_BOUGHT_PROMOTIONS_SUCCESS =
    "promotion/GET_LIST_OF_BOUGHT_PROMOTIONS_SUCCESS";
export const GET_LIST_OF_BOUGHT_PROMOTIONS_ERROR =
    "promotion/GET_LIST_OF_BOUGHT_PROMOTIONS_ERROR";

// deactivate bought promotions item
export const PUT_BOUGHT_PROMOTIONS_ITEM_REQUEST =
    "promotion/PUT_BOUGHT_PROMOTIONS_ITEM_REQUEST";
export const PUT_BOUGHT_PROMOTIONS_ITEM_SUCCESS =
    "promotion/PUT_BOUGHT_PROMOTIONS_ITEM_SUCCESS";
export const PUT_BOUGHT_PROMOTIONS_ITEM_ERROR =
    "promotion/PUT_BOUGHT_PROMOTIONS_ITEM_ERROR";

// set history
export const CLEAR_ALL_HISTORY_PARAMS = "promotion/CLEAR_ALL_HISTORY_PARAMS";
export const SET_HISTORY_PAGE = "promotion/SET_HISTORY_PAGE";
