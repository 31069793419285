import { get, destroy, post, put } from "../api";

export const getAllComplaintTypeRequest = async (params = "") => {
    const url = "/complaint/type/list" + params;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const deleteComplaintTypeRequest = async (id) => {
    const url = `/complaint/type/${id}`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const postComplaintTypeRequest = async (reqData = {}) => {
    const url = "/complaint/type";

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const putComplaintTypeRequest = async (reqData = {}, id) => {
    const url = `/complaint/type/${id}`;

    try {
        const { data } = await put(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const getAllComplaintUserRequest = async (params = "") => {
    const url = "/complaint/list" + params;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const deleteComplaintUserRequest = async (id) => {
    const url = `/complaint/reject/${id}`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};
