import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
    allComplaintParamsSelector,
    allComplaintsUsersSelector,
} from "../../../redux/selectors/complaintSelectors";

import {
    deleteComplaintUser,
    editPageAllComplaintUser,
    getAllComplaintUser,
} from "../../../redux/operations/complaintOperations";

import Pagination from "../../../components/Pagination/Pagination";
import UserComplaint from "./UserComplaint/UserComplaint";

import { clearAllParams } from "../../../redux/actions/complaintActions";
import { sizeItemOnPage, usersComplaints } from "./users-complaints.config";
import { openModalActionCreator } from "../../../redux/actions/modalActions";
import { ReactComponent as ArrowDown } from "../../../assets/images/arrow_down.svg";
import "./index.scss";

const UsersComplaintsTable = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { page } = useSelector(allComplaintParamsSelector);
    const { items, count } = useSelector(allComplaintsUsersSelector);

    const [toggleItem, setToggleItem] = useState("");
    const [usersComplaintsList, setUsersComplaintsList] = useState([]);

    const handleOnDelete = (_id, title) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    _id,
                    title: title,
                    action: () => dispatch(deleteComplaintUser(_id)),
                },
            })
        );
    };
    const sortUsersComplaints = (key) => {
        if (!key) return;
        if (toggleItem === key) {
            const sortedUsersComplaintsList = usersComplaintsList.reverse();
            setUsersComplaintsList(sortedUsersComplaintsList);
        }
        if (toggleItem !== key) {
            const sortedUsersComplaintsList = usersComplaintsList.sort((a, b) =>
                a[key] > b[key] ? 1 > 1 : -1
            );
            setToggleItem(key);
            setUsersComplaintsList(sortedUsersComplaintsList);
        } else setToggleItem("");
    };

    useEffect(() => setUsersComplaintsList(items), [items]);

    useEffect(() => {
        dispatch(getAllComplaintUser());
        return () => dispatch(clearAllParams());
    }, []);

    return (
        <>
            <table className="users-complaints">
                <thead className="users-complaints__header">
                    <tr className="users-complaints__header_item">
                        {usersComplaints.map(({ field, key }) => (
                            <th
                                onClick={() => sortUsersComplaints(key)}
                                className={`users-complaints__header_item_title${
                                    toggleItem === key ? "--active" : ""
                                }`}
                                key={field + key}
                            >
                                {key && t(`complaints.title.${field}`)}
                                {key && (
                                    <span
                                        className={`users-complaints__header_item_img`}
                                    >
                                        <ArrowDown />
                                    </span>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="users-complaints__content">
                    {usersComplaintsList &&
                        usersComplaintsList.map(
                            ({ _id, text, type, creator, user }) => (
                                <UserComplaint
                                    _id={_id}
                                    key={_id}
                                    user={user}
                                    text={text}
                                    type={type}
                                    creator={creator}
                                    onDelete={handleOnDelete}
                                />
                            )
                        )}
                </tbody>
            </table>
            <Pagination
                items={{ items: items, count: count }}
                isStartedPage={page}
                sizeItemOnPage={sizeItemOnPage}
                getNextPageItems={(e) => dispatch(editPageAllComplaintUser(e))}
                elementType="page"
            />
        </>
    );
};

export default UsersComplaintsTable;
