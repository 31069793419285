import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import { getAllDistricts } from "../../../redux/operations/locationOperations";
import { putSpecialistService } from "../../../redux/operations/servicesOperations";

import Button from "../../../shared/Button/Button";
import ScheduleInput from "../../../shared/ScheduleInput/ScheduleInput";
import LocationSelect from "../../../shared/LocationSelect/LocationSelect";

import { isObjectEqual } from "../../../helpers/isObjectEqual";
import { convertSpecialistServiceDataToRequestSchema } from "./SpecialistServiceForm.utils";
import SELECT_TYPES from "../../../shared/LocationSelect/location-select.constants";

import { getSpecialistServices } from "../../../redux/selectors/servicesSelectors";
import { citiesLoaderSelector } from "../../../redux/selectors/locationSelectors";

import { SpecialistServiceFormSchema } from "./SpecilaistServiceForm.Schema";

import "./index.scss";

const SpecialistServiceForm = ({ details }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loading } = useSelector(getSpecialistServices);
    const { cities, districts } = useSelector(citiesLoaderSelector);

    const {
        control,
        register,
        handleSubmit,
        setValue,
        resetField,
        formState: { isValid, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(SpecialistServiceFormSchema),
    });

    const [selectedCity, setSelectedCity] = useState({
        state_code: "",
        name: details?.city,
    });

    const handleSelectCity = (currentCity) => {
        if (
            isObjectEqual(currentCity, selectedCity) |
            (currentCity.name === selectedCity.name)
        )
            return;
        resetField("district");
        setSelectedCity(currentCity);
    };

    const handleCloseModal = () => dispatch(closeModalActionCreator());

    const handleFormSubmit = (data) => {
        dispatch(
            putSpecialistService(
                data._id,
                convertSpecialistServiceDataToRequestSchema(data)
            )
        );
    };

    useEffect(() => {
        Object.entries(details).forEach(([name, value]) =>
            setValue(name, value)
        );
    }, [setValue, details]);

    useEffect(() => {
        if (selectedCity?.state_code || details?.city)
            dispatch(getAllDistricts(selectedCity.state_code, details?.city));
    }, [selectedCity, details?.city]);

    return (
        <form
            className="specialist_service_form"
            onSubmit={handleSubmit(handleFormSubmit)}
        >
            <div className="specialist_service_form__content">
                <h2 className="specialist_service_form__title">
                    {t("services-specialist-edit")} ({t("specialist")})
                </h2>
                <div className="specialist_service_form__inner">
                    <div className="specialist_service_form_item">
                        <label className="specialist_service_form_item_label">
                            {t("services-specialist-city")}
                        </label>
                        <Controller
                            name="city"
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={cities}
                                    error={errors.city?.message}
                                    onSelect={(city) => {
                                        handleSelectCity(city);
                                        onChange(city.name);
                                    }}
                                    onBlur={(city) => onBlur(city.name)}
                                    type={SELECT_TYPES.WITHOUT_LABEL}
                                    styleHeight={"54px"}
                                />
                            )}
                        />
                    </div>
                    <div className="specialist_service_form_item">
                        <label className="specialist_service_form_item_label">
                            {t("services-specialist-district")}
                        </label>
                        <Controller
                            control={control}
                            name="district"
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={districts}
                                    onSelect={(district) =>
                                        onChange(district.name)
                                    }
                                    onBlur={(district) => onBlur(district.name)}
                                    type={SELECT_TYPES.WITHOUT_LABEL}
                                    error={errors.district?.message}
                                    styleHeight={"54px"}
                                />
                            )}
                        />
                    </div>
                    <div className="specialist_service_form_item">
                        <label className="specialist_service_form_item_label">
                            {t("services-specialist-address")}
                        </label>
                        <input
                            className={`specialist_service_form_item_input ${
                                errors?.address?.message ? "error" : ""
                            }`}
                            {...register("address")}
                        />
                        {errors?.address?.message && (
                            <p className="specialist_service_form_item-error">
                                {t(errors?.address?.message)}
                            </p>
                        )}
                    </div>
                    <div className="specialist_service_form_item">
                        <label className="specialist_service_form_item_label">
                            {t("services-specialist-price-range")}
                        </label>
                        <div className="specialist_service_form_item__range">
                            <input
                                className={`specialist_service_form_item__range_input ${
                                    errors?.priceFrom?.message ? "error" : ""
                                }`}
                                {...register("priceFrom")}
                            />
                            <input
                                className={`specialist_service_form_item__range_input  ${
                                    errors?.priceTo?.message ? "error" : ""
                                }`}
                                {...register("priceTo")}
                            />
                        </div>
                        {errors?.priceFrom?.message && (
                            <p className="specialist_service_form_item-error">
                                {t(errors?.priceFrom?.message)}
                            </p>
                        )}
                        {errors?.priceTo?.message && (
                            <p className="specialist_service_form_item-error">
                                {t(errors?.priceTo?.message)}
                            </p>
                        )}
                    </div>
                    <div className="specialist_service_form_item">
                        <label className="specialist_service_form_item_label">
                            {t("services-specialist-info-for-customer")}
                        </label>
                        <textarea
                            className={`specialist_service_form_item_textarea ${
                                errors?.comment?.message ? "error" : ""
                            }`}
                            {...register("comment")}
                        />
                        {errors?.comment?.message && (
                            <p className="specialist_service_form_item-error">
                                {t(errors?.comment?.message)}
                            </p>
                        )}
                    </div>
                    <div className="specialist_service_form_item">
                        <label className="specialist_service_form_item_label">
                            {t("services-specialist-schedule")}
                        </label>
                        <Controller
                            control={control}
                            name="schedule"
                            render={({ field: { onChange, value } }) => (
                                <ScheduleInput
                                    value={value}
                                    onChange={onChange}
                                    error={t(errors.schedule?.message)}
                                    placeholder={t(
                                        "services-specialist-schedule-placeholder"
                                    )}
                                />
                            )}
                        />
                    </div>
                    <div className="specialist_service_form__service">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button
                            type="submit"
                            loading={loading}
                            buttonStyle={"btn--green"}
                            disabled={!isValid}
                        >
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SpecialistServiceForm;
