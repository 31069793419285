import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
    blockAccount,
    deleteAccount,
    deleteAccountHard,
    putAccount,
    editPageAll,
    getAllAccount,
} from "../../../redux/operations/accountOperations";
import { openModalActionCreator } from "../../../redux/actions/modalActions";
import {
    allAccountSelector,
    allAccountParamsSelector,
} from "../../../redux/selectors/accountSelectors";
import { clearAllParams } from "../../../redux/actions/accountActions";

import Button from "../../../shared/Button/Button";
import AccountItem from "./Account/Account";
import Pagination from "../../Pagination/Pagination";
import AccountsSearch from "./AccountsSearch/AccountsSearch";

import { ReactComponent as ArrowDown } from "../../../assets/images/arrow_down.svg";
import { accountsTitle, sizeItemOnPage } from "./accounts.config";

import "./Accounts.scss";

const Accounts = () => {
    const { allAccount } = useSelector(allAccountSelector);
    const { page } = useSelector(allAccountParamsSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [accountsList, setAccountsList] = useState();
    const [toggleItem, setToggleItem] = useState("");

    useEffect(() => setAccountsList(allAccount), [allAccount]);

    useEffect(() => {
        dispatch(getAllAccount(sizeItemOnPage));
        return () => dispatch(clearAllParams());
    }, [dispatch]);

    const sortAccounts = (key) => {
        if (!key) return;
        if (toggleItem === key) {
            const sortedAccountsList = accountsList.items.reverse();
            setAccountsList({
                items: sortedAccountsList,
                count: accountsList.count,
            });
        }
        if (toggleItem !== key) {
            const sortedAccountsList = accountsList.items.sort((a, b) =>
                a[key] > b[key] ? 1 > 1 : -1
            );
            setToggleItem(key);
            setAccountsList({
                items: sortedAccountsList,
                count: accountsList.count,
            });
        } else setToggleItem("");
    };

    const handleOnDelete = (id) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    id,
                    title: "account",
                    action: () => dispatch(deleteAccount(id)),
                },
            })
        );
    };

    const handleOnDeleteHard = (id) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    id,
                    title: "account",
                    action: () => dispatch(deleteAccountHard(id)),
                },
            })
        );
    };

    const handleOnEdit = ({ role, _id }) => {
        dispatch(
            openModalActionCreator({
                typeModal: `EDIT_${role}`,
                details: { _id, role },
            })
        );
    };

    const handleOnBlock = (id, isBanned = false) => {
        dispatch(blockAccount(id, isBanned));
    };

    const handleOnAdd = () => {
        dispatch(
            openModalActionCreator({
                typeModal: "ADD_NEW_USER",
            })
        );
    };

    const handleOnRestore = (id) => {
        dispatch(putAccount(id, { isDeleted: false }));
    };

    return (
        <>
            <div className="accounts">
                <AccountsSearch />
                <table className="accounts__inner">
                    <thead className="accounts__header">
                        <tr className="accounts__header_item">
                            {accountsTitle.map(({ field, key }) => (
                                <th
                                    key={field}
                                    className={`accounts__header_item_title${
                                        toggleItem === key ? "--active" : ""
                                    }`}
                                    onClick={() => sortAccounts(key)}
                                >
                                    {t(field)}
                                    {key && (
                                        <span
                                            className={`accounts__header_item_img`}
                                        >
                                            <ArrowDown />
                                        </span>
                                    )}
                                </th>
                            ))}
                            <th className="accounts__header_item_title">
                                <Button
                                    buttonStyle={"btn--outlined"}
                                    onClick={handleOnAdd}
                                >
                                    {t("btn-add")}
                                </Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="accounts__body">
                        {accountsList?.items?.length > 0 &&
                            accountsList?.items?.map((account) => (
                                <AccountItem
                                    {...account}
                                    key={account._id}
                                    onEdit={() => handleOnEdit(account)}
                                    onDelete={() => handleOnDelete(account._id)}
                                    onDeleteHard={() =>
                                        handleOnDeleteHard(account._id)
                                    }
                                    onBlock={() =>
                                        handleOnBlock(
                                            account._id,
                                            account.isBanned
                                        )
                                    }
                                    onRestore={() =>
                                        handleOnRestore(account._id)
                                    }
                                />
                            ))}
                    </tbody>
                </table>
                <Pagination
                    getNextPageItems={(e) =>
                        dispatch(editPageAll(sizeItemOnPage, e))
                    }
                    sizeItemOnPage={sizeItemOnPage}
                    isStartedPage={page}
                    items={allAccount}
                    elementType="page"
                />
            </div>
        </>
    );
};

export default Accounts;
