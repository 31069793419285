import * as tagsTypes from "../types/tagsTypes";

const initialState = {
    allTags: { items: [], totalCount: 0 },
    loading: false,
    error: "",
};

export const tagsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case tagsTypes.GET_ALL_TAGS_REQUEST:
        case tagsTypes.POST_TAG_REQUEST:
        case tagsTypes.PUT_TAG_REQUEST:
        case tagsTypes.DELETE_TAG_REQUEST: {
            return { ...state, loading: true };
        }

        // data category
        case tagsTypes.GET_ALL_TAGS_SUCCESS:
        case tagsTypes.POST_TAG_SUCCESS:
        case tagsTypes.PUT_TAG_SUCCESS:
        case tagsTypes.DELETE_TAG_SUCCESS: {
            return { ...state, allTags: payload, loading: false, error: "" };
        }

        // error
        case tagsTypes.GET_ALL_TAGS_ERROR:
        case tagsTypes.POST_TAG_ERROR:
        case tagsTypes.PUT_TAG_ERROR:
        case tagsTypes.DELETE_TAG_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        default:
            return state;
    }
};
