import { t } from "i18next";
import { useSelector } from "react-redux";
import { allComplaintsSelector } from "../../../../redux/selectors/complaintSelectors";
import { ReactComponent as EmptyList } from "../../../../assets/images/empty-result.svg";

const NoUserComplaints = () => {
    const { loading } = useSelector(allComplaintsSelector);

    return (
        <div className="user-complaints_no-exist">
            <div className="user-complaints_no-exist-image">
                <EmptyList />
            </div>
            <div className="user-complaints_no-exist-text">
                {loading
                    ? t("complaints.complaints-unique-loading")
                    : t("complaints.complaints-unique-not-found")}
            </div>
        </div>
    );
};

export default NoUserComplaints;
