import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { yupResolver } from "@hookform/resolvers/yup";
import { ComplaintFormSchema } from "./ComplaintForm.Schema";

import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import {
    postComplaintType,
    putComplaintType,
} from "../../../redux/operations/complaintOperations";
import { allComplaintParamsSelector } from "../../../redux/selectors/complaintSelectors";

import Button from "../../../shared/Button/Button";

import "./index.scss";

const ComplaintForm = ({ details }) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        defaultValues: { ...details },
        mode: "all",
        resolver: yupResolver(ComplaintFormSchema),
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loading } = useSelector(allComplaintParamsSelector);

    const { _id } = details;
    const handleCloseModal = () => dispatch(closeModalActionCreator());

    const onSubmit = (data) => {
        if (_id) {
            dispatch(putComplaintType(data, _id));
        } else {
            dispatch(postComplaintType(data));
        }
    };
    return (
        <form className="complaint__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="complaint__form__content">
                <h2 className="complaint__form__title">
                    {t(`complaints.title.${_id ? "edit" : "add"}`)}
                </h2>
                <div className="complaint__form__items">
                    <div className="complaint__item">
                        <label className="complaint__item_label">EN</label>
                        <input
                            className={`complaint__item_input ${
                                errors?.text?.en && "error"
                            }`}
                            {...register("text.en")}
                        />
                        {errors.text?.en && (
                            <span className="complaint__item-error">
                                {t(errors.text?.en?.message)}
                            </span>
                        )}
                    </div>
                    <div className="complaint__item">
                        <label className="complaint__item_label">RU</label>
                        <input
                            className={`complaint__item_input ${
                                errors?.text?.ru && "error"
                            }`}
                            {...register("text.ru")}
                        />
                        {errors.text?.ru && (
                            <span className="complaint__item-error">
                                {t(errors.text?.ru?.message)}
                            </span>
                        )}
                    </div>
                    <div className="complaint__item">
                        <label className="complaint__item_label">TR</label>
                        <input
                            className={`complaint__item_input ${
                                errors?.text?.tr && "error"
                            }`}
                            {...register("text.tr")}
                        />
                        {errors.text?.tr && (
                            <span className="complaint__item-error">
                                {t(errors.text?.tr?.message)}
                            </span>
                        )}
                    </div>

                    <div className="complaint__options">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button
                            type="submit"
                            buttonStyle={"btn--green"}
                            loading={loading}
                        >
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ComplaintForm;
