import * as complaintActions from "../actions/complaintActions";
import * as requests from "../../services/requests/complaint";
import {
    allComplaintsTypesSelector,
    allComplaintsUserSelector,
} from "../selectors/complaintSelectors";
import { closeModalActionCreator } from "../actions/modalActions";
import { setToastifyStatus } from "../actions/toastifyActions";
import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";
import { sizeItemOnPage } from "../../components/Tables/UsersComplaints/users-complaints.config";

// complaint type
export const getAllComplaintType =
    (params = "") =>
    (dispatch) => {
        dispatch(complaintActions.getAllComplaintTypeRequest());
        return requests
            .getAllComplaintTypeRequest(`?limit=${sizeItemOnPage}` + params)
            .then(({ items }) => {
                dispatch(
                    complaintActions.getAllComplaintTypeSuccess({ items })
                );
            })
            .catch(({ error }) => {
                dispatch(complaintActions.getAllComplaintTypeError(error));
            });
    };

export const deleteComplaintType = (id) => (dispatch, getState) => {
    dispatch(complaintActions.deleteComplaintTypeRequest());

    const { items } = allComplaintsTypesSelector(getState());

    return requests
        .deleteComplaintTypeRequest(id)
        .then(() => {
            const newItems = items.filter(({ _id }) => _id !== id);
            dispatch(
                complaintActions.deleteComplaintTypeSuccess({ items: newItems })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_COMPLAINT_TYPE,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(complaintActions.deleteComplaintTypeError(error));
        });
};

export const postComplaintType = (data) => (dispatch, getState) => {
    dispatch(complaintActions.postComplaintTypeRequest());
    const { items } = allComplaintsTypesSelector(getState());

    return requests
        .postComplaintTypeRequest(data)
        .then((item) => {
            const newItems = [...items, item];
            dispatch(
                complaintActions.postComplaintTypeSuccess({
                    items: newItems,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.POST_COMPLAINT_TYPE,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(complaintActions.postComplaintTypeError(error));
        });
};

export const putComplaintType = (data, id) => (dispatch, getState) => {
    dispatch(complaintActions.putComplaintTypeRequest());
    const { items } = allComplaintsTypesSelector(getState());

    return requests
        .putComplaintTypeRequest({ text: data.text }, id)
        .then(() => {
            const newItems = items.map((body) =>
                body._id === id ? data : body
            );
            dispatch(
                complaintActions.putComplaintTypeSuccess({
                    items: newItems,
                })
            );

            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PUT_COMPLAINT_TYPE,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(complaintActions.putComplaintTypeError(error));
        });
};

// complaint user
export const getAllComplaintUser =
    (params = "") =>
    (dispatch) => {
        dispatch(complaintActions.getAllComplaintUserRequest());
        return requests
            .getAllComplaintUserRequest(`?limit=${sizeItemOnPage}` + params)
            .then(({ items, totalCount }) => {
                dispatch(
                    complaintActions.getAllComplaintUserSuccess({
                        items,
                        count: totalCount,
                    })
                );
            })
            .catch(({ error }) => {
                dispatch(complaintActions.getAllComplaintUserError(error));
            });
    };

export const deleteComplaintUser = (id) => (dispatch, getState) => {
    dispatch(complaintActions.deleteComplaintUserRequest());

    const { items, count } = allComplaintsUserSelector(getState());

    return requests
        .deleteComplaintUserRequest(id)
        .then(() => {
            const newItems = items.filter(({ _id }) => _id !== id);

            dispatch(
                complaintActions.deleteComplaintUserSuccess({
                    items: newItems,
                    count: count !== 0 ? count - 1 : count,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_COMPLAINT_USER,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(complaintActions.deleteComplaintUserError(error));
        });
};

export const getAllComplaintsUser =
    (params = "") =>
    (dispatch) => {
        dispatch(complaintActions.getAllComplaintsUserRequest());
        return requests
            .getAllComplaintUserRequest(`?limit=${sizeItemOnPage}` + params)
            .then(({ items, totalCount }) => {
                dispatch(
                    complaintActions.getAllComplaintsUserSuccess({
                        items,
                        count: totalCount,
                    })
                );
            })
            .catch(({ error }) => {
                dispatch(complaintActions.getAllComplaintsUserError(error));
            });
    };

export const deleteComplaintsUser = (id) => (dispatch, getState) => {
    dispatch(complaintActions.deleteComplaintsUserRequest());

    const { items, count } = allComplaintsUserSelector(getState());

    return requests
        .deleteComplaintUserRequest(id)
        .then(() => {
            const newItems = items.filter(({ _id }) => _id !== id);
            dispatch(
                complaintActions.deleteComplaintsUserSuccess({
                    items: newItems,
                    count: count,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_COMPLAINT_USER,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(complaintActions.deleteComplaintsUserError(error));
        });
};

//set
export const editPageAllComplaintUser = (data) => (dispatch) => {
    dispatch(complaintActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getAllComplaintUser(url));
};

export const editPageAllComplaintsUser = (id, data) => (dispatch) => {
    dispatch(complaintActions.setPage(`&page=${data}`));
    const url = `&user=${id}&page=${data}`;
    dispatch(getAllComplaintsUser(url));
};
