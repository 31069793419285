import { SET_TOASTIFY_STATUS } from "../types/toastifyTypes";

const initialState = { isVisible: false, title: "", type: "" };

export const toastifyReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_TOASTIFY_STATUS:
            return payload;

        default:
            return state;
    }
};
