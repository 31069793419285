import { useTranslation } from "react-i18next";
import React from "react";

import { getLanguage } from "../../../../helpers/language";
import { promotionTypes } from "../Promotion.config";

import "./PromotionItem.scss";

const PromotionItem = ({
    _id,
    descriptionTranslations,
    onEdit,
    onDelete,
    type,
}) => {
    const { value: language } = getLanguage();
    const { t } = useTranslation();

    return (
        <tr className="promotion">
            <td>{_id}</td>
            <td>
                {type &&
                    promotionTypes.hasOwnProperty(type) &&
                    t(promotionTypes[type].label)}
            </td>
            <td>
                {descriptionTranslations && descriptionTranslations[language]}
            </td>
            <td className="promotion__services">
                <button className="promotion__services--edit" onClick={onEdit}>
                    {t("btn-edit")}
                </button>
                <button
                    className="promotion__services--delete"
                    onClick={onDelete}
                >
                    {t("btn-delete")}
                </button>
            </td>
        </tr>
    );
};
export default PromotionItem;
