import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { editSort } from "../../../../redux/operations/supportOperations";
import { supportPageSelector } from "../../../../redux/selectors/supportSelectors";

import { supportTypes } from "../Support.config";

import "./SupportSort.scss";

const SupportSort = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const page = useSelector(supportPageSelector);

    return (
        <div className="support-sort">
            <label className="support-sort__label">
                {t("support.sort.sort-by")}
            </label>
            <select
                defaultValue={""}
                className="support-sort__select"
                onChange={({ target: { value } }) => {
                    dispatch(editSort(value, page));
                }}
            >
                {supportTypes.map(({ key, field }) => (
                    <option key={field} value={key}>
                        {t(`support.sort.${field}`)}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SupportSort;
