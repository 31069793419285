import { post, get, put, destroy, formDataHeaders } from "../api";

export const getBlogListRequest = async (params = "") => {
    const url = "/blog/search" + params;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const getBlogByIdRequest = async (id) => {
    const url = `/blog/${id}`;

    try {
        const { data } = await put(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const postBlogRequest = async (reqData = {}) => {
    const url = "/blog";

    try {
        const { data } = await post(url, reqData, formDataHeaders);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const putBlogRequest = async (reqData = {}, id) => {
    const url = `/blog/${id}`;

    try {
        const { data } = await put(url, reqData, formDataHeaders);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const deleteBlogRequest = async (id) => {
    const url = `/blog/${id}`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};
