import { createSelector } from "reselect";

const paramsSelector = (state) => state.category.params;
export const categorySelector = (state) => state.category;

export const allCategorySelector = createSelector(
    categorySelector,
    (category) => category.allCategory
);
export const allCategoryWithoutLimitSelector = createSelector(
    categorySelector,
    (category) => category.allCategoryWithoutLimit
);
export const allClarificationSelector = createSelector(
    categorySelector,
    (category) => category.allClarification
);
export const allSubcategorySelector = createSelector(
    categorySelector,
    (category) => category.allSubcategory
);
export const allSubcategoryWithoutLimitSelector = createSelector(
    categorySelector,
    (category) => category.allSubcategoryWithoutLimit
);

export const allClarificationParamsSelector = createSelector(
    paramsSelector,
    (category) => category.clarificationParams
);
export const allSubcategoryParamsSelector = createSelector(
    paramsSelector,
    (category) => category.subcategoryParams
);
export const allCategoryParamsSelector = createSelector(
    paramsSelector,
    (category) => category.categoryParams
);
