import { sizeItemOnPage } from "../../components/Tables/UsersComplaints/users-complaints.config";
import * as categoryTypes from "../types/complaintType";

const initialState = {
    params: { limit: sizeItemOnPage, text: "", page: "&page=0" },
    complaintsTypes: { items: [], count: 0 },
    complaintsUsers: { items: [], count: 0 },
    complaintsUser: { items: [], count: 0 },
    loading: false,
    error: "",
};

export const complaintReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case categoryTypes.GET_ALL_COMPLAINTS_USER_REQUEST:
        case categoryTypes.GET_ALL_COMPLAINT_USER_REQUEST:
        case categoryTypes.PUT_COMPLAINT_TYPE_REQUEST:
        case categoryTypes.POST_COMPLAINT_TYPE_REQUEST:
        case categoryTypes.DELETE_COMPLAINTS_USER_REQUEST:
        case categoryTypes.DELETE_COMPLAINT_USER_REQUEST:
        case categoryTypes.DELETE_COMPLAINT_TYPE_REQUEST:
        case categoryTypes.GET_ALL_COMPLAINT_TYPE_REQUEST: {
            return { ...state, loading: true };
        }

        case categoryTypes.PUT_COMPLAINT_TYPE_SUCCESS:
        case categoryTypes.POST_COMPLAINT_TYPE_SUCCESS:
        case categoryTypes.DELETE_COMPLAINT_TYPE_SUCCESS:
        case categoryTypes.GET_ALL_COMPLAINT_TYPE_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: "",
                complaintsTypes: payload,
            };
        }

        case categoryTypes.DELETE_COMPLAINT_USER_SUCCESS:
        case categoryTypes.GET_ALL_COMPLAINT_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: "",
                complaintsUsers: payload,
            };
        }

        case categoryTypes.DELETE_COMPLAINTS_USER_SUCCESS:
        case categoryTypes.GET_ALL_COMPLAINTS_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: "",
                complaintsUser: payload,
            };
        }
        case categoryTypes.GET_ALL_COMPLAINTS_USER_ERROR:
        case categoryTypes.GET_ALL_COMPLAINT_USER_ERROR:
        case categoryTypes.PUT_COMPLAINT_TYPE_ERROR:
        case categoryTypes.POST_COMPLAINT_TYPE_ERROR:
        case categoryTypes.DELETE_COMPLAINT_USER_ERROR:
        case categoryTypes.DELETE_COMPLAINT_TYPE_ERROR:
        case categoryTypes.GET_ALL_COMPLAINT_TYPE_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        case categoryTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };
        case categoryTypes.SET_TEXT:
            return { ...state, params: { ...state.params, text: payload } };
        case categoryTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialState.params };

        default:
            return state;
    }
};
