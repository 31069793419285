import * as requests from "../../services/requests/promotion";

import { promotionSelector } from "../selectors/promotionSelectors";
import { closeModalActionCreator } from "../actions/modalActions";
import * as promotionActions from "../actions/promotionActions";
import { setToastifyStatus } from "../actions/toastifyActions";

import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";
import { sizeItemOnPage } from "../../components/Tables/Promotion/Promotion.config";
import { sizeHistoryItemsOnPage } from "../../components/Tables/PromotionsHistory/promotions-history.config";

// get promotion
export const getPromotion =
    (params = "") =>
    (dispatch) => {
        dispatch(promotionActions.getPromotionRequest());

        return requests
            .getPromotionRequest(`?limit=${sizeItemOnPage}` + params)
            .then(({ promotionTypes: items, totalCount: count }) => {
                dispatch(
                    promotionActions.getPromotionSuccess({ items, count })
                );
            })
            .catch(({ error }) => {
                dispatch(promotionActions.getPromotionError(error));
            });
    };

export const getPromotionById = (id) => (dispatch) => {
    dispatch(promotionActions.getPromotionByIdPending());

    return requests
        .getPromotionByIdRequest(id)
        .then((response) => {
            dispatch(promotionActions.getPromotionByIdFulfilled(response));
        })
        .catch((error) => {
            dispatch(promotionActions.getPromotionByIdRejected(error));
        });
};

// post promotion
export const postPromotion = (data) => (dispatch, getState) => {
    dispatch(promotionActions.postPromotionRequest());
    const { allPromotion } = promotionSelector(getState());

    return requests
        .postPromotionRequest(data)
        .then((item) => {
            const newItems = [...allPromotion.items, item];
            dispatch(
                promotionActions.postPromotionSuccess({
                    items: newItems,
                    count: allPromotion.count + 1,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.POST_PROMOTION,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(promotionActions.postPromotionError(error));
        });
};

// delete promotion
export const deletePromotion = (id) => (dispatch, getState) => {
    dispatch(promotionActions.deletePromotionRequest());
    const { allPromotion } = promotionSelector(getState());

    return requests
        .deletePromotionRequest(id)
        .then(() => {
            const newItems = allPromotion.items.filter(({ _id }) => _id !== id);
            dispatch(
                promotionActions.deletePromotionSuccess({
                    items: newItems,
                    count: allPromotion.count - 1,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_PROMOTION,
                    type: "success",
                })
            );
        })
        .catch((error) =>
            dispatch(promotionActions.deletePromotionError(error))
        );
};

// put promotion
export const putPromotion = (data, id) => (dispatch, getState) => {
    dispatch(promotionActions.putPromotionRequest());
    const { allPromotion } = promotionSelector(getState());

    return requests
        .putPromotionRequest(data, id)
        .then((response) => {
            const newItems = allPromotion.items.map((body) =>
                body._id === id ? response : body
            );
            dispatch(
                promotionActions.putPromotionSuccess({
                    items: newItems,
                    count: allPromotion.count,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PUT_PROMOTION,
                    type: "success",
                })
            );
        })
        .catch((error) => dispatch(promotionActions.putPromotionError(error)));
};

// set promotions
export const editPagePromotion = (page) => (dispatch) => {
    dispatch(promotionActions.setPage(`&page=${page}`));
    const url = `&page=${page}`;
    dispatch(getPromotion(url));
};

// get list of bought promotions
export const getListOfBoughtPromotions =
    (params = "") =>
    (dispatch) => {
        dispatch(promotionActions.getListOfBoughtPromotionsRequest());

        return requests
            .getListOfBoughtPromotions(
                `?limit=${sizeHistoryItemsOnPage}` + params
            )
            .then(({ promotionBuyHistory: items, totalCount: count }) => {
                dispatch(
                    promotionActions.getListOfBoughtPromotionsSuccess({
                        items,
                        count,
                    })
                );
            })
            .catch(({ error }) => {
                dispatch(
                    promotionActions.getListOfBoughtPromotionsError(error)
                );
            });
    };

// deactivate bought promotions item
export const putBoughtPromotionsItemRequest = (id) => (dispatch) => {
    dispatch(promotionActions.putBoughtPromotionsItemRequest());

    return requests
        .deactivateBoughtPromotionsItem(id)
        .then(() => {
            dispatch(promotionActions.putBoughtPromotionsItemSuccess(id));
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DEACTIVATE_PROMOTION,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(promotionActions.putBoughtPromotionsItemError(error));
        });
};

// set bought promotions
export const editPageBoughtPromotion = (data) => (dispatch) => {
    dispatch(promotionActions.setHistoryPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getListOfBoughtPromotions(url));
};
