import { useTranslation } from "react-i18next";
import FlagsCheckbox from "../FlagsCheckbox/FlagsCheckbox";

const FlagsItem = ({ title }) => {
    const { t } = useTranslation();

    const checkboxList = [1, 2, 3];

    return (
        <tr className="flags__content_item">
            <td className="flags__content_item_text">{t(`flags.${title}`)}</td>
            {checkboxList.map((index, item) => (
                <FlagsCheckbox key={item + index} />
            ))}
        </tr>
    );
};

export default FlagsItem;
