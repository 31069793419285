import {
    getAllCitiesRequest,
    getAllDistrictsRequest,
} from "../../services/requests/location";
import * as locationActions from "../actions/locationActions";

export const getAllCities = () => (dispatch) => {
    dispatch(locationActions.getAllCitiesPending());

    return getAllCitiesRequest()
        .then((cities) =>
            dispatch(locationActions.getAllCitiesFulfilled(cities.items))
        )
        .catch((error) =>
            dispatch(locationActions.getAllCitiesRejected(error))
        );
};

export const getAllDistricts = (state_code, cityName) => (dispatch) => {
    dispatch(locationActions.getAllDistrictsPending());

    return getAllDistrictsRequest(state_code, cityName)
        .then((districts) =>
            dispatch(locationActions.getAllDistrictsFulfilled(districts.items))
        )
        .catch((error) =>
            dispatch(locationActions.getAllDistrictsRejected(error))
        );
};
