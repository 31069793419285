import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { tagSchema } from "./TagForm.Schema";
import { postTag, putTag } from "../../../redux/operations/tagsOperations";
import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import "./index.scss";
const TagForm = ({ details }) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        defaultValues: { ...details },
        mode: "all",
        resolver: yupResolver(tagSchema),
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { _id } = details;

    const onSubmit = (data) => {
        if (_id) {
            dispatch(putTag(data, _id));
        } else {
            dispatch(postTag(data));
        }
    };
    return (
        <form className="tag__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="tag__form__content">
                <h2 className="tag__form__title">
                    {t(`services.tags.${_id ? "edit" : "add"}`)}
                </h2>
                <div className="tag__form__items">
                    <div className="tag__item">
                        <label className="tag__item_label">{t("naming")}</label>
                        <input
                            className={`tag__item_input ${
                                errors?.name && "error"
                            }`}
                            {...register("name")}
                        />
                        {errors.remark && (
                            <span className="tag__item-error">
                                {t(errors.name?.message)}
                            </span>
                        )}
                    </div>

                    <div className="tag__options">
                        <button
                            onClick={() => dispatch(closeModalActionCreator())}
                            className="tag__options--cancel"
                        >
                            {t("btn-cancel")}
                        </button>
                        <button type="submit" className="tag__options--save">
                            {t("btn-save")}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default TagForm;
