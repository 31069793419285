import { useTranslation } from "react-i18next";

import Button from "../../../../shared/Button/Button";
import { getLanguage } from "../../../../helpers/language";

const FAQItem = ({
    titleTranslations,
    descriptionTranslations,
    onDelete,
    onEdit,
    _id,
}) => {
    const { value: lang = "en" } = getLanguage();
    const { t } = useTranslation();

    return (
        <tr className="faq_item">
            <td className="faq_item_text">{titleTranslations[lang]}</td>
            <td className="faq_item_text">{descriptionTranslations[lang]}</td>

            <td className="faq_item__options">
                <Button buttonStyle={"btn--green"} onClick={() => onEdit(_id ,titleTranslations ,descriptionTranslations)}>
                    {t("btn-edit")}
                </Button>
                <Button buttonStyle={"btn--red"} onClick={() => onDelete(_id)}>
                    {t("btn-delete")}
                </Button>
            </td>
        </tr>
    );
};
export default FAQItem;
