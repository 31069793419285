import { useTranslation } from "react-i18next";

import "./index.scss";

const CategoryItem = ({ _id, remark = "", onEdit, onDelete }) => {
    const { t } = useTranslation();

    return (
        <tr className="title">
            <td>{_id}</td>
            <td>{remark}</td>
            <td className="category-item__options">
                <button
                    className="category-item__options--edit"
                    onClick={onEdit}
                >
                    {t("btn-edit")}
                </button>
                <button
                    className="category-item__options--delete"
                    onClick={onDelete}
                >
                    {t("btn-delete")}
                </button>
            </td>
        </tr>
    );
};
export default CategoryItem;
