import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import BackButton from "../../../../shared/BackButton/BackButton";
import UserComplaintsTable from "../../../../components/Tables/UserComplaints/UserComplaintsTable";

import "./index.scss";

const UserComplaintsItem = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();

    return (
        <>
            <div className="account-details__header">
                <div className="account-details__header_btn">
                    <BackButton
                        onClickCallback={() => navigate(-1)}
                        title={t("btn-prev")}
                    />
                </div>
                {t("complaints.complaints-unique")} ID:{" "}
                <span className="account-details__header_id">{id}</span>
            </div>
            <UserComplaintsTable id={id} />
        </>
    );
};

export default UserComplaintsItem;
