import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { getLanguage } from "../../../../helpers/language";
import Button from "../../../../shared/Button/Button";

const UserComplaint = ({
    text = "",
    _id = "",
    type = "",
    user = {},
    creator = {},
    onDelete,
}) => {
    const { t } = useTranslation();
    const { value: language = "en" } = getLanguage();

    return (
        <tr className="user-complaints_item">
            <td className="user-complaints_item_text">
                {type?.text[language]}
            </td>
            <td className="user-complaints_item_text">{text}</td>
            <td className="user-complaints_item_text">
                {`${creator?.firstName}  ${creator?.lastName}`}
            </td>
            <td className="user-complaints_item_link">
                <a
                    className="user-complaints_item_link_creator"
                    href={`mailto:${creator?.email}`}
                >
                    {creator?.email}
                </a>
            </td>
            <td className="user-complaints_item_link">
                <NavLink
                    className={"user-complaints_item_link_user"}
                    to={`/accounts/${user?.role}/${user?._id}`}
                >{`${user?.firstName}  ${user?.lastName}`}</NavLink>
            </td>
            <td className="user-complaints_item_link">
                <a
                    className="user-complaints_item_link_post"
                    href={`mailto:${user?.email}`}
                >
                    {user?.email}
                </a>
            </td>
            <td className="user-complaints_item__options">
                <Button
                    buttonStyle={"btn--red"}
                    onClick={() => onDelete(_id, text)}
                >
                    {t("btn-delete")}
                </Button>
            </td>
        </tr>
    );
};

export default UserComplaint;
