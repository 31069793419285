import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

import Button from "../../../../shared/Button/Button";

import { compare } from "../../../../helpers/compare";
import { accountRoles } from "../../../../configs/accountRoles";

import routerBook from "../../../../router/routerBook";

const SupportTicket = ({
    _id,
    role,
    active,
    firstName,
    lastName,
    category,
    title,
    description,
    userId,
    chatId,
    onDeactivate,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <tr className="support_item">
            <td className="support_item_text">
                {role && t(compare(accountRoles, role))}
            </td>
            <td className="support_item_text">
                <NavLink
                    className="support_item_link"
                    to={`/accounts/${role}/${userId}`}
                >
                    {firstName} {lastName}
                </NavLink>
            </td>
            <td className="support_item_text">{category}</td>
            <td className="support_item_text">{title}</td>
            <td className="support_item_text">{description}</td>
            <td className="support_item_text">
                {active ? (
                    <span className="support_item_text-active">
                        {t("support.chat.active")}
                    </span>
                ) : (
                    <span className="support_item_text-disabled">
                        {t("support.chat.disabled")}
                    </span>
                )}
            </td>

            <td className="support_item__options">
                <Button
                    buttonStyle={"btn--green"}
                    onClick={() =>
                        navigate(
                            `${routerBook.support}/chat/${chatId._id}/${chatId.questionId}`,
                            {
                                state: true,
                            }
                        )
                    }
                >
                    {t("support.button.go-to-chat")}
                </Button>
                {active && (
                    <Button
                        buttonStyle={"btn--red"}
                        onClick={() => onDeactivate(_id)}
                    >
                        {t("btn-deactivate")}
                    </Button>
                )}
            </td>
        </tr>
    );
};
export default SupportTicket;
