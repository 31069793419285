import PromotionsHistoryPage from "../pages/PromotionsHistory/PromotionsHistory";
import ListOfAdminUsers from "../pages/ListOfAdminUsers/ListOfAdminUsers";
import AccountsDetails from "../pages/AccountsDetails/AccountsDetails";
import AccountsPage from "../pages/Accounts/AccountsPage";
import ServicesPage from "../pages/Services/ServicesPage";
import ForbiddenPage from "../pages/Forbidden/Forbidden";
import Complaints from "../pages/Complaints/Complaints";
import Promotion from "../pages/Promotion/Promotion";
import LoginPage from "../pages/Login/Login";
import BlogPage from "../pages/Blog/BlogPage";
import Flags from "../pages/Flags/Flags";
import FAQ from "../pages/FAQ/FAQ";
import Support from "../pages/Support/Support";
import SupportChat from "../pages/SupportChat/SupportChat";
import routerBook from "./routerBook";

export const publicRouter = [
    {
        index: true,
        path: `${routerBook.login}`,
        component: <LoginPage />,
    },
    {
        index: true,
        path: "*",
        component: <LoginPage />,
    },
];

export const privateRoutes = [
    {
        index: true,
        path: `${routerBook.home}`,
    },
    {
        index: true,
        path: `${routerBook.accounts}`,
        component: <AccountsPage />,
    },
    {
        index: true,
        path: `${routerBook.services}`,
        component: <ServicesPage />,
    },
    {
        index: true,
        path: `${routerBook.accountsItem}`,
        component: <AccountsDetails />,
    },
    {
        index: true,
        path: `${routerBook.complaintsType}`,
        component: <Complaints />,
    },
    {
        index: true,
        path: `${routerBook.promotion}`,
        component: <Promotion />,
    },
    {
        index: true,
        path: `${routerBook.listOfAdminUsers}`,
        component: <ListOfAdminUsers />,
    },
    {
        index: true,
        path: `${routerBook.blog}`,
        component: <BlogPage />,
    },
    {
        index: true,
        path: `${routerBook.promotionsHistory}`,
        component: <PromotionsHistoryPage />,
    },
    {
        index: true,
        path: `${routerBook.flags}`,
        component: <Flags />,
    },
    {
        index: true,
        path: `${routerBook.faq}`,
        component: <FAQ />, 
    },
    {
        index: true,
        path: `${routerBook.support}`,
        component: <Support />,
    },
    {
        index: true,
        path: `${routerBook.supportChat}`,
        component: <SupportChat />,
    },
    {
        index: true,
        path: "*",
        component: <ForbiddenPage />,
    },
];
