import ButtonLoader from "../ButtonLoader/ButtonLoader";
import "./index.scss";

const STYLES = ["btn--green", "btn--red", "btn--outlined"];

const Button = ({
    onClick,
    children,
    type,
    buttonStyle,
    loading,
    disabled,
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];

    return (
        <button
            className={`btn ${checkButtonStyle} ${
                loading ? "btn--loading" : ""
            } ${disabled ? " btn--disabled" : ""}`}
            onClick={onClick}
            type={type}
        >
            {loading ? <ButtonLoader size={35} /> : children}
        </button>
    );
};

export default Button;
