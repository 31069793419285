import { sizeItemOnPage } from "../../components/Tables/FAQ/FAQ.config";
import * as faqTypes from "../types/faqTypes";

const initialParams = {
    limit: sizeItemOnPage,
    page: "&page=0",
};

const initialState = {
    faq: { items: [], count: 0 },
    params: initialParams,
    loading: false,
    error: "",
};

export const faqReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case faqTypes.GET_FAQ_REQUEST:
        case faqTypes.POST_FAQ_REQUEST:
        case faqTypes.PUT_FAQ_REQUEST:
        case faqTypes.DELETE_FAQ_REQUEST: {
            return { ...state, loading: true };
        }

        // success
        case faqTypes.GET_FAQ_SUCCESS:
        case faqTypes.POST_FAQ_SUCCESS:
        case faqTypes.PUT_FAQ_SUCCESS:
        case faqTypes.DELETE_FAQ_SUCCESS: {
            return { ...state, faq: payload, loading: false, error: "" };
        }

        // error
        case faqTypes.GET_FAQ_ERROR:
        case faqTypes.POST_FAQ_ERROR:
        case faqTypes.PUT_FAQ_ERROR:
        case faqTypes.DELETE_FAQ_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        case faqTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };

        default:
            return state;
    }
};
