import * as servicesTypes from "../types/servicesTypes";

const initialState = {
    services: { items: [], totalCount: null },
    service: {},

    loading: false,
    error: "",
};

export const services = (state = initialState, { type, payload }) => {
    switch (type) {
        case servicesTypes.PUT_SPECIALIST_SERVICE_REQUEST:
        case servicesTypes.GET_ALL_SPECIALIST_SERVICES_REQUEST: {
            return { ...state, loading: true };
        }

        case servicesTypes.PUT_SPECIALIST_SERVICE_SUCCESS:
        case servicesTypes.GET_ALL_SPECIALIST_SERVICES_SUCCESS: {
            return { ...state, services: payload, loading: false, error: "" };
        }

        case servicesTypes.PUT_SPECIALIST_SERVICE_ERROR:
        case servicesTypes.GET_ALL_SPECIALIST_SERVICES_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        default:
            return state;
    }
};
