import { get, put, destroy, post } from "../api";

export const getAllAccountRequest = async (params = "") => {
    const url = `/admin/profiles` + params;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const getAccountRequest = async (id, role) => {
    const url = `/admin/profile/${id}/${role}`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const putAccountRequest = async (id, reqData = {}) => {
    const url = `/admin/profile/${id}`;

    try {
        const { data } = await put(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const putBlockAccountRequest = async (id, reqData = {}) => {
    const url = `/admin/profile/${id}/block`;

    try {
        const { data } = await put(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const deleteAccountAvatarRequest = async (id) => {
    const url = `/admin/profile/${id}/photo`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const deleteAccountRequest = async (id) => {
    const url = `/admin/profile/${id}`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const deleteAccountHardRequest = async (id) => {
    const url = `/admin/profile/${id}/hard`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

// post new user
export const postNewUserRequest = async (reqData = {}) => {
    const url = `/admin/new-user`;

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};
