import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    Fragment,
    memo,
    useEffect,
    useRef,
    useState,
    useLayoutEffect,
} from "react";
import { useChat } from "../use-chat";

import LazyImage from "../../../components/LazyImage/LazyImage";
import ChatsEmptyResult from "../SupportChatEmpty/SupportChatEmpty";

import { Icon } from "../../../components/Image/Image";
import { typeOfLazeImage } from "../../../components/LazyImage/LazyImage.constants";

import { generateItems } from "../SupportChat.utils";
import { selectedAdditionals, servicesData } from "../SupportChat.config";
import { supportSelector } from "../../../redux/selectors/supportSelectors";
import { myAccountDataSelector } from "../../../redux/selectors/myAccountSelectors";
import { openModalActionCreator } from "../../../redux/actions/modalActions";
import {
    disableSupportChat,
    jonToSupportChatTicket,
    postSupportChatFile,
} from "../../../redux/operations/supportOperations";

import { clearAllParamsSupportChat } from "../../../redux/actions/supportActions";

import "./SupportChatBody.scss";

const SupportChatBody = () => {
    const inputRef = useRef(null);
    const chatParent = useRef(null);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const { id: chatId } = useParams();
    const { questionId: questionId } = useParams();
    const { _id: userId } = useSelector(myAccountDataSelector);
    const { supportChat } = useSelector(supportSelector);
    const { messages, isDisabled } = supportChat;

    const [isOpen, setIsOpen] = useState(null);
    const [scrollsCount, setScrollsCount] = useState(0);
    const { setMessageText, sendMessage, messageText } = useChat(setIsOpen);

    const [messagesList, setMessagesList] = useState([]);

    const renderChatServices = (selected, icon) => {
        switch (selected) {
            case "file":
                return (
                    <Fragment key={selected}>
                        <label
                            className="support-chat_input_services-item"
                            htmlFor={selected}
                        >
                            <Icon type={icon} />
                        </label>
                        <input
                            accept="image/png, image/gif, image/jpeg"
                            onClick={() => setIsOpen(null)}
                            onChange={handleAttachFile}
                            style={{ display: "none" }}
                            id={selected}
                            type="file"
                        />
                    </Fragment>
                );

            case "service":
                return (
                    <div
                        className="support-chat_input_services-item"
                        onClick={() => changeIsOpen(selected)}
                        key={selected}
                    >
                        <Icon type={icon} />
                    </div>
                );
        }
    };

    const handleKeyPress = ({ code }) => {
        if (code === "Enter" && messageText.trim()) return sendMessage();
    };

    const handleService = () => {
        setIsOpen(null);
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    type: "deactivate",
                    title: "chat",
                    action: () =>
                        dispatch(disableSupportChat(chatId, questionId)),
                },
            })
        );
    };
    const changeIsOpen = (selected) => {
        if (isOpen === selected) return setIsOpen(null);
        setIsOpen(selected);
    };

    const handleAttachFile = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];

        if (file) {
            reader.onloadend = () =>
                dispatch(postSupportChatFile(chatId, file));
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        dispatch(jonToSupportChatTicket(questionId, chatId));

        return () => dispatch(clearAllParamsSupportChat());
    }, [questionId, chatId]);

    useLayoutEffect(() => {
        if (messages)
            setMessagesList(
                generateItems(
                    messages.items,
                    null,
                    userId,
                    null,
                    null,
                    setScrollsCount,
                    messages.carousel
                )
            );
    }, [messages]);

    useLayoutEffect(() => {
        const domNode = chatParent.current;
        if (domNode)
            domNode.scrollTo({
                top: domNode.scrollHeight,
            });
    }, [messagesList, scrollsCount, chatId]);

    return (
        <div className="support-chat">
            <div className="support-chat__header">
                <div
                    title={t("online")}
                    className="support-chat__header_img-active "
                >
                    <LazyImage
                        type={typeOfLazeImage.content}
                        options={{
                            className: "support-chat__header_avatar",
                            alt: "content",
                        }}
                    />
                </div>
                <div className="support-chat__header_info">
                    <div className="support-chat__header_title">
                        {t("support.chat.support")}
                    </div>
                    <div className="support-chat__header_status">
                        {t("online")}
                    </div>
                </div>
            </div>
            <div ref={chatParent} className="support-chat_body">
                {messages.items.length > 0 ? (
                    messagesList
                ) : (
                    <ChatsEmptyResult />
                )}
            </div>
            <div className="support-chat_input">
                <div
                    className={`support-chat_input_inner${
                        isOpen ? "-active" : ""
                    }`}
                >
                    {isDisabled ? (
                        <span className="support-chat_input_inner_finished">
                            {t("support.chat.chat-finished")}
                        </span>
                    ) : (
                        <>
                            {selectedAdditionals.map(({ icon, selected }) =>
                                renderChatServices(selected, icon)
                            )}
                            <div className="support-chat_input_body">
                                <input
                                    value={messageText}
                                    className="support-chat_input_body_input"
                                    placeholder={t(
                                        "support.chat.write-message"
                                    )}
                                    ref={inputRef}
                                    onKeyPress={handleKeyPress}
                                    onChange={(e) =>
                                        setMessageText(e.target.value)
                                    }
                                />
                                <button
                                    className="support-chat_input_body_img"
                                    disabled={!messageText}
                                    onClick={() =>
                                        messageText.trim() && sendMessage()
                                    }
                                >
                                    <Icon type="sendMessage" />
                                </button>
                            </div>
                        </>
                    )}
                </div>
                {isOpen === "service" && (
                    <div
                        className="support-chat_input_list_item"
                        onClick={handleService}
                    >
                        <div className="support-chat_input_list_item_icon">
                            <Icon type={servicesData.icon} />
                        </div>
                        <div className="support-chat_input_list_item_title">
                            {t(servicesData.title)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(SupportChatBody);
