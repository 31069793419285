import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { authSelector } from "../../redux/selectors/authSelectors";

import Navigation from "./Navigation/Navigation";

import "./Layout.scss";

const Layout = () => {
    const { token } = useSelector(authSelector);

    return (
        <div className="layout_container">
            {token && <Navigation />}
            {Outlet()}
        </div>
    );
};
export default Layout;
