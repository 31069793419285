import { useEffect } from "react";
import { sizeTagsOnPage, tagsTitle } from "./tags.config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
    deleteTag,
    getAllTags,
} from "../../../redux/operations/tagsOperations";
import { allTagsSelector } from "../../../redux/selectors/tagsSelectors";
import { openModalActionCreator } from "../../../redux/actions/modalActions";

import Tag from "./Tag/Tag";

import "./index.scss";

const Tags = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { items } = useSelector(allTagsSelector);
    useEffect(() => {
        dispatch(getAllTags(sizeTagsOnPage));
    }, [dispatch]);

    const handleOnAdd = () => {
        dispatch(openModalActionCreator({ typeModal: "ADD_TAG", details: {} }));
    };

    const handleOnEdit = (tag) => {
        dispatch(
            openModalActionCreator({ typeModal: "EDIT_TAG", details: tag })
        );
    };

    const handleOnDelete = (name, _id) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    _id,
                    title: name,
                    action: () => dispatch(deleteTag(_id)),
                },
            })
        );
    };
    return (
        <div className="tags-conatiner">
            <h1 className="tags-conatiner__title">{t("tags")}</h1>
            <table className="tags">
                <thead className="tags__header">
                    <tr className="tags__header_item">
                        {tagsTitle.map((title, index) => (
                            <th
                                className="tags__title"
                                key={`${title} + ${index}`}
                            >
                                {t(title)}
                            </th>
                        ))}
                        <th className="tags__title">
                            <button className="tags__btn" onClick={handleOnAdd}>
                                {t("services.tags.add")}
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody className="tags__body">
                    {items.length > 0 &&
                        items.map((tag) => (
                            <Tag
                                {...tag}
                                key={tag._id}
                                onEdit={() => handleOnEdit(tag)}
                                onDelete={() =>
                                    handleOnDelete(tag.name, tag._id)
                                }
                            />
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default Tags;
