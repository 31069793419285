import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    getBlogList,
    deleteBlog,
    editPageBlog,
} from "../../../redux/operations/blogOperations";
import {
    blogListSelector,
    allBlogParamsSelector,
} from "../../../redux/selectors/blogSelectors";
import { openModalActionCreator } from "../../../redux/actions/modalActions";
import { getAllCategory } from "../../../redux/operations/categoryOperations";

import { blogTitle, sizeBlogItemsOnPage } from "./blog.config";
import BlogItem from "./BlogItem/BlogItem";
import Pagination from "../../Pagination/Pagination";

import "./Blog.scss";

const Blog = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const blogList = useSelector(blogListSelector);
    const { page } = useSelector(allBlogParamsSelector);

    useEffect(() => {
        dispatch(getBlogList());
    }, [blogList.count, dispatch]);

    useEffect(() => {
        dispatch(getAllCategory());
    }, [dispatch]);

    const handleOnAdd = () => {
        dispatch(
            openModalActionCreator({ typeModal: "ADD_BLOG", details: {} })
        );
    };

    const handleOnEdit = (item) => {
        dispatch(
            openModalActionCreator({ typeModal: "EDIT_BLOG", details: item })
        );
    };

    const handleOnDelete = (id) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    id,
                    title: "blog",
                    action: () => dispatch(deleteBlog(id)),
                },
            })
        );
    };

    return (
        <div>
            <table className="blog">
                <thead className="blog__header">
                    <tr className="blog__header_item">
                        {blogTitle.map((title, index) => (
                            <th className="blog__title" key={index + title}>
                                {t(title)}
                            </th>
                        ))}
                        <th className="blog__title">
                            <button className="blog__btn" onClick={handleOnAdd}>
                                {t("blog.add")}
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {blogList.items.map((item) => (
                        <BlogItem
                            onEdit={() => handleOnEdit(item)}
                            onDelete={() => handleOnDelete(item._id)}
                            key={item._id}
                            titleTranslations={item.category.titleTranslations}
                            {...item}
                        />
                    ))}
                </tbody>
            </table>
            <Pagination
                getNextPageItems={(e) => dispatch(editPageBlog(e))}
                sizeItemOnPage={sizeBlogItemsOnPage}
                items={blogList}
                isStartedPage={page}
                elementType="page"
            />
        </div>
    );
};

export default Blog;
