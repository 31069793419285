import { t } from "i18next";
import CurrencyInput from "react-currency-input-field";
import { Controller } from "react-hook-form";

import "./index.scss";

const FieldSet = ({
    name = "",
    inputOptionsSecond = {},
    errorFirstOption = "",
    errorSecondOption = "",
    placeholderOptionsFirst = {},
    placeholderOptionsSecond = {},
    labelOptionsFirst = "",
    labelOptionsSecond = "",
    handleChangeOptionSecond = () => {},
    handleDeleteFieldset = () => {},
    control,
    currencyInputName,
}) => {
    return (
        <fieldset className="fieldset" name={name}>
            <div className="fieldset__inner">
                <div className="fieldset__inner-description">
                    <div className="fieldset__inner-description_item">
                        {t(labelOptionsFirst)}
                    </div>
                    <div className="fieldset__inner-description_item">
                        {t(labelOptionsSecond)}
                    </div>
                </div>
                <div className="fieldset__inner-value">
                    <Controller
                        name={currencyInputName}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <CurrencyInput
                                id="currency-promotion-input"
                                placeholder={placeholderOptionsFirst}
                                allowNegativeValue={false}
                                value={value}
                                decimalsLimit={2}
                                onValueChange={onChange}
                                decimalSeparator=","
                                groupSeparator="."
                                disableAbbreviations={true}
                                className={`fieldset__inner-value_input${
                                    errorFirstOption ? "--error" : ""
                                }`}
                            />
                        )}
                    />
                    <input
                        {...inputOptionsSecond}
                        onChange={handleChangeOptionSecond}
                        placeholder={placeholderOptionsSecond}
                        className={`fieldset__inner-value_input${
                            errorSecondOption ? "--error" : ""
                        }`}
                    />
                </div>
            </div>
            <button
                onClick={handleDeleteFieldset}
                className="fieldset__inner-close_btn"
            >
                &times;
            </button>
        </fieldset>
    );
};

export default FieldSet;
