const promotionTitles = ["identifier", "promotion-type", "description"];

const promotionTypes = {
    TOP_CATEGORY: {
        label: "promotion.types.top-category",
        value: "TOP_CATEGORY",
    },
    BEAUTY_NAME: {
        label: "promotion.types.beauty-name",
        value: "BEAUTY_NAME",
    },
    TOP: {
        label: "promotion.types.top",
        value: "TOP",
    },
};

const sizeItemOnPage = 5;

export { promotionTitles, sizeItemOnPage, promotionTypes };
