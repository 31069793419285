import { get, post, destroy, put } from "../api";

export const getFAQ = async (params = "") => {
    const url = `/faq` + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
export const postFAQ = async (reqData = {}) => {
    const url = `/faq`;

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const putFAQ = async (id = "", reqData = {}) => {
    const url = `/faq/${id}`;

    try {
        const { data } = await put(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deleteFAQ = async (id = "") => {
    const url = `faq/${id}`;

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};
