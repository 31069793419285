import * as myAccountTypes from "../types/myAccountTypes";

const initialState = {
    loading: false,
    error: "",
    data: {},
};

export const myAccountReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case myAccountTypes.GET_USER_REQUEST: {
            return { ...state, loading: true };
        }

        case myAccountTypes.GET_USER_SUCCESS: {
            return { ...state, loading: false, data: payload };
        }

        case myAccountTypes.GET_USER_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        // clear account data
        case myAccountTypes.CLEAR_ACCOUNT_DATA: {
            return initialState;
        }

        default:
            return state;
    }
};
