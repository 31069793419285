const book = Object.freeze({
    home: "/",
    accounts: "/accounts",
    support: "/support",
    supportChat: "/support/chat/:id/:questionId",
    flags: "/flags",
    faq: "/faq",
    accountsItem: "/accounts/:role/:id",
    services: "/services",
    login: "/login",
    complaints: "/complaints",
    complaintsType: "/complaints/:types",
    promotion: "/promotion",
    listOfAdminUsers: "/list-of-admin-users",
    blog: "/blog",
    promotionsHistory: "/promotions-history",
});

export default book;
