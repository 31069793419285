import * as locationTypes from "../types/locationTypes";

export const getAllCitiesPending = () => ({
    type: locationTypes.GET_ALL_CITIES_PENDING,
});

export const getAllCitiesFulfilled = (data) => ({
    type: locationTypes.GET_ALL_CITIES_FULFILLED,
    payload: data,
});

export const getAllCitiesRejected = (data) => ({
    type: locationTypes.GET_ALL_CITIES_REJECTED,
    payload: data,
});

export const getAllDistrictsPending = () => ({
    type: locationTypes.GET_ALL_DISTRICTS_PENDING,
});

export const getAllDistrictsFulfilled = (data) => ({
    type: locationTypes.GET_ALL_DISTRICTS_FULFILLED,
    payload: data,
});

export const getAllDistrictsRejected = (data) => ({
    type: locationTypes.GET_ALL_DISTRICTS_REJECTED,
    payload: data,
});
