import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { websocketsSelector } from "../../websockets/websockets.selectors";
import { socketEvents } from "../../websockets/websockets.constants";
import { supportSelector } from "../../redux/selectors/supportSelectors";

import { editSupportChat } from "../../redux/operations/supportOperations";

export const useChat = (setIsOpen) => {
    const dispatch = useDispatch();
    const { socket } = useSelector(websocketsSelector);
    const {
        supportChat: { _id: chatId },
    } = useSelector(supportSelector);

    const [messageText, setMessageText] = useState("");

    useEffect(() => {
        if (socket) {
            socket.on(socketEvents.RECEIVE_MESSAGE, (message) => {
                dispatch(editSupportChat(message));
            });
        }

        return () => {
            if (socket && socket.off) {
                socket.off(socketEvents.RECEIVE_MESSAGE);
            }
        };
    }, [socket]);

    useEffect(() => {
        if (chatId) {
            setMessageText("");
            setIsOpen(null);
        }
    }, [chatId]);

    const sendMessage = () => {
        socket.emit(socketEvents.SEND_MESSAGE, { chatId, messageText });
        setMessageText("");
    };

    return { sendMessage, messageText, setMessageText };
};
