import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import PropTypes from "prop-types";

import { getLanguage } from "../../../../helpers/language";

import "./BlogItem.scss";

const BlogItem = ({ _id, titleTranslations, onEdit, onDelete, title }) => {
    const { t } = useTranslation();
    const { value: language } = getLanguage();

    const sanitizedTitle = () => ({
        __html: DOMPurify.sanitize(title[language]),
    });

    return (
        <tr className="blog-item">
            <td>{_id}</td>
            <td>{titleTranslations && titleTranslations[language]}</td>
            <td dangerouslySetInnerHTML={sanitizedTitle()}></td>
            <td className="blog-item__options">
                <button className="blog-item__options--edit" onClick={onEdit}>
                    {t("btn-edit")}
                </button>
                <button
                    className="blog-item__options--delete"
                    onClick={onDelete}
                >
                    {t("btn-delete")}
                </button>
            </td>
        </tr>
    );
};

BlogItem.propTypes = {
    _id: PropTypes.string,
    titleTranslations: PropTypes.shape({
        en: PropTypes.string,
        ru: PropTypes.string,
        tr: PropTypes.string,
    }),
    title: PropTypes.shape({
        en: PropTypes.string,
        ru: PropTypes.string,
        tr: PropTypes.string,
    }),
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
};

export default BlogItem;
