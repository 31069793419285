import * as requests from "../../services/requests/services";

import { changeGlobalLoader } from "../../components/Loader/loader.actions";
import { closeModalActionCreator } from "../actions/modalActions";
import { setToastifyStatus } from "../actions/toastifyActions";
import * as servicesActions from "../actions/servicesActions";

import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";
import { getSpecialistServices } from "../selectors/servicesSelectors";

export const getAllCategory = (id) => (dispatch) => {
    dispatch(servicesActions.getSpecialistServicesRequest());
    dispatch(changeGlobalLoader({ status: true }));

    return requests
        .getServicesRequest(id)
        .then(({ items, totalCount }) => {
            dispatch(
                servicesActions.getSpecialistServicesSuccess({
                    items,
                    totalCount,
                })
            );
            dispatch(changeGlobalLoader({ status: false }));
        })
        .catch(({ error }) => {
            dispatch(servicesActions.getSpecialistServicesError(error));
        });
};

export const putSpecialistService =
    (id, serviceData) => (dispatch, getState) => {
        dispatch(servicesActions.putSpecialistServiceRequest());

        const {
            services: { items },
        } = getSpecialistServices(getState());

        return requests
            .putServiceRequest(id, serviceData)
            .then((data) => {
                const newItems = items.map((body) =>
                    body._id === id ? data : body
                );
                dispatch(
                    servicesActions.putSpecialistServiceSuccess({
                        items: newItems,
                    })
                );
                dispatch(closeModalActionCreator());
                dispatch(
                    setToastifyStatus({
                        title: toastifySuccessMessages.PUT_SPEICALISTS_SERVICE,
                        type: "success",
                    })
                );
            })
            .catch(({ error }) =>
                dispatch(servicesActions.putSpecialistServiceError(error))
            );
    };
