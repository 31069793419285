import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openModalActionCreator } from "../../../redux/actions/modalActions";
import {
    deleteComplaintsUser,
    editPageAllComplaintsUser,
    getAllComplaintsUser,
} from "../../../redux/operations/complaintOperations";

import { userComplaints } from "./user-complaints.config";
import { sizeItemOnPage } from "../UsersComplaints/users-complaints.config";
import {
    allComplaintParamsSelector,
    allComplaintsSelector,
    allComplaintsUserSelector,
} from "../../../redux/selectors/complaintSelectors";

import Pagination from "../../Pagination/Pagination";
import UserComplaint from "./UserComplaint/UserComplaint";

import "./index.scss";
import { useEffect } from "react";
import { clearAllParams } from "../../../redux/actions/complaintActions";
import NoUserComplaints from "./NoUserComplaints/NoUserComplaints";

const UserComplaintsTable = ({ id }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { page } = useSelector(allComplaintParamsSelector);
    const { loading } = useSelector(allComplaintsSelector);
    const { items, count } = useSelector(allComplaintsUserSelector);

    useEffect(() => {
        dispatch(getAllComplaintsUser(`&user=${id}`));

        return () => dispatch(clearAllParams());
    }, []);

    const handleOnDelete = (_id, title) => {
        dispatch(
            openModalActionCreator({
                typeModal: "DELETE",
                details: {
                    title: title,
                    action: () => dispatch(deleteComplaintsUser(_id)),
                },
            })
        );
    };

    return (
        <>
            <table className="user-complaints">
                <thead className="user-complaints__header">
                    <tr className="user-complaints__header_item">
                        {userComplaints.map(({ field, key }) => (
                            <th
                                key={field + key}
                                className="user-complaints__header_item_title"
                            >
                                {key && t(`complaints.title.${field}`)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="user-complaints__content">
                    {items.length > 0 && !loading
                        ? items.map(({ _id, text, type, creator, user }) => (
                              <UserComplaint
                                  _id={_id}
                                  key={_id}
                                  user={user}
                                  text={text}
                                  type={type}
                                  creator={creator}
                                  onDelete={handleOnDelete}
                              />
                          ))
                        : ""}
                </tbody>
            </table>
            {!items.length > 0 || loading ? <NoUserComplaints /> : ""}
            {items?.length > 0 && (
                <Pagination
                    isStartedPage={page}
                    items={{ items: items, count: count }}
                    sizeItemOnPage={sizeItemOnPage}
                    getNextPageItems={(e) =>
                        dispatch(editPageAllComplaintsUser(id, e))
                    }
                    elementType="page"
                />
            )}
        </>
    );
};

export default UserComplaintsTable;
