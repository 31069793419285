import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../../shared/Input/Input";
import Button from "../../../shared/Button/Button";
import LocationSelect from "../../../shared/LocationSelect/LocationSelect";

import { closeModalActionCreator } from "../../../redux/actions/modalActions";
import {
    deleteAccountAvatar,
    getAccount,
    putAccount,
} from "../../../redux/operations/accountOperations";
import { getAllDistricts } from "../../../redux/operations/locationOperations";
import {
    accountSelector,
    allAccountSelector,
} from "../../../redux/selectors/accountSelectors";
import { citiesLoaderSelector } from "../../../redux/selectors/locationSelectors";

import { customerFormSchema } from "./CustomerForm.Schema";
import { customerFormConfig } from "./CustomerForm.config";
import { isObjectEqual } from "../../../helpers/isObjectEqual";
import { normalizePutUserData } from "../AdminForm/AdminForm.helpers";

import "./CustomerForm.scss";

const CustomerForm = ({ details: { _id, role, city } }) => {
    const { cities, districts } = useSelector(citiesLoaderSelector);
    const { loading } = useSelector(allAccountSelector);
    const account = useSelector(accountSelector);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        reset,
        control,
        register,
        resetField,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "all",
        defaultValues: useMemo(() => {
            return account;
        }, [account]),
        resolver: yupResolver(customerFormSchema),
    });

    const { isDirty } = useFormState({ control });
    const [selectedCity, setSelectedCity] = useState({
        state_code: "",
        name: city,
    });

    useEffect(() => {
        dispatch(getAccount(_id, role));
    }, [dispatch, _id, role]);

    useEffect(() => {
        reset(account);
    }, [account, reset]);

    useEffect(() => {
        if (selectedCity?.state_code || city)
            dispatch(getAllDistricts(selectedCity.state_code, city));
    }, [selectedCity, city]);

    const handleFormSubmit = (data) => {
        if (isDirty) {
            dispatch(putAccount(_id, normalizePutUserData(data)));
        }

        dispatch(closeModalActionCreator());
        reset(data);
    };

    const handleSelectCity = (currentCity) => {
        if (
            isObjectEqual(currentCity, selectedCity) |
            (currentCity.name === selectedCity.name)
        )
            return;
        resetField("district");
        setSelectedCity(currentCity);
    };

    const handleCloseModal = () => {
        dispatch(closeModalActionCreator());
    };
    const handleDeleteAvatar = () => {
        account.avatar && dispatch(deleteAccountAvatar(_id));
    };

    return (
        <form
            className="customer_form"
            onSubmit={handleSubmit(handleFormSubmit)}
        >
            <div className="customer_form__content">
                <h2 className="customer_form__title">
                    {t("edit-user")} ({t("customer")})
                </h2>
                <div className="customer_form__items">
                    <div className="customer__item">
                        <label className="customer__item_label">
                            {t("photo")}
                        </label>
                        <div className="customer__upload">
                            {account.avatar ? (
                                <img
                                    className="customer__upload_preview"
                                    src={account.avatar}
                                    alt="avatar"
                                />
                            ) : (
                                <div className="customer__upload_default" />
                            )}
                        </div>
                        <button
                            type="button"
                            onClick={handleDeleteAvatar}
                            className={`customer__item_btn--delete ${
                                account.avatar ? "uploaded" : "empty"
                            }`}
                        >
                            {t("btn-delete-photo")}
                        </button>
                    </div>
                    {customerFormConfig.map(
                        ({
                            type,
                            label,
                            value,
                            placeholder,
                            placeholderPhoneNumber,
                            placeholderDialCode,
                        }) => {
                            if (type === "phone") {
                                return (
                                    <Input
                                        type={type}
                                        key={value}
                                        label={label}
                                        inputOptionsFirst={register(
                                            "phone.dialCode"
                                        )}
                                        inputOptionsSecond={register(
                                            "phone.number"
                                        )}
                                        placeholderDialCode={
                                            placeholderDialCode
                                        }
                                        placeholderPhoneNumber={
                                            placeholderPhoneNumber
                                        }
                                        error={errors?.phone}
                                        errorDialCode={
                                            errors?.phone?.dialCode?.message
                                        }
                                        errorPhoneNumber={
                                            errors?.phone?.number?.message
                                        }
                                    />
                                );
                            }
                            return (
                                <Controller
                                    name={value}
                                    control={control}
                                    key={value + label}
                                    render={({ field }) => (
                                        <Input
                                            type={type}
                                            label={label}
                                            inputChangeOptions={field}
                                            placeholderText={placeholder}
                                            error={errors[value]?.message}
                                        />
                                    )}
                                />
                            );
                        }
                    )}

                    <div className="customer__item">
                        <label className="customer__item_label">
                            {t("services-specialist-city")}
                        </label>
                        <Controller
                            name="city"
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={cities}
                                    error={errors.city?.message}
                                    onSelect={(city) => {
                                        handleSelectCity(city);
                                        onChange(city.name);
                                    }}
                                    onBlur={(city) => onBlur(city.name)}
                                />
                            )}
                        />
                    </div>
                    <div className="customer__item">
                        <label className="customer__item_label">
                            {t("services-specialist-district")}
                        </label>
                        <Controller
                            control={control}
                            name="district"
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={districts}
                                    onSelect={(district) =>
                                        onChange(district.name)
                                    }
                                    onBlur={(district) => onBlur(district.name)}
                                    error={errors.district?.message}
                                />
                            )}
                        />
                    </div>
                    <div className="customer__item">
                        <label className="customer__item_label">
                            {t("address")}
                        </label>
                        <input
                            placeholder={t("address-placeholder")}
                            className={`customer__item_input ${
                                errors?.address?.message && "error"
                            }`}
                            {...register("address")}
                        />
                        {errors?.address?.message && (
                            <span className="customer__item-error">
                                {t(errors?.address?.message)}
                            </span>
                        )}
                    </div>

                    <div className="customer__item">
                        <label className="customer__item_label">
                            {t("popular")}
                        </label>
                        <select
                            className={`customer__item_select ${
                                errors.category ? "error" : ""
                            }`}
                            {...register("popular")}
                        >
                            <option value={true}>{t("yes")}</option>
                            <option value={false}>{t("no")}</option>
                        </select>
                        {errors.popular && (
                            <span className="customer__item-error">
                                {t(errors.popular.message)}
                            </span>
                        )}
                    </div>
                    <div className="customer__service">
                        <Button
                            onClick={handleCloseModal}
                            buttonStyle={"btn--red"}
                        >
                            {t("btn-cancel")}
                        </Button>
                        <Button
                            type="submit"
                            buttonStyle={"btn--green"}
                            disabled={!isValid}
                            loading={loading}
                        >
                            {t("btn-save")}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CustomerForm;
