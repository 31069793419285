import * as supportActions from "../actions/supportActions";
import * as supportRequests from "../../services/requests/support";

import {
    supportSelector,
    supportTextSelector,
} from "../selectors/supportSelectors";
import { closeModalActionCreator } from "../actions/modalActions";
import { changeGlobalLoader } from "../../components/Loader/loader.actions";

import { setToastifyStatus } from "../actions/toastifyActions";
import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";
import { sizeItemOnPage } from "../../components/Tables/Support/Support.config";

export const deactivateSupportTicket = (id) => (dispatch, getState) => {
    dispatch(changeGlobalLoader({ status: true, button: "" }));
    dispatch(supportActions.deactivateSupportTicketRequest());

    const {
        supportTickets: { items, count },
    } = supportSelector(getState());

    return supportRequests
        .deactivateSupportTicket(id)
        .then(() => {
            const updatedSupportTicketsList = items.map((ticket) =>
                ticket._id === id ? { ...ticket, active: false } : ticket
            );
            const newSupportList = {
                count: count,
                items: updatedSupportTicketsList,
            };

            dispatch(
                supportActions.deactivateSupportTicketSuccess(newSupportList)
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DEACTIVATE_SUPPORT_TICKET,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            setToastifyStatus({
                title: error,
                type: "error",
            });
            dispatch(supportActions.deactivateSupportTicketError(error));
            dispatch(closeModalActionCreator());
        })
        .finally(() =>
            dispatch(changeGlobalLoader({ status: false, button: "" }))
        );
};
export const getSupportTickets =
    (params = "") =>
    (dispatch) => {
        dispatch(supportActions.getSupportTicketsRequest());
        dispatch(changeGlobalLoader(true));

        return supportRequests
            .getSupportTickets(`?limit=${sizeItemOnPage}` + params)
            .then(({ items, totalCount }) => {
                dispatch(
                    supportActions.getSupportTicketsSuccess({
                        items: items,
                        count: totalCount,
                    })
                );
            })
            .catch((error) =>
                dispatch(supportActions.getSupportTicketsError(error))
            )
            .finally(() => {
                dispatch(closeModalActionCreator());
                dispatch(changeGlobalLoader(false));
            });
    };

export const getSupportChat = (id) => (dispatch) => {
    dispatch(supportActions.getSupportChatRequest());
    dispatch(changeGlobalLoader(true));

    return supportRequests
        .getSupportChat(id)
        .then((chat) => {
            const { messages } = chat;
            const { items, totalCount, carousel } = messages;

            const newMessages = { items, carousel, count: totalCount };
            const newChat = {
                ...chat,
                messages: newMessages,
            };

            dispatch(supportActions.getSupportChatSuccess(newChat));
        })
        .catch((error) => dispatch(supportActions.getSupportChatError(error)))
        .finally(() => dispatch(changeGlobalLoader(false)));
};

// receive new message
export const editSupportChat = (message) => (dispatch, getState) => {
    const { supportChat } = supportSelector(getState());
    const { items, count, carousel } = supportChat.messages;

    const updatedMessages = {
        carousel: message?.image ? [...carousel, message] : carousel,
        items: [...items, message],
        count: count + 1,
    };

    const updatedChat = { ...supportChat, messages: updatedMessages };

    dispatch(supportActions.getSupportChatSuccess(updatedChat));
};

export const postSupportChatFile = (id, file) => (dispatch) => {
    dispatch(supportActions.postSupportChatFileRequest());

    let formData = new FormData();
    formData.append("file", file);

    return supportRequests
        .postSupportChatFile(id, formData)
        .then((message) => {
            editSupportChat(message);
        })
        .catch((error) =>
            dispatch(supportActions.postSupportChatFileError(error))
        );
};

export const jonToSupportChatTicket = (questionId, chatId) => (dispatch) => {
    dispatch(supportActions.joinToSupportChatRequest());

    supportRequests
        .jonToSupportChat(questionId)
        .then(() => {
            supportActions.joinToSupportChatSuccess();
            dispatch(getSupportChat(chatId));
        })
        .catch((error) =>
            dispatch(supportActions.joinToSupportChatError(error))
        );
};

export const disableSupportChat =
    (chatId, ticketId) => (dispatch, getState) => {
        dispatch(supportActions.disableSupportChatRequest());
        const { supportChat } = supportSelector(getState());

        return supportRequests
            .disableSupportChat(chatId)
            .then(() => {
                const updateChat = { ...supportChat, isDisabled: true };
                dispatch(supportActions.disableSupportChatSuccess(updateChat));
                dispatch(deactivateSupportTicket(ticketId));
            })
            .catch((error) =>
                dispatch(supportActions.disableSupportChatError(error))
            );
    };

export const editPageSupportTickets = (page) => (dispatch, getState) => {
    const text = supportTextSelector(getState());
    dispatch(supportActions.setPage(`&page=${page}`));

    const url = text ? text + `&page=${page}` : `&page=${page}`;

    dispatch(getSupportTickets(url));
};

export const editSort =
    (type = "") =>
    (dispatch) => {
        dispatch(supportActions.setPage(`&page=0`));
        dispatch(supportActions.setText(type));
        dispatch(getSupportTickets(`${type}`));
    };
