import * as yup from "yup";
import { t } from "i18next";

export const subcategorySchema = yup.object().shape({
    category: yup.string().required("required"),
    remark: yup.string().trim().required("required"),
    titleTranslations: yup.object().shape({
        en: yup
            .string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        ru: yup
            .string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
        tr: yup
            .string()
            .trim()
            .required("required")
            .min(3, ({ min }) => t("validation-min", { min })),
    }),
});
