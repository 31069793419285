import enIcon from "../assets/images/language/en.svg";
import RuIcon from "../assets/images/language/ru.svg";
import TrIcon from "../assets/images/language/tr.svg";

export const languageList = {
    en: { label: "ENG", value: "en", img: enIcon },
    ru: { label: "RU", value: "ru", img: RuIcon },
    tr: { label: "TR", value: "tr", img: TrIcon },
};

const checkLanguageForAvailability = (language = "") => {
    const isAvailable = Object.keys(languageList).includes(language);
    return isAvailable ? languageList[language] : languageList["en"];
};

export const getLanguage = () => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    const selectedLanguage = localStorage.getItem("language");
    const language = selectedLanguage || browserLanguage;

    return checkLanguageForAvailability(language.substring(0, 2));
};
