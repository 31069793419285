import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    sizeHistoryItemsOnPage,
    promotionsHistoryTitle,
} from "./promotions-history.config";
import {
    promotionSelector,
    promotionHistoryParamsSelector,
} from "../../../redux/selectors/promotionSelectors";
import {
    getListOfBoughtPromotions,
    editPageBoughtPromotion,
} from "../../../redux/operations/promotionOperations";
import { clearAllHistoryParams } from "../../../redux/actions/promotionActions";

import PromotionsHistoryItem from "./PromotionsHistoryItem/PromotionsHistoryItem";
import Pagination from "../../Pagination/Pagination";

import "./PromotionsHistory.scss";

const PromotionsHistoryTable = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { boughtPromotions } = useSelector(promotionSelector);
    const { page } = useSelector(promotionHistoryParamsSelector);

    useEffect(() => {
        dispatch(getListOfBoughtPromotions());
        return () => dispatch(clearAllHistoryParams());
    }, [boughtPromotions.count, dispatch]);

    return (
        <div>
            <table className="promotions-history">
                <thead className="promotions-history__header">
                    <tr className="promotions-history__header_item">
                        {promotionsHistoryTitle.map((title, index) => (
                            <th
                                className="promotions-history__title"
                                key={index + title}
                            >
                                {t(`promotion.titles.${title}`)}
                            </th>
                        ))}
                        <th className="promotions-history__title"></th>
                    </tr>
                </thead>
                <tbody>
                    {boughtPromotions.items.map((item) => (
                        <PromotionsHistoryItem
                            key={item._id}
                            nameTranslations={item.promotion.nameTranslations}
                            {...item}
                        />
                    ))}
                </tbody>
            </table>
            <Pagination
                getNextPageItems={(e) => dispatch(editPageBoughtPromotion(e))}
                sizeItemOnPage={sizeHistoryItemsOnPage}
                items={boughtPromotions}
                isStartedPage={page}
                elementType="page"
            />
        </div>
    );
};

export default PromotionsHistoryTable;
