import { websocketsTypes } from "./websockets.types";

const initialState = { onlineParticipants: [], socketId: null, socket: null };

export const websockets = (state = initialState, { type, payload }) => {
    switch (type) {
        case websocketsTypes.OPENING_WEBSOCKET_CONNECTION:
        case websocketsTypes.DISCONNECT_WEBSOCKET:
            return { ...state };

        case websocketsTypes.WEBSOCKET_CONNECTED:
            return { ...state, socket: payload, socketId: payload.id };

        case websocketsTypes.SET_USER_ONLINE_STATUS: {
            return { ...state, onlineParticipants: payload };
        }

        default:
            return state;
    }
};

export default websockets;
