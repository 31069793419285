import specialistServices from "./specilist-services.json";
import validation from "./validation.json";
import complaints from "./complaints.json";
import promotion from "./promotion.json";
import feedback from "./feedback.json";
import services from "./services.json";
import support from "./support.json";
import toastify from "./toastify.json";
import button from "./button.json";
import title from "./title.json";
import admin from "./admin.json";
import rest from "./rest.json";
import days from "./days.json";
import flags from "./flags.json";
import blog from "./blog.json";
import faq from "./faq.json";

const translation = {
    ...specialistServices,
    ...complaints,
    ...validation,
    ...promotion,
    ...feedback,
    ...services,
    ...support,
    ...toastify,
    ...button,
    ...admin,
    ...title,
    ...days,
    ...flags,
    ...rest,
    ...blog,
    ...faq,
};

export default translation;
