import * as faqActions from "../actions/faqActions";
import * as requests from "../../services/requests/faq";
import { sizeItemOnPage } from "../../components/Tables/FAQ/FAQ.config";
import { faqSelector } from "../selectors/faqSelectors";
import { closeModalActionCreator } from "../actions/modalActions";
import { toastifySuccessMessages } from "../../configs/toastifySuccessMessages";
import { setToastifyStatus } from "../actions/toastifyActions";

export const getFAQ =
    (params = "") =>
    (dispatch) => {
        dispatch(faqActions.getFAQRequest());

        return requests
            .getFAQ(`?limit=${sizeItemOnPage}` + params)
            .then(({ items, totalCount: count }) => {
                dispatch(faqActions.getFAQSuccess({ items, count }));
            })
            .catch(({ error }) => {
                dispatch(faqActions.getFAQError(error));
            });
    };

export const postFAQ = (data) => (dispatch, getState) => {
    dispatch(faqActions.postFAQRequest());
    const { faq } = faqSelector(getState());
    const { items, count } = faq;

    return requests
        .postFAQ(data)
        .then((item) => {
            const newItems = [...items, item];
            dispatch(
                faqActions.postFAQSuccess({
                    items: newItems,
                    count: count + 1,
                })
            );

            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.POST_FAQ,
                    type: "success",
                })
            );
        })
        .catch(({ error }) => {
            dispatch(faqActions.postFAQError(error));
        })
        .finally(() => dispatch(closeModalActionCreator()));
};

export const putFAQ = (id, data) => (dispatch, getState) => {
    dispatch(faqActions.putFAQRequest());
    const { faq } = faqSelector(getState());

    return requests
        .putFAQ(id, data)
        .then((response) => {
            const newItems = faq.items.map((body) =>
                body._id === id ? response : body
            );
            dispatch(
                faqActions.putFAQSuccess({
                    items: newItems,
                    count: faq.count,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.PUT_FAQ,
                    type: "success",
                })
            );
        })
        .catch((error) => dispatch(faqActions.putFAQError(error)));
};

export const deleteFAQ = (id) => (dispatch, getState) => {
    dispatch(faqActions.deleteFAQRequest());
    const { faq } = faqSelector(getState());

    return requests
        .deleteFAQ(id)
        .then(() => {
            const newItems = faq.items.filter(({ _id }) => _id !== id);
            dispatch(
                faqActions.deleteFAQSuccess({
                    items: newItems,
                    count: faq.count - 1,
                })
            );
            dispatch(closeModalActionCreator());
            dispatch(
                setToastifyStatus({
                    title: toastifySuccessMessages.DELETE_FAQ,
                    type: "success",
                })
            );
        })
        .catch((error) => dispatch(faqActions.deleteFAQError(error)));
};

export const editPageFAQ = (page) => (dispatch) => {
    dispatch(faqActions.setPage(`&page=${page}`));
    const url = `&page=${page}`;
    dispatch(getFAQ(url));
};
