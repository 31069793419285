import { useTranslation } from "react-i18next";

import "./index.scss";

const ScheduleWorkInfoItem = ({
    day = "",
    isWeekend = false,
    defaultTimeAM = "",
    defaultTimePM = "",
}) => {
    const { t } = useTranslation();
    return (
        <div className="schedule_work_info_item">
            <div className="schedule_work_info_item__day">
                {t(day.slice(0, 1) + day.slice(1).toLowerCase())}
            </div>
            {isWeekend ? (
                <div className="schedule_work_info_item__weekend">
                    {t("weekend")}
                </div>
            ) : (
                <div className="schedule_work_info_item__time">
                    <div className="schedule_work_info_item__time--am">
                        {new Date(defaultTimeAM).getHours()} AM
                    </div>
                    <div className="schedule_work_info_item__time--pm">
                        {new Date(defaultTimePM).getHours()} PM
                    </div>
                </div>
            )}
        </div>
    );
};

export default ScheduleWorkInfoItem;
