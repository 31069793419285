import { sizeItemOnPage } from "../../components/Tables/Accounts/accounts.config";
import * as accountTypes from "../types/accountTypes";

const initialState = {
    params: { limit: sizeItemOnPage, text: "", page: "&page=0" },
    allAccount: { items: [], count: 0 },
    currentAccount: {},

    loading: false,
    error: "",
};

export const accountReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case accountTypes.DELETE_ACCOUNT_AVATAR_REQUEST:
        case accountTypes.GET_ALL_ACCOUNT_REQUEST:
        case accountTypes.DELETE_ACCOUNT_REQUEST:
        case accountTypes.DELETE_ACCOUNT_HARD_REQUEST:
        case accountTypes.BLOCK_ACCOUNT_REQUEST:
        case accountTypes.GET_ACCOUNT_REQUEST:
        case accountTypes.POST_NEW_USER_REQUEST:
        case accountTypes.PUT_ACCOUNT_REQUEST: {
            return { ...state, loading: true };
        }

        // data all account

        case accountTypes.POST_NEW_USER_SUCCESS:
        case accountTypes.GET_ALL_ACCOUNT_SUCCESS:
        case accountTypes.DELETE_ACCOUNT_SUCCESS:
        case accountTypes.DELETE_ACCOUNT_HARD_SUCCESS:
        case accountTypes.BLOCK_ACCOUNT_SUCCESS: {
            return { ...state, allAccount: payload, loading: false, error: "" };
        }

        // data account
        case accountTypes.DELETE_ACCOUNT_AVATAR_SUCCESS:
        case accountTypes.GET_ACCOUNT_SUCCESS:
        case accountTypes.PUT_ACCOUNT_SUCCESS: {
            return {
                ...state,
                currentAccount: payload,
                loading: false,
                error: "",
            };
        }

        // error
        case accountTypes.POST_NEW_USER_ERROR:
        case accountTypes.DELETE_ACCOUNT_AVATAR_ERROR:
        case accountTypes.GET_ALL_ACCOUNT_ERROR:
        case accountTypes.DELETE_ACCOUNT_ERROR:
        case accountTypes.DELETE_ACCOUNT_HARD_ERROR:
        case accountTypes.BLOCK_ACCOUNT_ERROR:
        case accountTypes.GET_ACCOUNT_ERROR:
        case accountTypes.PUT_ACCOUNT_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        //set
        case accountTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };
        case accountTypes.SET_TEXT:
            return { ...state, params: { ...state.params, text: payload } };
        case accountTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialState.params };

        default:
            return state;
    }
};
