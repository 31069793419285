import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import { authSelector } from "../redux/selectors/authSelectors";

import { publicRouter, privateRoutes } from "./routers";
import Layout from "../components/Layout/Layout";
import routerBook from "./routerBook";

const Router = () => {
    const { token } = useSelector(authSelector);

    const getPrivateRouter = () =>
        privateRoutes.map(({ path, component }) => (
            <Route key={path} path={path} element={component} />
        ));
    const getPublicRouter = () =>
        publicRouter.map(({ path, component }) => (
            <Route key={path} path={path} element={component} />
        ));

    return (
        <Routes>
            <Route path={routerBook.main} element={<Layout />}>
                {token ? getPrivateRouter() : getPublicRouter()}
            </Route>
        </Routes>
    );
};

export default Router;
