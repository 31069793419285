import { Fragment } from "react";
import { t } from "i18next";
import dayjs from "dayjs";
import SupportChatMessage from "./SupportChatMessage/SupportChatMessage";

const convertDate = (date) => {
    const dateSplit = date && date.split(" ");
    const fullDay = date && `${t(`full-days.${dateSplit[0]}`)}`;
    const day = date && dateSplit[1];
    const month = date && `${t(`full-months.${dateSplit[2]}`)}`;
    const year = date && dateSplit[3];
    return { fullDay, day, month, year };
};

const renderMessages = (
    messages,
    participant,
    userId,
    orderId,
    taskId,
    callback,
    carousel
) => {
    let lastSenderId = undefined;

    return messages.reverse().map((message, index) => {
        let same = !lastSenderId || message.createdBy !== lastSenderId;
        lastSenderId = message.messageType !== "operation" && message.createdBy;

        if (index === messages.length - 1) {
            return renderMessage(
                message,
                same,
                participant,
                userId,
                orderId,
                taskId,
                callback,
                carousel
            );
        }

        return renderMessage(
            message,
            same,
            participant,
            userId,
            orderId,
            taskId,
            () => {},
            carousel
        );
    });
};

const renderMessage = (
    message,
    same = true,
    participant,
    userId,
    orderId,
    taskId,
    callback,
    carousel
) => {
    const { fullDay, day, month, year } = convertDate(message.date);
    return (
        <Fragment key={message._id + message?.createdAt}>
            {message.type ? (
                <span className="chat_body_date">
                    {fullDay}, {day} {month}, {year} {t("short-year")}
                </span>
            ) : (
                <SupportChatMessage
                    key={message._id}
                    participant={participant}
                    callback={callback}
                    carousel={carousel}
                    orderId={orderId}
                    userId={userId}
                    taskId={taskId}
                    same={same}
                    {...message}
                />
            )}
        </Fragment>
    );
};

const groupedDays = (messages) => {
    return messages.reduce((acc, message) => {
        const messageDay = dayjs(message.createdAt).format("dddd DD MMMM YYYY");

        if (acc[messageDay]) {
            return { ...acc, [messageDay]: acc[messageDay].concat([message]) };
        }
        return { ...acc, [messageDay]: [message] };
    }, {});
};

export const generateItems = (
    messages,
    participant,
    userId,
    orderId,
    taskId,
    callback,
    carousel
) => {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort(
        (firstMessage, secondMessage) =>
            dayjs(secondMessage, "YYYY-MM-DD").unix() -
            dayjs(firstMessage, "YYYY-MM-DD").unix()
    );
    const items = sortedDays.reduce((acc, date) => {
        const sortedMessages = days[date].sort(
            (firstMessage, secondMessage) =>
                new Date(secondMessage.createdAt) -
                new Date(firstMessage.createdAt)
        );
        return acc.concat([...sortedMessages, { type: "day", date }]);
    }, []);
    return renderMessages(
        items,
        participant,
        userId,
        orderId,
        taskId,
        callback,
        carousel
    );
};
