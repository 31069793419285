import PropTypes from "prop-types";

import ScheduleWorkInfoItem from "./ScheduleWorkInfoItem/ScheduleWorkInfoItem";
import { useEffect, useState } from "react";

import { ReactComponent as Close } from "../../assets/images/close-crosshair-gray.svg";
import { scheduleTime, scheduleWork } from "./schedule-work.config";

import "./index.scss";
import ScheduleModuleWorkItem from "./ShaduleModuleWorkItem/ShaduleModuleWorkItem";
import { useTranslation } from "react-i18next";

const ScheduleWork = ({
    onSaveScheduleData,
    onCloseSchedule,
    defaultSchedule,
    type,
}) => {
    const checkDefaultSchedule = () => {
        if (defaultSchedule.length) {
            const scheduleFromDefaultValues = [];

            scheduleWork.forEach((item) => {
                const itemFromDefaultValues = defaultSchedule.find(
                    (defaultItem) => defaultItem.day === item.day
                );

                if (itemFromDefaultValues) {
                    scheduleFromDefaultValues.push(itemFromDefaultValues);
                } else {
                    scheduleFromDefaultValues.push(item);
                }
            });
            return scheduleFromDefaultValues;
        }

        return scheduleWork;
    };

    const [schedule, setSchedule] = useState(checkDefaultSchedule());
    const { t } = useTranslation();

    useEffect(() => {
        if (defaultSchedule) setSchedule(checkDefaultSchedule());
    }, [defaultSchedule]);

    const setDayStatus = (day) => {
        const newSchedule = schedule.map((obj) =>
            obj.day === day
                ? {
                      ...obj,
                      isWeekend: !obj.isWeekend,
                      workingHours: [{ timeFrom: "8:00", timeTo: "17:00" }],
                  }
                : obj
        );
        setSchedule(newSchedule);
    };
    const setTimeAM = (day, time) => {
        const newSchedule = schedule.map((obj) => {
            return obj.day === day
                ? {
                      ...obj,
                      workingHours: [
                          {
                              timeFrom: time,
                              timeTo: obj.workingHours[0].timeTo,
                          },
                      ],
                  }
                : obj;
        });
        setSchedule(newSchedule);
    };
    const setTimePM = (day, time) => {
        const newSchedule = schedule.map((obj) =>
            obj.day === day
                ? {
                      ...obj,
                      workingHours: [
                          {
                              timeTo: time,
                              timeFrom: obj.workingHours[0].timeFrom,
                          },
                      ],
                  }
                : obj
        );
        setSchedule(newSchedule);
    };

    const onSave = (e) => {
        e.preventDefault();
        onSaveScheduleData(schedule);
        onCloseSchedule();
    };
    const onCancel = (e) => {
        e.preventDefault();
        setSchedule(scheduleWork);
    };
    const onClose = (e) => {
        e.preventDefault();
        onCloseSchedule();
    };

    return (
        <div className="schedule_work">
            <div className="schedule_work__content">
                <div className="schedule_work__header">
                    <h3 className="schedule_work__title">
                        {t("services-specialist-schedule")}
                    </h3>
                    {type === "list" ? (
                        ""
                    ) : (
                        <div
                            className="schedule_work__btn--close"
                            onClick={onClose}
                        >
                            <Close />
                        </div>
                    )}
                </div>
                <div className="schedule_work__table">
                    {type === "list"
                        ? schedule.map(({ day, isWeekend, workingHours }) => (
                              <ScheduleWorkInfoItem
                                  key={day}
                                  day={day}
                                  isWeekend={isWeekend}
                                  defaultTimeAM={workingHours[0]?.timeFrom}
                                  defaultTimePM={workingHours[0]?.timeTo}
                              />
                          ))
                        : schedule.map(({ day, isWeekend, workingHours }) => (
                              <ScheduleModuleWorkItem
                                  key={day}
                                  day={day}
                                  isWeekend={isWeekend}
                                  defaultTimeAM={workingHours[0]?.timeFrom}
                                  defaultTimePM={workingHours[0]?.timeTo}
                                  scheduleTimeAM={scheduleTime}
                                  scheduleTimePM={scheduleTime}
                                  onChangeTimePM={setTimePM}
                                  onChangeTimeAM={setTimeAM}
                                  onChangeDayStatus={setDayStatus}
                              />
                          ))}
                </div>
                {type === "list" ? (
                    ""
                ) : (
                    <div className="schedule_work__service">
                        <button
                            className="schedule_work__btn--save"
                            onClick={onSave}
                        >
                            {t("btn-save")}
                        </button>
                        <button
                            className="schedule_work__btn--cancel"
                            onClick={onCancel}
                        >
                            {t("btn-reset")}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
ScheduleWork.propTypes = {
    onSaveScheduleData: PropTypes.func,
    onCloseSchedule: PropTypes.func,
    defaultSchedule: PropTypes.array,
};
export default ScheduleWork;
