import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import { getAllCategory } from "../../../redux/operations/servicesOperations";
import { openModalActionCreator } from "../../../redux/actions/modalActions";
import { getSpecialistServices } from "../../../redux/selectors/servicesSelectors";

import SpecialistServiceItem from "./SpecialistServiceItem/SpecialistServiceItem";
import NoServices from "./NoServices/NoServices";

import "./index.scss";

const SpecialistServices = () => {
    const { services } = useSelector(getSpecialistServices);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getAllCategory(id));
    }, [dispatch, id]);

    const handleEditService = (service) => {
        dispatch(
            openModalActionCreator({
                typeModal: "EDIT_SPECIALIST_SERVICE",
                details: service,
            })
        );
    };
    return (
        <div className="specialist-services">
            <div className="specialist-services__header">
                {t("services-specialist-unique")} ID:{" "}
                <span className="specialist-services__header_id">{id}</span>
            </div>
            <div className="specialist-services__inner">
                {services?.items.length >= 1 ? (
                    services?.items.map((service) => (
                        <SpecialistServiceItem
                            key={service._id}
                            onEdit={() => handleEditService(service)}
                            {...service}
                        />
                    ))
                ) : (
                    <NoServices
                        text={t("services-specialist-unique-not-found")}
                    />
                )}
            </div>
        </div>
    );
};
export default SpecialistServices;
