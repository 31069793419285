import React from "react";

import noFeedbacksIcon from "../../../../assets/images/empty-result.svg";
import "./index.scss";

const NoFeedbacks = ({ text }) => {
    return (
        <div className="no-feedbacks">
            <img
                className="no-feedbacks__img"
                src={noFeedbacksIcon}
                alt="no feedbacks"
            />
            <span className="no-feedbacks__title">{text}</span>
        </div>
    );
};
export default NoFeedbacks;
