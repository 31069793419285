import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { putBoughtPromotionsItemRequest } from "../../../../redux/operations/promotionOperations";

import { getLanguage } from "../../../../helpers/language";

import "./PromotionsHistoryItem.scss";

const PromotionsHistoryItem = ({
    _id,
    nameTranslations,
    startDate,
    endDate,
    active,
}) => {
    const { value: language } = getLanguage();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    return (
        <tr className="promotions-history-item">
            <td>{_id}</td>
            <td>{nameTranslations && nameTranslations[language]}</td>
            <td>
                {dayjs(startDate).format("DD")}.{dayjs(startDate).format("MM")}.
                {dayjs(startDate).format("YYYY")}
            </td>
            <td>
                {dayjs(endDate).format("DD")}.{dayjs(endDate).format("MM")}.
                {dayjs(endDate).format("YYYY")}
            </td>
            {active ? (
                <td className="promotions-history-item__active">
                    {t("active")}
                </td>
            ) : (
                <td className="promotions-history-item__inactive">
                    {t("inactive")}
                </td>
            )}
            <td className="promotions-history-item__options">
                <button
                    onClick={() =>
                        dispatch(putBoughtPromotionsItemRequest(_id))
                    }
                    disabled={!active}
                >
                    {t("promotion.deactivate")}
                </button>
            </td>
        </tr>
    );
};

PromotionsHistoryItem.propTypes = {
    _id: PropTypes.string,
    nameTranslations: PropTypes.shape({
        en: PropTypes.string,
        ru: PropTypes.string,
        tr: PropTypes.string,
    }),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    active: PropTypes.bool,
};

export default PromotionsHistoryItem;
