import { useLottie } from "lottie-react";
import PropTypes from "prop-types";
import { useEffect } from "react";

const LottieAnimation = ({ lotti: animationData, width, height }) => {
    const options = { animationData, loop: true, autoplay: true };
    const style = { height, width };

    const { View, destroy } = useLottie(options, style);

    useEffect(() => {
        return () => destroy();
    }, []);

    return View;
};

LottieAnimation.propTypes = {
    lotti: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default LottieAnimation;
