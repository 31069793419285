import { useTranslation } from "react-i18next";

import ScheduleWork from "../../../../shared/ScheduleWork/ScheduleWork";
import "./index.scss";

const SpecialistServiceItem = ({
    _id = "",
    city = "",
    comment = "",
    priceTo = "",
    address = "",
    district = "",
    priceFrom = "",
    createdAt = "",
    category = {},
    subcategory = {},
    clarification = {},
    schedule = [],
    onEdit,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`specialist-services__list_item`}>
            <div className="specialist-services__list_item__header">
                <div className="specialist-services__list_item__header_title">
                    {clarification?.title} ({_id})
                </div>
            </div>
            <div className="specialist-services__list_item__info">
                <div className="specialist-services__list_item__info_title">
                    {t("services-specialist-project-description")}
                </div>
                <div className="specialist-services__list_item__info_item">
                    <div className="specialist-services__list_item__info_item_description">
                        {t("services-specialist-category")}
                    </div>
                    <div className="specialist-services__list_item__info_item_value">
                        {category?.title}
                    </div>
                </div>
                <div className="specialist-services__list_item__info_item">
                    <div className="specialist-services__list_item__info_item_description">
                        {t("services-specialist-subcategory")}
                    </div>
                    <div className="specialist-services__list_item__info_item_value">
                        {subcategory?.title}
                    </div>
                </div>
                <div className="specialist-services__list_item__info_item">
                    <div className="specialist-services__list_item__info_item_description">
                        {t("services-specialist-clarification")}
                    </div>
                    <div className="specialist-services__list_item__info_item_value">
                        {clarification?.title}
                    </div>
                </div>
                <div className="specialist-services__list_item__info_title">
                    {t("services-specialist-price")}
                </div>
                <div className="specialist-services__list_item__info_item">
                    <div className="specialist-services__list_item__info_item_description">
                        {t("services-specialist-price")}
                    </div>
                    <div className="specialist-services__list_item__info_item_value">
                        {priceFrom} - {priceTo}
                    </div>
                </div>
                <div className="specialist-services__list_item__info_title">
                    {t("services-specialist-full-address")}
                </div>
                <div className="specialist-services__list_item__info_item">
                    <div className="specialist-services__list_item__info_item_description">
                        {t("services-specialist-city")}
                    </div>
                    <div className="specialist-services__list_item__info_item_value">
                        {city}
                    </div>
                </div>
                <div className="specialist-services__list_item__info_item">
                    <div className="specialist-services__list_item__info_item_description">
                        {t("services-specialist-district")}
                    </div>
                    <div className="specialist-services__list_item__info_item_value">
                        {district}
                    </div>
                </div>
                <div className="specialist-services__list_item__info_item">
                    <div className="specialist-services__list_item__info_item_description">
                        {t("services-specialist-address")}
                    </div>
                    <div className="specialist-services__list_item__info_item_value">
                        {address}
                    </div>
                </div>
                <div className="specialist-services__list_item__info_title">
                    {t("services-specialist-complete-date")}
                </div>
                <div className="specialist-services__list_item__info_item">
                    <div className="specialist-services__list_item__info_item_description">
                        {t("services-specialist-date")}
                    </div>
                    <div className="specialist-services__list_item__info_item_value">
                        {new Date(createdAt).toLocaleDateString()}
                    </div>
                </div>
                <div className="specialist-services__list_item__info_title">
                    {t("services-specialist-comments")}
                </div>
                <div className="specialist-services__list_item__info_item">
                    <div className="specialist-services__list_item__info_item_value">
                        {comment}
                    </div>
                </div>
                <div className="specialist-services__list_item__info_title">
                    {t("services-specialist-schedule")}
                </div>
                <ScheduleWork defaultSchedule={schedule} type={"list"} />
                <button
                    className="specialist-services__list_item__info-btn"
                    onClick={() => onEdit()}
                >
                    {t("btn-edit")}
                </button>
            </div>
        </div>
    );
};

export default SpecialistServiceItem;
